/* eslint-disable react-hooks/exhaustive-deps */
import {
	Stack,
	Heading,
	Divider,
	Text,
	Button,
	useToast,
	Grid,
	GridItem,
	Card,
	CardBody,
	CardFooter,
	Image,
	Box,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Flex,
} from "@chakra-ui/react";
import { BsCartPlus } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import React, { useContext, useEffect, useState } from "react";
import _axios from "../../Api/AxiosBarrier";
import AuthContext from "../../Routes/hooks/AuthContext";
import { useNavigate } from "react-router-dom";
import { formatFrice } from "../../Utils/Helper";
import { useTranslation } from "react-i18next";
import store from "store";
function CbmPrice() {
	const packageUser = store.get("package_user");
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [cbmPackages, setCbmPackages] = useState([]);
	const path = window.location.href ?? "#";
	const { loadingShow, loadingClose } = useContext(AuthContext);
	const toast = useToast({
		position: "top",
		align: "center",
	});

	// useEffect(() => {
	// 	if (["Free-Member", "FREE MEMBER"].includes(packageUser?.name)){
	// 		return navigate('/')
	// 	}
	// }, [packageUser]);

	const fetchCbmPackages = async () => {
		try {
			loadingShow();
			const url = `api/cbm-balance/get-cbm-price`;
			const { status, message, data } = await _axios.get(url);
			loadingClose();
			if (!status) {
				return toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			}
			setCbmPackages(data);
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	useEffect(() => {
		fetchCbmPackages();
	}, []);

	const [modalPackage, setModalPackage] = useState(false);
	const [selectedPackage, setSelectedPackage] = useState({});

	const buyCbmPackage = async (arg) => {
		setSelectedPackage(arg);
		setModalPackage(true);
	};

	const processBuyCbmPackage = async (id) => {
		try {
			loadingShow();
			const url = `api/cbm-balance/store-cbm-order`;
			const { status, message, data } = await _axios.post(url, {
				cbm_id: id,
			});
			loadingClose();
			if (!status) {
				return toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			}
			navigate("/cbm/invoice");
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	return (
		<Stack p={[1, 1, 5]} bgColor="white" shadow={"md"} borderRadius="md">
			<Flex justify={'end'}>
				<Button onClick={() => navigate('/cbm/invoice')} colorScheme="yellow">
					<IoMdArrowRoundBack />&nbsp; {t('back')}
				</Button>
			</Flex>
			{!["Free-Member", "FREE MEMBER"].includes(packageUser?.name) ? (
				<Heading
					size={"xl"}
					textAlign={"center"}
					px={11}
					py={12}
					color={"green.300"}
				>
					{t("cbm_package")}
				</Heading>
			) : null}
			<Divider />
			{/* <Flex justify={'center'} alignItems={'center'} gap={10} px={5} py={6}>
                <Stack alignItems={'center'} justifyContent='center'>
                    <Heading size={'lg'}>{cbmBalanceData.balance_lartas} CBM</Heading>
                    <Text>All Product</Text>
                </Stack>
                <Stack alignItems={'center'} justifyContent='center'>
                    <Heading size={'lg'}>{cbmBalanceData.balance_non_lartas} CBM</Heading>
                    <Text>Selected Product</Text>
                </Stack>
            </Flex> */}
			<Grid
				templateColumns={{
					base: "repeat(1, 1fr)",
					md: "repeat(2, 1fr)",
					lg: "repeat(3, 1fr)",
				}}
				gap={5}
			>
				{cbmPackages.length > 0
					? cbmPackages.map((item, index) => (
						<GridItem key={index}>
							<Card maxW="sm" shadow={"lg"}>
								<CardBody>
									<Image
										src={
											item?.image !== null
												? item?.image
												: "https://importir-new.s3.ap-southeast-1.amazonaws.com/cbm_prices/0znYbuMNkrguJwVyiMpgWkSeEK3m2dyEmifhsX4N.png"
										}
										alt="Green double couch with wooden legs"
										borderRadius="lg"
									/>
									<Stack mt="6" spacing="3">
										<Box marginY={"2%"} height={"50px"}>
											<Heading size="md">
												{item?.name}
											</Heading>
										</Box>
										<Box
											height={"150px"}
											overflowY={"auto"}
										>
											<Text>{item?.description}</Text>
										</Box>
										<Text
											color="blue.600"
											fontSize="2xl"
										>
											<Text
												fontSize={"4xl"}
												color={
													item?.price_big > 0
														? "green"
														: "blue"
												}
											>
												{`Rp. ${formatFrice(
													parseInt(item?.price)
												)}`}
												<br />
												<Text
													fontSize={"lg"}
													color={"red"}
													as={"s"}
												>
													{item?.price_big > 0
														? `Rp. ${formatFrice(
															item?.price_big
														)}`
														: null}
												</Text>
											</Text>
										</Text>
									</Stack>
								</CardBody>
								<Divider />
								<Center>
									<CardFooter>
										<Button
											onClick={() =>
												buyCbmPackage(item)
											}
											variant="solid"
											colorScheme="green"
										>
											<BsCartPlus /> &nbsp;{" "}
											{t("buy_now")}
										</Button>
									</CardFooter>
								</Center>
							</Card>
						</GridItem>
					))
					: null}
			</Grid>
			<Modal isOpen={modalPackage} onClose={() => setModalPackage(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{Object.keys(selectedPackage).length > 0
							? selectedPackage.name
							: null}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Card
							padding={"2%"}
							shadow={"md"}
							bgColor={"whitesmoke"}
						>
							<Grid templateColumns="repeat(2, 1fr)">
								<GridItem>{t("price")}</GridItem>
								<GridItem>
									Rp.{" "}
									{Object.keys(selectedPackage).length > 0
										? formatFrice(
											parseFloat(
												selectedPackage.price
											)
										)
										: 0}
								</GridItem>

								<GridItem>{t("balance_type")}</GridItem>
								<GridItem>
									{Object.keys(selectedPackage).length > 0
										? selectedPackage.is_lartas === 1
											? `${t("all_product")}`
											: `${t("selected_product")}`
										: null}
								</GridItem>

								<GridItem>{t("cbm_amount")}</GridItem>
								<GridItem>
									{Object.keys(selectedPackage).length > 0
										? selectedPackage.cbm_amount
										: null}
									&nbsp; CBM
								</GridItem>
							</Grid>
						</Card>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={() => setModalPackage(false)}
						>
							{t("close")}
						</Button>
						<Button
							onClick={() =>
								processBuyCbmPackage(
									Object.keys(selectedPackage).length > 0
										? selectedPackage.id
										: null
								)
							}
							variant="solid"
							colorScheme="green"
						>
							{t("buy_package")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	);
}

export default CbmPrice;
