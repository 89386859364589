import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import AppBarSideV2 from '../Components/AppBarSideV2'
import AppFooterV2 from '../Components/AppFooterV2'
import CatalogPage from '../Pages/Admin/CatalogPage'
import UpdateDataPage from '../Pages/Admin/UpdateDataPage'
import CartPage from '../Pages/CartPage'
import CategoryPage from '../Pages/CategoryPage'
import CheckoutFreeMemberPage from '../Pages/CheckoutFreeMemberPage'
import ConsultationPage from '../Pages/ConsultationPage'
import CoursePage from '../Pages/CoursePage'
import CrowdfundPage from '../Pages/Crowdfund/CrowdfundPage'
import FavoritePage from '../Pages/FavoritePage'
import HomePageV2 from '../Pages/HomePageV2'
import NewsPage from '../Pages/NewsPage'
import PricingPage from '../Pages/PricingPage'
import ProfilePage from '../Pages/ProfilePage'
import LoginEmail from '../Pages/registration/LoginEmail'
import SearchPage from '../Pages/SearchPage'
import SearchPageByImage from '../Pages/SearchPageByImage'
import SourceForYouPage from '../Pages/SourceForYou/SourceForYouPage'
import AcademyPage from '../Pages/Academy/AcademyPage'
import { Container } from '@chakra-ui/react'
import CbmPage from '../Pages/Cbm/CbmPage'
import CbmInvoicePage from '../Pages/Cbm/CbmInvoicePage'
import CbmPrice from '../Pages/Cbm/CbmPrice'
import RefundListPage from '../Pages/Refund/RefundListPage'
import EscrowPage from '../Pages/Escrow/EscrowPage'
import ErrorPage from '../Pages/ErrorPage'
import EscrowDetailPage from '../Pages/Escrow/EscrowDetailPage'
import ContactUsPage from '../Pages/ContactUsPage'
import EscrowCreatePage from '../Pages/Escrow/EscrowCreatePage'
import OrderPage from '../Pages/OrderPage'
import OrderDetailPage from '../Pages/Order/OrderDetailPage'
import InvoiceListPageV2 from '../Pages/Invoice/InvoiceListPageV2'
import InvoiceDetailPage from '../Pages/Invoice/InvoiceDetailPage'
import LoginAsMember from '../Pages/Admin/LoginAsMember'
import AcademyCourseList from '../Pages/Academy/AcademyCourseList'
import TestPage from '../Pages/TestPage'
import ConfirmationEmailPage from '../Pages/registration/ConfirmationEmailPage'
import FormNewPassword from '../Pages/registration/FormNewPassword'
import ProtectedRoutesUser from './ProtectRoutesUser'
import InvoiceDetailNoMemberPage from '../Pages/Invoice/InvoiceDetailNoMemberPage'
import SourceForYouDetail from '../Pages/SourceForYou/SourceForYouDetail'
import ProductListNonLartasPage from '../Pages/ProductList/ProductListNonLartasPage'
import ProductNotFoundPage from '../Pages/ProductList/ProductNotFoundPage'
import CustomCatalog from '../Pages/Catalog/CatalogPage'
import BusinessPage from '../Pages/Business/BusinessPage'
import ShippingPage from '../Pages/Shipping/IndexPage'
import ShippingCreatePage from '../Pages/Shipping/CreatePage'
import ShippingSimulatorPage from '../Pages/Shipping/SimulatorPage'
import BusinessDetailPage from '../Pages/Business/BusinessDetailPage'
import ShippingDetail from '../Pages/Shipping/DetailPage'
import EscrowShippingDetail from '../Pages/Shipping/Escrow/EscrowShippingDetail'
import DetailBillShipping from '../Pages/Shipping/Billl/DetailBillShipping'
import CatalogHppPage from '../Pages/CatalogHpp/IndexPage'
import CatalogHppDetailPage from '../Pages/CatalogHpp/DetailPage'
import OldOrderListPage from '../Pages/Order/OldOrderListPage'
import OldOrderDetailPage from '../Pages/Order/OldOrderDetailPage'
import InvoiceDetailOldPage from '../Pages/Invoice/InvoiceDetailOldPage'
import ProductDetailPage from '../Pages/ProductDetailPage'
import ProductDetailFreeMemberPage from '../Pages/ProductDetailFreeMemberPage'
import QuizOnboardingIndex from '../Pages/QuizOnboarding/QuizOnboardingIndex';
import OldSHPIndex from '../Pages/Shipping/OldSHPIndex'
import OldSHPDetail from '../Pages/Shipping/OldSHPDetail'
import IndexPage from '../Pages/Mitra/IndexPage'
import MitraWishlist from '../Pages/Mitra/WishlistPage'
import MitraCart from '../Pages/Mitra/CartPage'
import MitraCheckout from '../Pages/Mitra/CheckoutPage'
import AppHeader from '../Components/Mitra/AppHeader'
import AppFooter from '../Components/Mitra/AppFooter'
import HscodeIndexPage from '../Pages/Hscode/IndexPage'
import HscodeDetailPage from '../Pages/Hscode/DetailPage'
import OrderList from '../Pages/Mitra/Order/IndexPage'
import OrderDetail from '../Pages/Mitra/Order/DetailPage'
import QuizOnboardingHistory from '../Pages/QuizOnboarding/HistoryPage'
import ContactPage from '../Pages/ContactPage'
import AppFooterIcon from '../Components/AppFooterIcon'
import RegisterFreeMemberPage from '../Pages/registration/RegisterFreeMemberPage'
import PackageList from '../Pages/PackageOrder/IndexPage'
import PackageOrderDetail from '../Pages/PackageOrder/DetailPage'
import CatalogListPage from '../Pages/CatalogList/CatalogListPage'
import OldOrderMitra from '../Pages/Mitra/OldOrder/IndexPage'
import OldDetailOrderMitra from '../Pages/Mitra/OldOrder/DetailPage'
import OrderTrackingNonMember from '../Pages/OrderTrackingNonMember'
import RefundHistory from '../Pages/Profile/RefundHistory'
import DetailMember from '../Pages/DetailMember/DetailMemberPage'
import EscrowEditPage from '../Pages/Escrow/EscrowEditPage'
import VoucherPage from '../Pages/Voucher/IndexPage'
import VoucherOrderPage from '../Pages/Voucher/OrderPage'
import VoucherOrderDetailPage from '../Pages/Voucher/OrderDetailPage'
import RefundPolicyPage from '../Pages/RefundPolicyPage'
import DetailResearch from '../Pages/Research/DetailResearch'
import IndexResearch from '../Pages/Research/IndexResearch'
import CbmDetailInvoice from '../Pages/Cbm/CbmDetailInvoice'
import PersonalizedIndex from '../Pages/Personalized/Index'
import InvoiceShippingListPage from '../Pages/Invoice/InvoiceShippingListPage'
import InvoiceShippingListDetailPage from '../Pages/Invoice/InvoiceShippingListDetailPage'
function MainRoute() {

	const location = useLocation()

	return (
		<>
			{
				location.pathname.includes("catalog-hpp") ?
					<>
						<Routes>
							<Route path="/catalog-hpp" element={<CatalogHppPage />} />
							<Route path="/catalog-hpp/:slug" element={<CatalogHppDetailPage />} />
						</Routes>
					</>
					:
					<>
						{location.pathname !== "/login" && location.pathname !== "/signup" && location.pathname !== "/register" && location.pathname !== "/register-free-member" ? (
							location.pathname.includes("mitra")
								?
								<>
									<AppHeader />
									<Routes>
										<Route path="/mitra" element={
											<IndexPage />
										} />
										<Route path="/mitra/wishlist" element={
											<MitraWishlist />
										} />
										<Route path="/mitra/cart" element={
											<MitraCart />
										} />
										<Route path="/mitra/checkout" element={
											<MitraCheckout />
										} />
										<Route path="/mitra/order/list" element={
											<OrderList />
										} />
										<Route path="/mitra/order/:id/detail" element={
											<OrderDetail />
										} />
										<Route path="/mitra/old-order" element={
											<OldOrderMitra />
										} />
										<Route path="/mitra/old-order/:id/detail" element={
											<OldDetailOrderMitra />
										} />
									</Routes>
									<AppFooter />
								</>
								:
								location.pathname.includes('hscode') ?
									<Routes>
										<Route path="/hscode" element={
											<HscodeIndexPage />
										} />

										<Route path="/hscode/:id" element={
											<HscodeDetailPage />
										} />
									</Routes>
									:
									location.pathname.includes('non-member') ?
										<AppBarSideV2>
											<Container maxW={'8xl'}>
												<Routes>
													<Route path="/order-tracking-non-member" element={<OrderTrackingNonMember />} />
												</Routes>
											</Container>
										</AppBarSideV2>
										:
										<>
											<AppBarSideV2 >
												<Container maxW={'8xl'}>
													{/* <BreadCrump /> */}
													<Routes>

														<Route path="/admin" element={
															<UpdateDataPage />
														} />
														<Route path="/catalog" element={
															<CatalogPage />
														} />

														<Route path="/" element={
															<HomePageV2 />
														} />

														<Route path="/pricing" element={
															<PricingPage />
														} />



														<Route path="/courses/:id" element={
															<CoursePage />
														} />

														<Route path="/wishlist" element={
															<FavoritePage />
														} />

														<Route path="/search" element={
															<SearchPage />
														} />

														<Route path="/search-by-image" element={
															<SearchPageByImage />
														} />

														<Route path="/category" element={
															<CategoryPage />
														} />

														<Route path="/cart" element={
															<CartPage />
														} />

														<Route path="/cataloglist" element={
															<CatalogListPage />
														} />

														<Route path="/product/:type/:id" element={
															<ProductDetailPage />
														} />

														<Route path="/product-free-member/:type/:id" element={
															<ProductDetailFreeMemberPage />
														} />

														<Route path="/research" element={
															<IndexResearch />
														} />

														<Route path="/research/:slug" element={
															<DetailResearch />
														} />

														{/* <Route path="/detail/:type/:id" element={
															<ProductDetailPage />
														} /> */}

														{/* <Route path="/checkout" element={

															<CheckoutPage />
														} /> */}

														<Route path="/checkout" element={
															<CheckoutFreeMemberPage />
														} />

														<Route path="/invoice" element={
															<InvoiceListPageV2 />
														} />

														<Route path="/invoice-shipping" element={
															<InvoiceShippingListPage />
														} />

														<Route path="/invoice-shipping/detail/:id" element={
															<InvoiceShippingListDetailPage />
														} />

														<Route path="/invoice/:id" element={
															<ProtectedRoutesUser>
																<InvoiceDetailPage />
															</ProtectedRoutesUser>
														} />

														<Route path="/invoice-nomember/:id" element={
															<InvoiceDetailNoMemberPage />
														} />


														<Route path="/consultation" element={
															<ConsultationPage />
														} />

														<Route path="/calculator" element={
															// <CalculatorPage />
															<ShippingSimulatorPage />
														} />

														<Route path="/sourceforyou" element={
															<SourceForYouPage />
														} />


														<Route path="/sourceforyou/:id" element={
															<SourceForYouDetail />
														} />

														<Route path="/news" element={
															<ProtectedRoutesUser>
																<NewsPage />
															</ProtectedRoutesUser>
														} />

														<Route path="/crowdfund" element={
															<CrowdfundPage />
														} />

														<Route path="/cbm" element={
															<CbmPage />
														} />

														<Route path="/cbm/package" element={
															<CbmPrice />
														} />

														<Route path="/cbm/invoice" element={
															<CbmInvoicePage />
														} />
														<Route path="/cbm/invoice/:id/detail" element={
															<CbmDetailInvoice />
														} />

														<Route path="/refund" element={
															<RefundListPage />
														} />

														<Route path="/escrow" element={
															<EscrowPage />
														} />

														<Route path="/profile" element={
															<ProfilePage />
														} />

														<Route path="/escrow/:id" element={
															<EscrowDetailPage />
														} />

														<Route path="/contactus" element={
															<ContactUsPage />
														} />

														<Route path="/escrow/create" element={
															<EscrowCreatePage />
														} />

														<Route path="/order" element={
															<OrderPage />
														} />

														<Route path="/order/:id" element={
															<OrderDetailPage />
														} />

														<Route path="/academy" element={
															<AcademyPage />
														} />

														<Route path="/admin-login" element={
															<LoginAsMember />
														} />

														<Route path="/course/list" element={
															<AcademyCourseList />
														} />

														<Route path="/forgot-password" element={
															<ConfirmationEmailPage />
														} />

														<Route path="/change-password" element={
															<FormNewPassword />
														} />

														<Route path="/nonlartas" element={
															<ProductListNonLartasPage />
														} />

														<Route path="/productnotfound" element={
															<ProductNotFoundPage />
														} />

														<Route path="/catalogs/:slug" element={
															<CustomCatalog />
														} />

														<Route path="/business" element={
															<BusinessPage />
														} />

														<Route path="/business/:id" element={
															<BusinessDetailPage />
														} />

														<Route path="/shipping" element={
															<ShippingPage />
														} />

														<Route path="/shipping/form/:id" element={
															<ShippingCreatePage />
														} />

														<Route path="/shipping/simulator" element={
															<ShippingSimulatorPage />
														} />

														<Route path="/shipping/detail/:id" element={
															<ShippingDetail />
														} />
														<Route path="/shipping/escrow/:id" element={
															<EscrowShippingDetail />
														} />

														<Route path="/shipping/payment/:id" element={
															<DetailBillShipping />
														} />

														<Route path="/old-order" element={
															<OldOrderListPage />
														} />

														<Route path="/old-order/:id" element={
															<OldOrderDetailPage />
														} />

														<Route path="/old-invoice/:id" element={
															<InvoiceDetailOldPage />
														} />

														<Route path="/old-shp" element={
															<OldSHPIndex />
														} />

														<Route path="/old-shp/:id" element={
															<OldSHPDetail />
														} />

														<Route path="/package" element={
															<PackageList />
														} />

														<Route path="/package/:id" element={
															<PackageOrderDetail />
														} />

														<Route path="/refund-history" element={
															<RefundHistory />
														} />

														<Route path="/dashboard-diamond" element={
															<DetailMember />
														} />

														<Route path="/escrow-edit/:id" element={
															<EscrowEditPage />
														} />

														<Route path="/error404" element={<ErrorPage />} />

														<Route path="/test" element={<TestPage />} />

														<Route path="/quiz-onboarding" element={<QuizOnboardingIndex />}></Route>
														<Route path="/list-module" element={<QuizOnboardingHistory />}></Route>
														<Route path="/quiz-onboarding/:id" element={<QuizOnboardingIndex />}></Route>
														<Route path="/contact" element={<ContactPage />}></Route>

														<Route path="/voucher" element={<VoucherPage />} />
														<Route path="/voucher/order" element={<VoucherOrderPage />} />
														<Route path="/voucher/order/:id" element={<VoucherOrderDetailPage />} />
														<Route path="/refund-policy" element={
															<RefundPolicyPage />
														} />
														<Route path="/personalized-catalog" element={
															<PersonalizedIndex />
														} />
													</Routes>
												</Container>
											</AppBarSideV2>
											<AppFooterV2 />
										</>

						) : (
							<Routes>
								<Route path="/register" element={<RegisterFreeMemberPage />} />
								{/* <Route path="/register" element={<RegisterPage />} /> */}
								<Route path="/login" element={<LoginEmail />} />
								<Route path="/error404" element={<ErrorPage />} />
							</Routes>
						)}
					</>
			}

			<AppFooterIcon />
		</>


	)
}

export default MainRoute