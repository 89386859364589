/* eslint-disable react-hooks/exhaustive-deps */
import {
	Stack,
	Heading,
	Divider,
	Flex,
	Text,
	Button,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	useToast,
	Tag,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import _axios from "../../Api/AxiosBarrier";
import moment from "moment";
import { errorSlack } from "../../Api/SlackApi";
import AuthContext from "../../Routes/hooks/AuthContext";
import { useNavigate } from "react-router-dom";
import store from "store";
import { useTranslation } from "react-i18next";
import { IoMdArrowRoundBack } from "react-icons/io";
function CbmInvoicePage() {
	const { t } = useTranslation();
	const packageUser = store.get("package_user");
	const navigate = useNavigate();
	const [cbmBalanceData, setCbmbalanceData] = useState([]);
	const [cbmBalanceLogsData, setCbmbalanceLogsData] = useState([]);

	const path = window.location.href ?? "#";
	const { loadingShow, loadingClose } = useContext(AuthContext);

	const toast = useToast({
		position: "top",
		align: "center",
	});

	const getCbmBalanceData = async () => {
		try {
			loadingShow();
			const res = await _axios.get("api/cbm-balance/get-cbm-balance");
			loadingClose();
			if (res.status === true) {
				setCbmbalanceData(res.data);
			} else {
				toast({
					title: "importir.com",
					description: res.message,
					status: "error",
				});
				const resSlack = await errorSlack(
					res.message,
					"importir.com",
					path
				);
				console.log(resSlack, "CONNECTED ERROR IN SLACK");
			}
		} catch (error) {
			toast({
				title: "importir.com",
				description: error.message,
				status: "error",
			});
			const resSlack = await errorSlack(error, "importir.com", path);
			console.log(resSlack, "CONNECTED ERROR IN SLACK");
		}
	};

	const getCbmBalanceLogsData = async () => {
		try {
			const res = await _axios.get(
				"api/cbm-balance/get-cbm-order-by-user"
			);
			if (res.status) {
				setCbmbalanceLogsData(res.data);
			} else {
				toast({
					title: "importir.com",
					description: res.message,
					status: "error",
				});
				const resSlack = await errorSlack(
					res.message,
					"importir.com",
					path
				);
			}
		} catch (error) {
			toast({
				title: "importir.com",
				description: error.message,
				status: "error",
			});
			const resSlack = await errorSlack(error, "importir.com", path);
			console.log(resSlack, "CONNECTED ERROR IN SLACK");
		}
	};

	useEffect(() => {
		getCbmBalanceData();
		getCbmBalanceLogsData();
	}, []);

	return (
		<Stack p={[1, 1, 5]} bgColor="white" shadow={"md"} borderRadius="md" mt={5}>
			<Flex justify="space-between" align="center" width="100%">
				<Heading
					size={"xl"}
					textAlign={"center"}
				>
				{t('invoice')} CBM
				</Heading>
				<Button onClick={() => navigate('/cbm')} bgColor={'#ffd600'}>
					<IoMdArrowRoundBack />&nbsp; {t('back')}
				</Button>
			</Flex>
			<Heading
				size={"xl"}
				textAlign={"center"}
				px={11}
				mt={12}
				color={"green.300"}
			>
				{t('remaining_cbm_balance')}
			</Heading>
			<Divider />
			<Flex
				justify={"center"}
				alignItems={"center"}
				gap={10}
				px={5}
				py={6}
			>
				<Stack alignItems={"center"} justifyContent="center">
					<Heading size={"lg"}>
						{cbmBalanceData.balance_lartas} CBM
					</Heading>
					<Text>{t('all_product')}</Text>
				</Stack>
				<Stack alignItems={"center"} justifyContent="center">
					<Heading size={"lg"}>
						{cbmBalanceData.balance_non_lartas} CBM
					</Heading>
					<Text>{t('selected_product')}</Text>
				</Stack>
			</Flex>
			{/* <Stack align={'center'} mt={10}>
				<Button onClick={() => navigate(`/cbm/invoice`)} bgColor={'#ffd600'} color='black' gap={2} size={'sm'} shadow='md'>
					Invoice Detail <AiOutlineArrowRight />
				</Button>
			</Stack> */}
			{!["Free-Member", "FREE MEMBER"].includes(packageUser?.name) ? (
				<Flex justifyContent={"end"}>
					<Button
						onClick={() => navigate("/cbm/package")}
						colorScheme="green"
						size="sm"
					>
						{t('buy_package')} CBM
					</Button>
				</Flex>
			) : null}
			<TableContainer px={4} py={5}>
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>#</Th>
							<Th>{t('invoice_number')}</Th>
							<Th>CBM</Th>
							<Th>{t('type')}</Th>
							<Th>{t('date')}</Th>
							<Th>Status</Th>
							<Th>{t('actions')}</Th>
						</Tr>
					</Thead>
					<Tbody fontSize={"sm"}>
						{cbmBalanceLogsData.length > 0 ? (
							cbmBalanceLogsData.map((item, index) => (
								<Tr>
									<Td>{index + 1}</Td>
									<Td>
										{item?.cbm_log_payments?.invoice_number}
									</Td>
									<Td>
										<Text>{item?.cbm_amount} CBM</Text>
									</Td>
									<Td>
										{item?.is_lartas > 0 ? (
											<Tag bgColor={"red.100"}>
												<Text>{t('all_product')}</Text>
											</Tag>
										) : (
											<Tag bgColor={"blue.100"}>
												<Text>{t('selected_product')}</Text>
											</Tag>
										)}
									</Td>
									<Td>
										{moment(
											item?.cbm_log_payments?.created_at
										).format("LLL")}
									</Td>
									<Td>
										<Text
											color={
												item?.cbm_log_payments
													?.paid_at == null
													? "red"
													: "green"
											}
										>
											{item?.cbm_log_payments?.paid_at ==
												null
												? `${t('unpaid')}`
												: `${t('paid')}`}{" "}
											<br />
											{item?.cbm_log_payments?.paid_at !==
												null
												? "May 29, 2024 1:41 PM"
												: null}
										</Text>
									</Td>
									<Td>
										<Button
											onClick={() => navigate(`/cbm/invoice/${item?.id}/detail`)}
											size={"sm"}
											colorScheme="green"
										>
											Detail
										</Button>
									</Td>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={7} textAlign="center">
									<Text color={"gray.600"}>{t('data_not_found')}</Text>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>
		</Stack>
	);
}

export default CbmInvoicePage;
