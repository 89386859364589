/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from "@chakra-ui/react";
import { createUserWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth, db } from "../Config/firebase";
import AuthContext from "./hooks/AuthContext";
import store from 'store'
import _axios from "../Api/AxiosBarrier";
import axios from "axios";
import { errorSlack, loginSlack, logoutSlack } from "../Api/SlackApi";
import { validatePersonalData } from "./UserMiddleware";
import { changePasswordFirebaseWithMiddleware } from "../Api/FirebaseFunction";
import { gtmConfig } from "../Utils/gtm";
import moment from "moment";

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState('');
  // const [tokenId, setTokenId] = useState("");
  const [loading, setLoading] = useState(false);
  const [userDb, setUserDb] = useState()
  const [kursRmb, setKursRmb] = useState(1)
  const [kursRates, setKursRate] = useState(1)

  const [dataUserApi, setDataUserApi] = useState('')
  const [dataPackageUserApi, setDataPackageUserApi] = useState('')
  const [userCurrency, setUserCurrency] = useState('idr')
  const [userSymbolCurrency, setUserSymbolCurrency] = useState('Rp.')

  const path = window.location.href ?? '#'
  const dateNow = moment();
  const expiredDate = dateNow.add(3, 'days');


  const [productListWishlist, setProductWishlist] = useState([])
  const [productListWishlistId, setProductWishlistId] = useState([])
  const [cartList, setCartList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [dataNews, setDataNews] = useState([])

  const [dataList] = useState([])


  const [imageCarouselDashboard, setImageCarouselDashboard] = useState([])
  const [catalogDashboard, setCatalogDashboard] = useState([])

  const [searchParams] = useSearchParams();
  const [categoryFrom, setCategoryFrom] = useState('china')

  const [popupView, setPopupView] = useState(false)

  const isByPass = searchParams.get('is_by_pass');



  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const setUsedPopupFunc = (val) => {
    setPopupView(val)
  }


  const setUsedCurrencyFunc = (val) => {
    setUserCurrency(val)
  }

  const setSymbolCurrenyFunc = (val) => {
    setUserSymbolCurrency(val)
  }


  const login = async (email, password) => {
    let dataAuth = {
      email,
      password
    };

    if (isByPass !== null) {
      if (isByPass === process.env.REACT_APP_MYPASS) {
        dataAuth = {
          email,
          password,
          bypass: true
        };
      }
    }

    const passwordDefault = "importir123";

    try {
      loadingShow(); // Tampilkan loading
      const res = await axios.post(process.env.REACT_APP_API_COM + 'api/sign-mobile', dataAuth);
      if (res.data.status === false) {
        loadingClose(); // Tutup loading
        toast({
          title: 'importir.com',
          description: `${res.data.message}`,
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      const dataUser = res?.data?.data;
      const displayName = dataUser?.user?.name || 'none';

      let userCredential = null;

      try {

        const querySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', email)));

        if (querySnapshot.empty) {
          let newUserCredential = '';
          try {
            newUserCredential = await createUserWithEmailAndPassword(auth, email, passwordDefault);
            store.set('fb_account', newUserCredential)
          } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
              newUserCredential = await signInWithEmailAndPassword(auth, email, passwordDefault);
              store.set('fb_account', newUserCredential)
            }
          }

          if (newUserCredential?.user) {
            await updateProfile(auth.currentUser, {
              displayName: displayName || 'none',
            });

            sendEmailVerification(auth.currentUser);

            const user = newUserCredential?.user;

            if (user) {


              if (dataUser.user && dataUser.user.name) {
                await setDoc(doc(db, 'users', user.uid), {
                  name: displayName || 'none',
                  keyword_name: displayName ? displayName.toLowerCase().split(' ').join('') : 'none',
                  email: user?.email,
                  uid_user: user?.uid,
                  nohp: dataUser?.user?.phone,
                  role: 'user',
                  subscription: 'null',
                  createdAt: new Date(),
                  data_importir: dataUser && dataUser,
                  package_importir: dataUser?.package_user?.package_child?.package?.name,
                  password: passwordDefault,
                });
              }
            }

            setDataUserApi(dataUser.user);
            setDataPackageUserApi(dataUser.package_user.package_child);
            await Promise.all([
              store.set('user_api', dataUser.user),
              store.set('package_user', dataUser.package_user.package_child),
              store.set('user_package', dataUser.package_user),
              store.set('user_token', dataUser.token),
              store.set('expiredAt', expiredDate),
              store.set('edutama_token', 'Bearer 7264|LUG0itqrBSdIbzTTPHxTlURiCMpNuyazQIA8F2Kk'),
              store.set('lang', dataUser.user.country_code !== 'id' ? 'en' : 'id')
            ]);

            if (dataUser.user.country_code === 'id') {
              setUsedCurrencyFunc('idr');
              setSymbolCurrenyFunc('Rp.');
              await Promise.all([
                store.set('usedCurrency', 'idr'),
                store.set('symbolCurrency', 'Rp.')
              ]);
            } else if (dataUser.user.country_code === 'ph') {
              setUsedCurrencyFunc('php');
              setSymbolCurrenyFunc('₱');
              await Promise.all([
                store.set('usedCurrency', JSON.stringify('php')),
                store.set('symbolCurrency', JSON.stringify('₱'))
              ]);
            }

            loadingClose(); // Tutup loading

            loginSlack(email, 'importir.com');

            if (dataUser.user.country_code === 'ph') {
              setUsedCurrencyFunc('php');
              setSymbolCurrenyFunc('₱');
              if (!validatePersonalData(dataUser, toast)) {
                return;
              }
              navigate('/')
            } else {
              setUsedCurrencyFunc('idr');
              setSymbolCurrenyFunc('Rp.');
              if (!validatePersonalData(dataUser, toast)) {
                return;
              }
              window.open(res?.data?.data?.token_user, "_blank");
              navigate("/");
            }
          }

        } else {
          const userDataArray = querySnapshot.docs.map((doc) => doc.data());
          const userData = userDataArray[0];

          if (userData.password) {
            userCredential = await signInWithEmailAndPassword(auth, email, userData.password);
            store.set('fb_account', userCredential)

          } else {
            userCredential = await signInWithEmailAndPassword(auth, email, password);
            store.set('fb_account', userCredential)
          }

          if (userCredential?.user) {
            setDataUserApi(dataUser.user);
            setDataPackageUserApi(dataUser.package_user.package_child);

            await Promise.all([
              store.set('user_api', dataUser.user),
              store.set('package_user', dataUser.package_user.package_child),
              store.set('user_package', dataUser.package_user),
              store.set('user_token', dataUser.token),
              store.set('expiredAt', expiredDate),
              store.set('lang', dataUser.user.country_code !== 'id' ? 'en' : 'id')
            ]);

            if (dataUser.user.country_code === 'id') {
              setUsedCurrencyFunc('idr');
              setSymbolCurrenyFunc('Rp.');
              await Promise.all([
                store.set('usedCurrency', 'idr'),
                store.set('symbolCurrency', 'Rp.')
              ]);
            } else if (dataUser.user.country_code === 'ph') {
              setUsedCurrencyFunc('php');
              setSymbolCurrenyFunc('₱');
              await Promise.all([
                store.set('usedCurrency', JSON.stringify('php')),
                store.set('symbolCurrency', JSON.stringify('₱'))
              ]);
            }

            const docRef = doc(db, 'users', userCredential?.user?.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              await updateDoc(docRef, {
                // tokenId: arrayUnion(tokenId),
                package_importir: dataUser?.package_user?.package_child?.package.name,
                data_importir: dataUser
              });

              if (docSnap.data().password === undefined) {
                changePasswordFirebaseWithMiddleware(userCredential?.user?.uid, passwordDefault);
              }
            } else {
            }

            loginSlack(email, 'importir.com'); // login slack

            loadingClose(); // Tutup loading

            store.remove("expired_session")
            if (dataUser.user.country_code === 'ph') {
              setUsedCurrencyFunc('php');
              setSymbolCurrenyFunc('₱');
              if (!validatePersonalData(dataUser, toast)) {
                return;
              }
              navigate('/')
            } else {
              setUsedCurrencyFunc('idr');
              setSymbolCurrenyFunc('Rp.');
              if (!validatePersonalData(dataUser, toast)) {
                return;
              }
              // window.open(res?.data?.data?.token_user, "_blank");
              navigate("/");
            }
          }
        }
       
      } catch (error) {
        if (error.code === 'auth/user-not-found') {
          console.log('User not registered in Firebase');
          toast({
            title: 'importir.com',
            description: 'User not registered in Firebase',
            status: 'error',
          });
        } else {
          const res = await axios.get(`https://asia-southeast2-anggaran-v2.cloudfunctions.net/anggaran/api/importir/reset-authentication?email=${email}`);
          if (res.data.status === false) {
            loadingClose(); // Tutup loading
            toast({
              title: 'importir.com',
              description: `${res.data.message}`,
              status: 'error',
              isClosable: true,
              position: 'top-right',
            });
            return;
          }
          login(email, password)
          // console.log('Error logging in', error.message);

          // if (error.code === 'auth/wrong-password') {
          //   toast({
          //     title: 'importir.com',
          //     description: `Your account in our database has successfully logged in, but there is an error in Firebase authentication. Please contact admin by clicking the bottom right icon`,
          //     status: 'error',
          //   });
          // } else {
          //   toast({
          //     title: 'importir.com',
          //     description: error.message,
          //     status: 'error',
          //   });
          // }

          loadingClose(); // Tutup loading
        }
      }
    } catch (error) {
      const resSlack = await errorSlack(error, 'importir.com', path);
      console.log(resSlack, 'CONNECTED ERROR IN SLACK');
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      // window.open('https://old.importir.com/logout', '_blank');
      navigate('/login', { replace: true });
      store.clearAll();
      logoutSlack(currentUser.email, 'importir.com');
      // Lakukan tindakan setelah berhasil sign out, misalnya navigasi ke halaman login
      return { res: true, message: 'Success Sign out.' };
    } catch (error) {
      // Tangani kesalahan yang terjadi saat sign out
      // Lakukan tindakan penanganan kesalahan, misalnya menampilkan pesan kesalahan kepada pengguna
      return { res: false, message: 'Terjadi kesalahan saat sign out.' };
    }
  };


  const getUserDataDb = async () => {
    if (currentUser) {
      try {
        onSnapshot(doc(db, "users", currentUser?.uid), (doc) => {
          setUserDb(doc.data());
        });
      } catch (error) {
        const resSlack = await errorSlack(error, 'importir.com', path)
        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
      }
    }
  }


  const loadingShow = () => {
    setLoading(true)
  }

  const loadingClose = () => {
    setLoading(false)
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      gtmConfig(user?.uid || "")
      setCurrentUser(user);
      getDataWishlist(user)
      // fetchToken(setTokenId)
    });
  }, []);


  //    Home Page

  const getDataRmb = async () => {
    loadingShow()
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_COM}api/rates`)
      if (res.status === 200) {
        setKursRmb(res.data.data.rmb)
        setKursRate(res.data.data)
      }
      loadingClose()
    } catch (error) {
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
      loadingClose()
    }
  }

  // get wishlist
  const getDataWishlist = async (user) => {
    if (user) {
      let arrProduct = []
      try {
        onSnapshot(doc(db, "wishlist", user.uid), (doc) => {
          setProductWishlist(doc.data());

          const dataProduct = doc?.data()?.data

          dataProduct?.forEach(element => arrProduct?.push((element.id).toString()));

          setProductWishlistId(arrProduct)

        });

      } catch (error) {
        const resSlack = await errorSlack(error, 'importir.com', path)
        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        console.log(error.message)
      }
    }

  }

  // get cart
  const getCart = async (user) => {
    if (user) {
      try {
        loadingShow()
        const res = await _axios('api/carts')
        loadingClose()
        if (res.status === true) {
          setCartList(res.data)
          // const dataArr = res.data
          // dataArr.forEach(item => {
          //     sum += (parseInt(item.price))
          //     setTotalPrice(sum)
          // }
          // )

        }

      } catch (error) {
        let isSend = true
        if (error.response !== undefined) {
          if (error.response.status !== undefined) {
            if (error.response.status === 401) {
              isSend = false
            }
          }
        }
        if (isSend) {
          console.log(error, 'in getcart')
          const resSlack = await errorSlack(error, 'importir.com', path)
          console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
      }
    }
  }

  const setCategoryFunc = async (val) => {
    setLoading(true);
    store.set("categoryFrom", val)

    // Simulasi delay misalnya 2 detik untuk tampilan progress yang lebih jelas

    setLoading(false);

    if (val === "japan") {

      toast({
        title: 'Welcome to Importir Japan!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else if (val === "usa") {
      toast({
        title: 'Welcome to Importir USA!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else if (val === "korea") {
      toast({
        title: 'Welcome to Importir Korea',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Welcome to Importir China!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
    setCategoryFrom(val);
    getCategory(val)
  };


  //get category list
  const getCategory = async (categoryFromLocal) => {
    let categoryParams = ""
    if (categoryFromLocal === "china") {
      categoryParams = "category"
    }
    if (categoryFromLocal === "japan") {
      categoryParams = "category-amazon-jp-v2"
    }

    if (categoryFromLocal === "usa") {
      categoryParams = "category-amazon-com-v2"
    }

    if (categoryFromLocal === "korea") {
      categoryParams = "category-coupang"
    }

    try {

      const docRef = doc(db, "display", categoryParams);
      onSnapshot(docRef, (doc) => {
        if (categoryParams === 'category-coupang' && doc?.data() === undefined) {
          alert('Category not found')   
        } else {
          setCategoryList(doc?.data()?.data)
        }
      });
    } catch (error) {
      console.log(error, 'ini error')
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
    }
  }



  const getDataDashboardImage = async () => {
    setLoading(true);

    try {
      loadingShow()
      try {
        const userCountryCode = store.get("user_api") === undefined ? 'nl' : store.get("user_api").country_code;
        const url = `${process.env.REACT_APP_API_COM}api/home/main-banner?country=${userCountryCode}`;
        const res = await axios.get(url);
        if (res.status === 200) {
          // console.log("ini data :", res.data.data)
          // const dataImage = docSnap.data().image_dashboard;
          setImageCarouselDashboard(res.data.data)
        }
        loadingClose()
      } catch (error) {
        console.log(error, 'ini error')
        const resSlack = await errorSlack(error, 'importir.com', path)
        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        loadingClose()
      }

      // console.log(store.get("user_api").country_code)
      const docRef = doc(db, "display", "dashboard");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // const dataImage = docSnap.data().image_dashboard;
        // setImageCarouselDashboard(dataImage)

        const dataCatalog = docSnap.data().catalog_dashboard;
        setCatalogDashboard(dataCatalog)
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
    } finally {
      setLoading(false);
    }
  };

  const getDataNews = async () => {
    setLoading(true)
    try {
      const res = await axios.get('https://new-apiv2.importir.com/api/news');
      setDataNews(res.data);
      setLoading(false)
    } catch (error) {
      console.log(error, 'ini error');
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
      setLoading(false)
    }
    setLoading(false)
  };

  useEffect(() => {
    getUserDataDb()
    getCart(currentUser)

    return () => {
    }
  }, [currentUser])


  useEffect(() => {
    getDataRmb()
    getDataDashboardImage()
    getDataNews()

    return () => {
    }
  }, [])


  // useEffect(() => {
  //   getCategory()

  //   return () => {
  //   }
  // }, [categoryFrom])







  const value = {
    currentUser,
    // tokenId,
    login,
    signOut,
    userDb,
    loadingShow,
    loadingClose,
    loading,
    kursRmb,
    kursRates,

    productListWishlist,
    cartList,
    categoryList,
    productListWishlistId,
    dataNews,
    imageCarouselDashboard,
    dataList,

    dataUserApi,
    dataPackageUserApi,
    userCurrency,
    userSymbolCurrency,
    categoryFrom,
    popupView,
    catalogDashboard,

    getCart,
    getDataWishlist,
    setUsedCurrencyFunc,
    setSymbolCurrenyFunc,
    setCategoryFunc,

    setUsedPopupFunc,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
