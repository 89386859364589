/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Center, Checkbox, Divider, Grid, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spacer, Stack, Tag, Text, useToast, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaShip } from 'react-icons/fa'
import { IoAirplaneSharp, IoCaretBackOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import _axios from '../Api/AxiosBarrier'
import AuthContext from '../Routes/hooks/AuthContext'
import colors from '../Utils/colors'
import { formatFrice, getHandlingFeePercentage, getShippingFee } from '../Utils/Helper'
import _ from 'lodash'
import AddressCard from '../Components/Card/AddressCard'
import AddressList from '../Components/Form/AddressList'
import { getCountry } from '../Utils/country'
import ConvertPrice from '../Utils/ConvertPrice'
import store from 'store'
import { errorSlack } from '../Api/SlackApi'
import { sendEcommerceEvent } from '../Utils/gtm'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { useJitsu } from '@jitsu/jitsu-react'
import { triggerEventFacebookPixel } from '../Utils/facebook'
function CheckoutFreeMemberPage() {
    const userData = store.get('fb_account');
    const { analytics } = useJitsu();
    const { t } = useTranslation();
    const path = window.location.href ?? '#'
    const [activeAddress, setActiveAddress] = useState('')

    const navigate = useNavigate()
    const toast = useToast({
        position: "top",
        align: "center",
    });

    const height = window.innerHeight

    const { loadingShow, loadingClose, getCart, userSymbolCurrency, userDb } = useContext(AuthContext)

    const { convertPriceFix } = ConvertPrice()

    const [modalVisible, setModalVisible] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [promoModal, setPromoModal] = useState(false);
    const [orderModal, setOrderModal] = useState(false);
    const [moreInfo, setMoreInfo] = useState(false);
    const [cbmModal, setCbmModal] = useState(false);

    const [voucherModal, setVoucherModal] = useState(false);
    const [productActiveVoucher, setProductActiveVoucher] = useState(null)
    const [cartVoucherIndex, setCartVoucherIndex] = useState('')
    const [voucherData, setVoucherData] = useState([])

    const [cbmData, setCbmData] = useState("");

    const [cbmInputLartas, setCbmInputLartas] = useState(0);
    const [cbmInputNonLartas, setCbmInputNonLartas] = useState(0);

    const [agreeBol, setAgreeBol] = useState(false);
    const [agreeText, setAgreeText] = useState("");
    const [carts, setCarts] = useState([])
    const [addreses, setAddressess] = useState([])
    const [productPrice, setProductPrice] = useState(0)
    const [warehouseDeliveryFee, setWarehouseDeliveryFee] = useState(0)
    const [handlingFee, setHandlingFee] = useState(0)
    const [handlingFeeTax, setHandlingFeeTax] = useState(0)
    const [shippinglocalFee, setShippinglocalFee] = useState(0)
    const [shippingFee, setShippingFee] = useState(0)
    const [promoRes, setPromoRes] = useState(null)
    const [bm, setBm] = useState(0)
    const [ppn, setPpn] = useState(0)
    const [pph, setPph] = useState(0)
    const [discShippingFee, setDiscShippingFee] = useState(0)
    const [codePromo, setCodePromo] = useState("")
    const [inputCodePromo, setInputCodePromo] = useState("")
    const [totalBill, setTotalBill] = useState(0)
    const [item, setItem] = useState(null)
    let seaFee = useRef(3500000)
    let airFee = useRef(180000)
    let airAmazonJapanFee = useRef(330000)
    let airAmazonUsaFee = useRef(320000)
    let airCoupangFee = useRef(195000)
    const [percentageBm, setPercentageBm] = useState(0)
    const [percentagePph, setPercentagePph] = useState(0)
    const [percentagePpn, setPercentagePpn] = useState(0)
    const [rateData, setRateData] = useState(null)
    const [productActiveCbm, setProductActiveCbm] = useState('')
    const handlingFeeTaxPercentage = 0.11
    const userPackage = store.get('user_package');
    const expirationDate = userPackage?.expired_at
    const userDataLoc = store.get('user_api');

    const fetchCart = () => {
        loadingShow()
        return new Promise((resolve, reject) => {
            _axios
                .get('api/checkout')
                .then((res) => {
                    const filteredData = res.data.filter((item) => item.is_checkout === 1);
                    resolve(filteredData);
                })
                .catch((err) => reject(err));
        });
    };

    const fetchAddress = () => {
        return new Promise((resolve, reject) => {
            _axios.get('api/address').then((res) => {
                if (res.data.length === 0) {
                    toast({
                        title: 'importir.com',
                        description: 'Please, Fill in your address',
                        status: 'warning'
                    })
                    setAddressModal(true)
                    loadingClose()
                } else {
                    resolve(res.data)
                }

            })
                .catch(err => reject(err))
        })
    }

    const fetchLogistic = (address, weight) => {
        return new Promise((resolve, reject) => {
            _axios.get(`api/delivery?weight=${weight}&province=${address.province}&city=${address.city}&district=${address.district}`)
                .then((res) => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    }

    const fetchHandlingFeePercentage = async () => {
        return new Promise((resolve, reject) => {
            _axios.get(`api/shipping/percentage-handling-fee`).then(res => {
                resolve(res.data)
            }).catch(err => reject(err))
        })
    }

    const initFetch = () => {
        return Promise.all([
            fetchCart(),
            fetchAddress(),
            fetchHandlingFeePercentage()
        ])
    }

    const getDataCBM = async () => {
        try {
            const res = await _axios.get('api/cbm-balance/get-cbm-balance')
            setCbmData(res.data)
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const getVoucherData = async () => {
        try {
            const res = await _axios.get('api/voucher/order?is_get=yes&paid_at=yes')
            let respData = []
            res.forEach((x) => {
                x.data.voucher_order_detail_id = x.voucher_order_detail_id
                x.data.invoice_no = x.invoice_no
                x.data.is_expired = x.is_expired
                respData.push(x.data)
            })
            setVoucherData(respData)
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }


    const changeActiveAddress = async (item) => {
        // return new Promise((resolve, reject) => {
        await _axios.post(`api/address/${item.id}/set-active`)
        // })
    }

    const getDataRates = async () => {
        try {
            const res = await _axios.get('api/rates')
            if (res.status) {
                setRateData(res.data)
            }
            return res.data
        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    useEffect(() => {
        getVoucherData()
        getDataCBM()
        return () => {

        }
    }, [])

    useEffect(() => {
        if (userDataLoc?.country_code === "id") {
            let cType = null
            carts.forEach((x) => {
                if (cType === null) {
                    cType = x?.product_relation?.is_lartas;
                    // if (x?.product_relation?.is_lartas > 0)
                } else {
                    if (cType !== x?.product_relation?.is_lartas) {
                        window.location.href = '/cart'
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carts])

    const fetchData = async (dataRates = null) => {
        try {
            loadingShow();

            const res = await initFetch();
            let handlingFeePercentage = 0.1;
            let user = store.get('user_api')
            const arrCarts = res[0].map((x) => {
                const price = parseFloat(x.price);
                let bmFee = 0;
                let ppnFee = 0;
                let pphFee = 0;
                x.handlingFee = 0;
                x.handlingFeeTax = 0;
                handlingFeePercentage = handlingFeeCalculate(x, x.product_relation.is_lartas) / 100
                if (user?.country_code === 'id') {
                    if (x.product_relation.category_platform) {
                        if (x.product_relation.category_platform.category_google) {
                            x.product_relation.is_lartas = x.product_relation.category_platform.category_google.is_lartas
                        }
                    }

                    handlingFeePercentage = handlingFeeCalculate(x, x.product_relation.is_lartas) / 100
                    x.handlingFeePercentage = handlingFeePercentage

                    if (['1688', 'taobao', 'amazonjapan', 'amazonus', 'coupang'].includes(x.product_relation.platform_type)) {
                        x.handlingFee = price * handlingFeePercentage
                        x.handlingFeeTax = x.handlingFee * handlingFeeTaxPercentage
                    }
                } else {
                    x.handlingFee = price * handlingFeePercentage
                    x.handlingFeePercentage = handlingFeePercentage
                }


                let bm = 0
                let ppn = 0
                let pph = 0

                if (x?.product_relation?.category_platform?.category_google?.hscode) {
                    bm = x?.product_relation?.category_platform?.category_google?.hscode?.mfn
                    ppn = x?.product_relation?.category_platform?.category_google?.hscode?.ppn
                    pph = x?.product_relation?.category_platform?.category_google?.hscode?.pph_import
                } else {
                    bm = 7.5
                    ppn = 11
                    pph = 10
                }

                setPercentageBm(bm)
                setPercentagePph(pph)
                setPercentagePpn(ppn)

                if (['1688', 'taobao', 'amazonjapan', 'amazonus', 'coupang'].includes(x.product_relation.platform_type)) {
                    bmFee = (price * bm) / 100;
                    ppnFee = ((price + bmFee) * ppn) / 100;
                    pphFee = ((price + bmFee) * pph) / 100;
                }

                x.wood_packing = false;
                x.wood_note = false;
                x.logistic = '';
                x.shippingFee = 0;
                x.shippinglocalFee = 0;
                x.logistic_arr = [];
                x.fragile = false;
                x.packing_note = '';
                x.bm = bmFee;
                x.ppn = ppnFee;
                x.pph = pphFee;
                x.weightCbmToKg = 0;
                x.voucher = ''

                const packageUsers = store.get('package_user')
                seaFee.current = getShippingFee(packageUsers?.package?.name, x?.freight, x.product_relation.is_lartas, user?.country_code)

                calculateFreight(x, 0.2, dataRates);


                x.total =
                    x.price +
                    x.warehouse_delivery_fee +
                    x.handlingFee +
                    x.handlingFeeTax +
                    x.shippinglocalFee +
                    x.shippingFee +
                    x.bm +
                    x.ppn +
                    x.pph;

                return x;
            });

            const arrAddress = res[1];
            setAddressess(arrAddress);

            let actAddress = arrAddress.find((x) => x.is_active);

            if (!actAddress) {
                actAddress = arrAddress[0];
                // disini tambahkan modal
                changeAddress()
                toast({
                    title: 'importir.com',
                    description: 'Please, set your address active',
                    status: 'warning',
                });
                loadingClose();
            }

            setActiveAddress(actAddress);

            const arrLogistic = arrCarts.map((x) => fetchLogistic(actAddress, x.total_weight));
            const generateLogistict = await Promise.all(arrLogistic);

            generateLogistict?.forEach((x, index) => {
                const currentCart = arrCarts[index];
                currentCart.logistic_arr = x;
            });


            // const ecommerceData = {
            //     'user_id': userData?.user?.uid ?? "",
            //     'email': userData?.user?.email ?? "",
            //     'location': window.location.pathname,
            //     'company_id': "dNNHm2zy4iTSfp1ejV9K",
            //     'project_id': "wjS0AhuP4FAF1j0A6tb9",
            //     'currency': 'IDR',
            //     'value': parseFloat(_.sumBy(arrCarts, 'total')),
            //     'items': arrCarts,
            // };
            // analytics.track('begin_checkout', ecommerceData)
            // sendEcommerceEvent('begin_checkout', ecommerceData);


            if (arrCarts.length === 0) {
                window.location.href = process.env.REACT_APP_BASE_URL + "cart"
            } else {
                setCarts(arrCarts);
                generateBillAmount(arrCarts);
            }
            loadingClose();
        } catch (err) {
            loadingClose();
            toast({
                title: 'importir.com',
                description: err.message,
                status: 'error',
            });
            const resSlack = await errorSlack(err.message, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    };


    useEffect(() => {
        initiateData()
        return () => {
            loadingClose();
        };
    }, []);

    const initiateData = async () => {
        const dataRates = await getDataRates()
        fetchData(dataRates);
    }

    const showBillDetail = (index) => {
        setItem(carts[index])
        setModalVisible(true)
    }

    const changeAddress = () => {
        setAddressModal(true)
    }

    const onsetActiveAddress = async (arg) => {
        if (addreses.length === 1) {
            if (addreses[0].is_active === 1) {
                toast({
                    title: 'importir.com',
                    description: 'You can\'t change the active if the address is only one',
                    status: 'error',
                });
                return
            }
        }
        loadingShow()
        setAddressModal(false)
        const findIndexAddress = addreses.findIndex(x => x.id === arg.id)
        const arrAddress = addreses
        // const indexActive = arrAddress.findIndex(x => x?.is_active)
        await changeActiveAddress(arrAddress[findIndexAddress])

        await initiateData()
        loadingClose()
        // const arrCarts = carts
        // const arrLogistic = []
        // arrCarts?.forEach(x => {
        //     arrLogistic.push(
        //         fetchLogistic(arrAddress[findIndexAddress], x.total_weight)
        //     )
        // })

        // const generateLogistict = await Promise.all(arrLogistic)
        // generateLogistict.map((x, index) => {
        //     const currentCart = arrCarts[index]
        //     currentCart.logistic_arr = x
        //     return x
        // })
        // setCarts(arrCarts)

        // loadingClose()

        // console.log('arraaddress', arrAddress)
        // if (arrAddress[indexActive] !== undefined) {
        //     arrAddress[indexActive].is_active = false
        // }
        // if (arrAddress[findIndexAddress] !== undefined) {
        //     arrAddress[findIndexAddress].is_active = true
        // }
        // setAddressess([...arrAddress])
        // setActiveAddress(arg)
        // loadingClose()
    }

    const onSetLogistic = (val, index) => {
        const arrCarts = carts
        const item = arrCarts[index]
        const findLogistic = arrCarts[index].logistic_arr.find(x => x.service_code === val)
        arrCarts[index].logistic = val
        arrCarts[index].shippinglocalFee = findLogistic?.fee_total
        arrCarts[index].total = item.price + item.warehouse_delivery_fee + item.handlingFee + item.handlingFeeTax + item.shippinglocalFee + item.shippingFee + item.bm + item.ppn + item.pph
        setCarts([...arrCarts])
        setShippinglocalFee(_.sumBy(arrCarts, 'shippinglocalFee'))
        setTotalBill(_.sumBy(arrCarts, 'total'))
    }

    const generateBillAmount = (arg) => {
        setProductPrice(_.sumBy(arg, 'price'))
        setWarehouseDeliveryFee(_.sumBy(arg, 'warehouse_delivery_fee'))
        setHandlingFee(_.sumBy(arg, 'handlingFee'))
        setHandlingFeeTax(_.sumBy(arg, 'handlingFeeTax'))
        setShippinglocalFee(_.sumBy(arg, 'shippinglocalFee'))
        setShippingFee(_.sumBy(arg, 'shippingFee'))
        setBm(_.sumBy(arg, 'bm'))
        setPpn(_.sumBy(arg, 'ppn'))
        setPph(_.sumBy(arg, 'pph'))
        setDiscShippingFee(_.sumBy(arg, 'discount_price'))
        setTotalBill(_.sumBy(arg, 'total'))
    }

    const calculateFreight = (item, defaultCBM = 0.2, dataRates = null) => {
        let user = store.get('user_api')
        let packageUser = store.get('package_user')
        if (user?.country_code !== 'id') {
            const ratePhpLocal = dataRates !== null ? dataRates.php : rateData?.php
            airFee.current = 780 * parseFloat(ratePhpLocal)
            seaFee.current = 11500 * parseFloat(ratePhpLocal)
            airAmazonJapanFee.current = 780 * parseFloat(ratePhpLocal)
        }
        let weightConverted = findWeight(item);
        let cbmConverted = weightConverted / 168;
        if (item?.freight === "Sea") {
            if (defaultCBM > 0) {
                if (cbmConverted < 0.2) {
                    cbmConverted = 0.2;
                }

                if (packageUser?.package_id === 7 && parseInt(item?.product_relation?.is_lartas) === 0) {
                    cbmConverted = 0;
                }
            } else {
                cbmConverted = item.cbm
            }

            if (['1688', 'taobao', 'amazonjapan'].includes(item?.product_relation?.platform_type)) {
                item.shippingFee = cbmConverted * seaFee.current;
            }

            if (['amazonjapan'].includes(item?.product_relation?.platform_type)) {
                item.shippingFee = cbmConverted * airAmazonJapanFee.current;
            }

            item.weightCbmToKg = cbmConverted
        } else {
            if (weightConverted < 1) {
                weightConverted = 1;
            }
            if (['1688', 'taobao'].includes(item?.product_relation?.platform_type)) {
                item.shippingFee = weightConverted * airFee.current;
            }
            if (['amazonjapan'].includes(item?.product_relation?.platform_type)) {
                item.shippingFee = weightConverted * airAmazonJapanFee.current;
            }
            if (['amazonus'].includes(item?.product_relation?.platform_type)) {
                item.shippingFee = weightConverted * airAmazonUsaFee.current;
            }
            if (['coupang'].includes(item?.product_relation?.platform_type)) {
                item.shippingFee = weightConverted * airCoupangFee.current;
            }
            item.weightCbmToKg = weightConverted
        }
    }

    const findWeight = (item) => {
        const weightEst = item.weight;
        const cbmEst = item.cbm
        const w = (parseFloat(weightEst) / 1000)
        const cbm = parseFloat(cbmEst)
        let cbmToKg = 0;
        if (item?.freight === "Sea") {
            cbmToKg = cbm * 168;
        } else {
            cbmToKg = cbm * 168;
            if (cbmToKg < w) {
                cbmToKg = w;
            }
        }
        return cbmToKg;
    }

    const onChangeCheckbox = (val, arg, index) => {
        const arrCarts = carts
        if (arg === 'fragile') {
            arrCarts[index].fragile = !!val
        } else {
            arrCarts[index].wood_packing = !!val
        }

        setCarts([...arrCarts])
    }

    const onChangeAgree = (val) => {
        setAgreeBol(val)
    }

    const onSubmit = async () => {
        const userDataLoc = store.get('user_api')
        if (agreeText === "I AGREE" && agreeBol === true) {
            loadingShow();
            setOrderModal(false);

            let error = false;
            const orders = carts.map((x) => {
                if (userDataLoc?.country_code === 'id') {
                    if (!x.logistic) {
                        error = true;
                    }
                }

                return {
                    fragile: x.fragile,
                    wood_packing: x.wood_packing,
                    packing_note: x.packing_note,
                    shipping_method: x.logistic,
                    shipping_fee_local: x.shippinglocalFee,
                    shipping_fee: x.shippingFee,
                    use_cbm_lartas: x.use_cbm_lartas || 0,
                    use_cbm_non_lartas: x.use_cbm_non_lartas || 0,
                    voucher_order_detail_id: x.voucher_order_detail_id
                };
            });

            if (error) {
                loadingClose();
                toast({
                    title: 'importir.com',
                    description: 'Please Choose Logistic First',
                    status: 'error',
                });
                return;
            }

            const params = {
                orders,
                promo_code: inputCodePromo ? inputCodePromo : null,
                firebase_uid: userData.user.uid
            };

            loadingClose()

            try {
                const res = await _axios.post('api/checkout', params);

                if (res.status === true) {

                    const ecommerceData = {
                        'user_id': userData?.user?.uid ?? "",
                        'email': userData?.user?.email ?? "",
                        'location': window.location.pathname,
                        'company_id': "dNNHm2zy4iTSfp1ejV9K",
                        'project_id': "wjS0AhuP4FAF1j0A6tb9",
                        'currency': 'IDR',
                        'value': parseFloat(_.sumBy(carts, 'total')),
                        'items': JSON.stringify(carts),
                    };
                    // console.log(ecommerceData)
                    analytics.track('Checkout', ecommerceData)
                    triggerEventFacebookPixel('InitiateCheckout', ecommerceData)
                    toast({
                        title: 'importir.com',
                        description: 'Success to checkout',
                        status: 'success',
                    });
                    getCart();
                    navigate(`/invoice/${res?.data?.invoice}`);
                } else {
                    toast({
                        title: 'importir.com',
                        description: res.message,
                        status: 'error',
                    });
                    const resSlack = await errorSlack(res.message, 'importir.com', path)
                    console.log(resSlack, 'CONNECTED ERROR IN SLACK')
                }
            } catch (error) {
                console.log(error);
                toast({
                    title: 'importir.com',
                    description: 'An error occurred while processing the checkout.',
                    status: 'error',
                });
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            } finally {
                loadingClose();
            }
        } else {
            toast({
                title: 'importir.com',
                description: 'Please input your agreement.',
                status: 'error',
            });
        }
    };

    const onSubmitPromo = async () => {
        loadingShow()
        let total = 0

        const cbmActive = carts.some(
            (item) =>
                item.use_cbm_lartas > 0 || item.use_cbm_non_lartas > 0
        );


        if (cbmActive) {
            toast({
                title: 'importir.com',
                description: 'You have cbm balance active, cannot access Promo code',
                status: 'warning'
            })
            loadingClose();
            return;
        }

        const res = await _axios.post('api/promo',
            {
                promo_code: codePromo,
                role: 'order'
            }
        )
        if (res.status) {
            const dataArr = res.data
            const datacode = res.data.code
            setInputCodePromo(datacode)
            const promoType = res.data.permissions.find(x => x.key === 'promo_type')
            const promoMethod = res.data.permissions.find(x => x.key === 'promo_method')
            const promoAmount = res.data.permissions.find(x => x.key === 'promo_amount')
            const promoMinimumCBM = res.data.permissions.find(x => x.key === 'minimum_cbm_order')



            if (promoType) {
                if (promoType.value === 'shipping_fee') {
                    if (promoMethod.value === 'percentage') {
                        total = (shippingFee * parseFloat(promoAmount.value)) / 100
                    }
                    else {
                        total = promoMethod.value
                    }
                }

                carts?.forEach((cart, index) => {
                    if (promoType) {
                        if (promoType.value === 'shipping_fee') {
                            let totalLocal = 0
                            let discountLabelGlob = ''
                            if (promoMethod.value === 'percentage') {
                                totalLocal = -1 * ((cart.shippingFee * parseFloat(promoAmount.value)) / 100)
                                discountLabelGlob = "Discount promo code: " + res.data.code + " " + promoAmount.value + "%"
                            } else {
                                totalLocal = cart.shippingFee
                            }
                            if (promoMinimumCBM.key === "minimum_cbm_order") {
                                if (promoMinimumCBM.value === "0") {
                                    calculateFreight(cart, 0)
                                    setShippingFee(_.sumBy(carts, 'shippingFee'))
                                    carts[index].total = cart.price + cart.warehouse_delivery_fee + cart.handlingFee + cart.handlingFeeTax + cart.shippinglocalFee + cart.shippingFee + cart.bm + cart.ppn + cart.pph
                                }
                            }
                            if (totalLocal !== 0) {
                                carts[index].discount_price = totalLocal
                                carts[index].discount_title = discountLabelGlob
                                carts[index].total = cart.price + cart.warehouse_delivery_fee + cart.handlingFee + cart.handlingFeeTax + cart.shippinglocalFee + cart.shippingFee + cart.bm + cart.ppn + cart.pph - totalLocal
                            }
                        } else if (promoType.value === 'shipping_fee_fix_sea_nl') {
                            if (cart?.freight === 'Sea') {
                                let isLartas = cart?.is_lartas
                                if (cart?.product_relation?.category_platform) {
                                    const catPlatform = cart?.product_relation?.category_platform
                                    if (catPlatform?.category_google) {
                                        isLartas = catPlatform?.category_google?.is_lartas
                                    }
                                }

                                if (!isLartas) { // default non lartas
                                    const totalLocalV2 = -1 * (cart.shippingFee - promoAmount.value)
                                    carts[index].discount_price = totalLocalV2
                                    carts[index].discount_title = "Discount promo code: " + res.data.code + " " + promoAmount.value
                                    carts[index].total = cart.price + cart.warehouse_delivery_fee + cart.handlingFee + cart.handlingFeeTax + cart.shippinglocalFee + cart.shippingFee + cart.bm + cart.ppn + cart.pph + totalLocalV2
                                }
                            }
                        } else if (promoType.value === 'shipping_fee_fix_sea_price') {
                            let isLartas = cart?.is_lartas
                            if (cart?.product_relation?.category_platform) {
                                const catPlatform = cart?.product_relation?.category_platform
                                if (catPlatform?.category_google) {
                                    isLartas = catPlatform?.category_google?.is_lartas
                                }
                            }
                            const amount = parseInt(isLartas) === 1 ? 2500000 : 1000000;
                            const cbmVal = cart.cbm < 0.2 ? 0.2 : cart.cbm;
                            let promoTotal = cbmVal * amount;
                            promoTotal = -1 * (cart.shippingFee - promoTotal);
                            carts[index].discount_price = promoTotal
                            carts[index].discount_title = "Discount promo code: " + datacode + " " + promoTotal
                            carts[index].total = cart.price + cart.warehouse_delivery_fee + cart.handlingFee + cart.handlingFeeTax + cart.shippinglocalFee + cart.shippingFee + cart.bm + cart.ppn + cart.pph + promoTotal
                        }
                    }
                })

                generateBillAmount(carts);
            }
            const sumBill = _.sumBy(carts, 'total')
            setTotalBill(sumBill)


            const promoData = { message: dataArr.description, data: total }
            setPromoModal(false)
            setPromoRes(promoData)
            toast({
                title: 'importir.com',
                description: <div dangerouslySetInnerHTML={{ __html: dataArr.description }} />,
                status: 'success'
            })

        }


        if (!res.status) {
            const promoData = { message: res.message }
            setPromoModal(false)
            setPromoRes(promoData)
        }
        loadingClose()
    }

    const removePromoCode = () => {
        setPromoRes(null)
        setInputCodePromo("")
        carts?.forEach((cart, index) => {
            carts[index].discount_price = 0
            carts[index].discount_title = ''
            carts[index].shippingFee = item?.freight === "Sea" ? seaFee.current * (cart.cbm > 0.2 ? cart.cbm : 0.2) : item?.freight === "Air" ? airFee.current : airAmazonJapanFee.current
            carts[index].total = cart.price + cart.warehouse_delivery_fee + cart.handlingFee +
                cart.handlingFeeTax + cart.shippinglocalFee + cart.shippingFee + cart.bm + cart.ppn + cart.pph
        })
        const shippingFeeTotal = _.sumBy(carts, 'shippingFee');
        setShippingFee(shippingFeeTotal)
        const sumBill = _.sumBy(carts, 'total')
        setTotalBill(sumBill)

    }


    const handleCloseModalAddress = async () => {
        setAddressModal(false)
    }

    const handleCbmProduct = (productDetail) => {
        if (inputCodePromo === '') {
            let voucherAct = false;
            carts.forEach((x) => {
                if (x.use_voucher !== undefined) {
                    voucherAct = true
                    if (x.use_voucher === '') {
                        voucherAct = false
                    }
                }
            })

            if (voucherAct) {
                toast({
                    title: 'importir.com',
                    description: 'You have voucher active, cannot access CBM Package',
                    status: 'warning'
                })
                loadingClose();
                return;
            }

            setProductActiveCbm(productDetail)
            setCbmModal(true)
            setCbmInputLartas(0)
            setCbmInputNonLartas(0)

        } else {
            toast({
                title: 'importir.com',
                description: 'You have promo code active, cannot access CBM Package',
                status: 'warning'
            })
        }

    }

    const handleCancelVoucher = (index) => {

        const updatedCartList = carts.map((cartItem, i) => {
            if (i === index) {
                cartItem.use_cbm_lartas = 0;
                cartItem.use_cbm_non_lartas = 0;

                cartItem.voucher = ''
                cartItem.voucher_order_detail_id = ''
                cartItem.total = cartItem.total - cartItem.discount_price
                cartItem.discount_price = 0

                return {
                    ...cartItem,
                };
            }
            return cartItem;
        });
        setCarts(updatedCartList)
    }

    const handleShowVoucher = (index) => {
        // if (inputCodePromo === '') {
        const cbmActive = carts.some(
            (item) =>
                item.use_cbm_lartas > 0 || item.use_cbm_non_lartas > 0
        );

        if (cbmActive) {
            toast({
                title: 'importir.com',
                description: 'You have cbm balance active, cannot access Voucher',
                status: 'warning'
            })
            loadingClose();
            return;
        }

        setProductActiveVoucher(null)
        setCartVoucherIndex(index)
        setVoucherModal(true)

        // } else {
        //     toast({
        //         title: 'importir.com',
        //         description: 'You have promo code active, cannot access Voucher',
        //         status: 'warning'
        //     })
        // }

    }

    const handleModalSubmit = () => {

        const voucherAct = carts.some(
            (item) =>
                item.voucher !== ''
        );


        if (voucherAct) {
            toast({
                title: 'importir.com',
                description: 'You have voucher active, cannot access Cbm Balance',
                status: 'warning'
            })
            loadingClose();
            return;
        }

        if (productActiveCbm) {
            const updatedCartList = carts.map((cartItem, index) => {
                if (cartItem.id === productActiveCbm.id) {
                    let use_cbm_lartas = parseFloat(productActiveCbm.use_cbm_lartas) || 0;
                    let use_cbm_non_lartas = parseFloat(productActiveCbm.use_cbm_non_lartas) || 0;

                    use_cbm_lartas += parseFloat(cbmInputLartas) || 0;
                    use_cbm_non_lartas += parseFloat(cbmInputNonLartas) || 0;

                    return {
                        ...cartItem,
                        use_cbm_lartas,
                        use_cbm_non_lartas,
                    };


                }
                return cartItem;
            });


            loadingClose()
            setCarts(updatedCartList);
            setProductActiveCbm('');
            setCbmInputLartas(0);
            setCbmInputNonLartas(0);
            setCbmModal(false)
            toast({
                title: 'importir.com',
                description: 'Succes to input your cbm Balance',
                status: 'success'
            })
            // Tambahkan logika untuk menutup modal di sini
        }
    };


    const handleCancelCbm = (item) => {
        if (item) {
            const updatedCartList = carts.map((cartItem) => {
                if (cartItem.id === item.id) {
                    cartItem.use_cbm_lartas = 0;
                    cartItem.use_cbm_non_lartas = 0;

                    return {
                        ...cartItem,
                    };
                }
                return cartItem;
            });
            setCarts(updatedCartList)
            toast({
                title: 'importir.com',
                description: 'You have to cancel the cbm active',
                status: 'success'
            })
        }
    }


    const handlingFeeCalculate = (product, isLartas) => {
        const packageUser = store.get('package_user')
        const userDataLoc = store.get('user_api');
        return getHandlingFeePercentage(product?.price, packageUser?.package?.name, product?.freight, isLartas, 'com', userDataLoc?.country_code);;
    }

    const closeModalChangeAddress = () => {
        setAddressModal(false)
    }

    const renderCartItem = () => {
        return carts.map((item, i) => {
            return (<Box bg="white" key={item.id} shadow='md' borderRadius={'md'} p={5}>
                <Grid templateColumns={{ base: "1fr", md: "1fr 3fr" }} >
                    <Image src={item.product_image
                    } alt="Alternate Text" size="sm" borderRadius={5} />
                    <Stack alignItems={'flex-start'} ml={3} spacing={2}>
                        <Text _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800" fontWeight={'bold'} fontSize='sm' >
                            {item.product_name}
                        </Text>
                        <Text color="coolGray.600" _dark={{
                            color: "warmGray.200"
                        }} fontSize='sm'>
                            {t('quantity')} {item.quantity}
                        </Text>
                        <HStack space={2}>
                            {
                                store.get('user_api')?.country_code === 'id' ?
                                    <Tag size="sm" bgColor={item.product_relation.is_lartas > 0 ? 'red.300' : 'yellow.300'} color={colors.black}>
                                        <Text>{item.product_relation.is_lartas > 0 ? 'Lartas' : 'Non Lartas'}</Text>
                                    </Tag>
                                    : ""
                            }
                            {item?.product_relation?.platform_type && (
                                getCountry(item?.product_relation?.platform_type)
                            )}
                            {item?.freight === "Sea" ? (
                                <Tag size="sm" bgColor={colors.black}>
                                    <HStack>
                                        <FaShip size={15} color="white" />
                                    </HStack>
                                </Tag>
                            ) : (
                                <Tag size="sm" bgColor={colors.black}>
                                    <HStack>
                                        <IoAirplaneSharp size={15} color="white" />
                                    </HStack>
                                </Tag>
                            )}
                        </HStack>
                        <Box mt="3">
                            <HStack spacing={3}>
                                <Checkbox value={item.wood_packing} accessibilityLabel="This is a dummy checkbox" onChange={e => onChangeCheckbox(e.target.checked, 'wood', i)} />
                                <Box>
                                    <Text fontSize="sm" color="dark.100">{t('wooden_packing')}</Text>
                                    <Text fontSize="xs" color="red.400">
                                        {t('wooden_packing_agreement')}
                                    </Text>
                                </Box>
                            </HStack>
                            <HStack spacing={3}>
                                <Checkbox value={item.fragile} accessibilityLabel="This is a dummy checkbox" onChange={e => onChangeCheckbox(e.target.checked, 'fragile', i)} />
                                <Box>
                                    <Text fontSize="sm" color="dark.100">{t('fragile')}</Text>
                                    <Text fontSize="xs" color="red.400">
                                        {t('wooden_packing_policy')}
                                    </Text>
                                </Box>
                            </HStack>
                            {
                                store.get('user_api')?.country_code === 'id' ?
                                    <>
                                        <Divider bg="gray.200" thickness="1" my="1.5" />
                                        <Stack py="3" spacing={3}>
                                            <Heading size="xs">{t('logistic')}</Heading>


                                            <Select fontSize='sm' size={'sm'} bgColor={'white'} placeholder={t('choose_address')} onChange={(e) => onSetLogistic(e.target.value, i)}>
                                                {item?.logistic_arr?.map((x, index) => (
                                                    <option value={x.service_code} key={`${index}-p`}>{`${x.service_code} - ${userSymbolCurrency} ${formatFrice(convertPriceFix(x.fee_total))}`}</option>
                                                ))}
                                            </Select>

                                            {/* <Text fontSize="xs" color="red.400">
                                                {t('anteraja_info')}
                                            </Text> */}
                                        </Stack>
                                    </>
                                    : ""
                            }

                            <Divider bg="gray.200" thickness="1" my="1.5" />
                            <HStack alignItems={'center'} justifyContent='center'>
                                <Heading size="sm" color="#354259">{userSymbolCurrency} {formatFrice(convertPriceFix(item.total))}</Heading>
                                <Spacer />
                                <VStack >
                                    <Button
                                        size={'sm'}
                                        fontSize='sm'
                                        onClick={() => showBillDetail(i)}
                                        _text={{
                                            _light: {
                                                color: "cyan.500"
                                            },
                                            color: "cyan.300"
                                        }}
                                    >
                                        {t('show_detail')}
                                    </Button>
                                </VStack>
                            </HStack>
                        </Box>
                    </Stack>
                </Grid>
                <Divider my={3} bg="gray.200" thickness="1" />
                <Stack spacing={3}>
                    <VStack alignItems="center" justifyContent="center" space={2}>
                        <Text fontSize={'sm'}>[ {t('estimated_weight')} : {item.total_weight} Kg ]</Text>
                        <Text fontSize={'sm'}>[ {t('estimated_cbm')} : {item.cbm} CBM ]</Text>
                    </VStack>

                    {
                        store.get('user_api')?.country_code === 'id' ?
                            <Center my={2}>
                                {item?.freight === "Sea" && (
                                    <Stack>

                                        {item.use_cbm_lartas > 0 || item.use_cbm_non_lartas > 0 ? (
                                            <Stack spacing={1}>
                                                <Button size="sm" colorScheme={'red'} onClick={() => handleCancelCbm(item)}>
                                                    <HStack space={2} alignItems="center" justifyContent="center">
                                                        <Text>{t('cancel')}</Text>
                                                    </HStack>
                                                </Button>
                                                <HStack fontSize={'sm'}>
                                                    <Text>{t('use_cbm_balance')} :</Text>
                                                    {item.use_cbm_lartas > 0 && item.use_cbm_non_lartas > 0 ? (
                                                        <Text color='red'>{Math.min(item.use_cbm_lartas, item.use_cbm_non_lartas)}</Text>
                                                    ) : item.use_cbm_lartas > 0 ? (
                                                        <Text color='red'>{item.use_cbm_lartas}</Text>
                                                    ) : (
                                                        <Text color='red'>{item.use_cbm_non_lartas}</Text>
                                                    )}
                                                </HStack>
                                            </Stack>
                                        ) : (
                                            <Button size="sm" bgColor={colors.theme} onClick={() => handleCbmProduct(item)}>
                                                <HStack space={2} alignItems="center" justifyContent="center">
                                                    <Text>{t('use_cbm_balance')}</Text>
                                                </HStack>
                                            </Button>
                                        )}

                                    </Stack>

                                )}

                                {item.voucher === '' && userDb?.data_importir?.user?.country_code === 'id' && moment().isAfter(expirationDate)  ?
                                    <Button ml={2} size="sm" bgColor="red.500" color="white" onClick={() => handleShowVoucher(i)}>
                                        <HStack space={2} alignItems="center" justifyContent="center">
                                            <Text>{t('use_voucher')}</Text>
                                        </HStack>
                                    </Button>
                                    :
                                    (item.voucher !== "" ?
                                        <Button ml={2} size="sm" bgColor="coolGray.500" onClick={() => handleCancelVoucher(i)}>
                                            <HStack space={2} alignItems="center" justifyContent="center">
                                                <Text>{t('cancel_voucher')} ({item.voucher.title})</Text>
                                            </HStack>
                                        </Button>
                                        :
                                        ""
                                    )
                                }
                            </Center>
                            : ""
                    }
                </Stack>

            </Box>)
        })
    }



    const renderBillItem = () => {
        const bills = [
            { label: t('product'), amount: productPrice },
            { label: t('warehouse_delivery_fee'), amount: warehouseDeliveryFee },
            { label: t('handling_fee'), amount: handlingFee },
            { label: t('ppn_handling_fee'), amount: handlingFeeTax },
            { label: t('delivery_fee_local'), amount: shippinglocalFee },
            // { label: `${item?.product_relation?.platform_type === "amazonjapan" ? 'Shipping fee Japan-Indonesia' : (item?.product_relation?.platform_type === "amazonus" ? 'Shipping fee US-Indonesia' : 'Shipping fee China-Indonesia')}`, amount: shippingFee },
            { label: t('shipping_fee_to', {country: store.get('user_api')?.country_code === 'id' ? 'Indonesia' : "Philippines"}), amount: shippingFee },
            { label: `${t('bm')} (${percentageBm}%)`, amount: bm },
            { label: `${t('ppn')} (${percentagePpn}%)`, amount: ppn },
            { label: `${t('pph')} (${percentagePph}%)`, amount: pph },
            { label: t('discount'), amount: discShippingFee },
            { label: t('estimated_total_purchase'), amount: totalBill },
        ]
        return bills.map((x, index) => {
            return (
                <HStack spacing={3} justifyContent="space-between" p={1} key={index}>
                    <Text _dark={{
                        color: "warmGray.50"
                    }} color="coolGray.500" fontSize="xs">
                        {x.label}


                    </Text>
                    <Text color="coolGray.600" fontSize="xs" _dark={{
                        color: "warmGray.200"
                    }}>
                        {userSymbolCurrency}  {isNaN(x.amount) ? 0 : formatFrice(convertPriceFix(x.amount))}
                    </Text>
                </HStack>
            )
        })
    }

    const renderSingleItem = () => {
        if (!item) return <></>

        const bills = [
            { label: `${item.product_name} /@ ${item.quantity} `, amount: item.price },
            { label: t('warehouse_delivery_fee'), amount: item.warehouse_delivery_fee },
            { label: `${t('handling_fee')} (${parseInt(item.handlingFeePercentage * 100)} %)`, amount: item.handlingFee },
            { label: t('ppn_handling_fee'), amount: item.handlingFeeTax },
            { label: t('delivery_fee_local'), amount: item.shippinglocalFee },
            { label: `${item?.product_relation?.platform_type === "amazonjapan" ? 'Shipping fee Japan-' : (item?.product_relation?.platform_type === "amazonus" ? "Shipping fee US-" : (item?.product_relation?.platform_type === "coupang" ? 'Shipping fee Korea-' : 'Shipping fee China-'))}${store.get('user_api')?.country_code === 'id' ? 'Indonesia' : "Philippines"}`, amount: item.shippingFee },
            { label: `${t('bm')} (${percentageBm}%)`, amount: item.bm },
            { label: `${t('ppn')} (${percentagePpn}%)`, amount: item.ppn },
            { label: `${t('pph')} (${percentagePph}%)`, amount: item.pph },
            { label: t('discount'), amount: (isNaN(item.discount_price) ? 0 : item.discount_price) },
            { label: t('estimated_total_purchase'), amount: item.total },
        ]
        return bills.map((x, index) => {
            return (
                <HStack space={[2, 3]} justifyContent="space-between" py="2" key={index}>
                    <Text _dark={{
                        color: "warmGray.50"
                    }} color="coolGray.500" fontSize="xs">
                        {x.label}
                    </Text>
                    <Text color="coolGray.600" fontSize="xs" _dark={{
                        color: "warmGray.200"
                    }}>
                        {userSymbolCurrency} {formatFrice(convertPriceFix(x.amount))}
                    </Text>
                </HStack>
            )
        })
    }

    const ModalPromo = () => {
        return (
            <>
                <Button onClick={() => setPromoModal(true)} bgColor="green.600" size={'sm'}>
                    <Text color={colors.white} fontWeight="bold">{t('apply_promo')}</Text>
                </Button>
            </>
        )
    }

    const handleChangeVoucher = (e) => {
        setProductActiveVoucher(voucherData[e.target.value])
    }

    const handleSubmitVoucher = () => {

        const voucherUser = carts.some(
            (item) =>
                item.voucher.id === productActiveVoucher.id
        );


        if (voucherUser) {
            toast({
                title: 'importir.com',
                description: 'You have used this voucher, please use other voucher',
                status: 'warning'
            })
            loadingClose();
            return;
        }

        setVoucherModal(false)

        let priceDisc = parseFloat(productActiveVoucher.price)
        if (productActiveVoucher.tag !== null) {
            if (productActiveVoucher.tag === 'product') {
                priceDisc = carts[cartVoucherIndex].price
            }
            if (productActiveVoucher.tag === 'bm') {
                priceDisc = carts[cartVoucherIndex].bm
            }
            if (productActiveVoucher.tag === 'ppn') {
                priceDisc = carts[cartVoucherIndex].ppn
            }
            if (productActiveVoucher.tag === 'pph') {
                priceDisc = carts[cartVoucherIndex].pph
            }
            if (productActiveVoucher.tag === 'shipping_fee') {
                priceDisc = carts[cartVoucherIndex].shippingFee
            }
            if (productActiveVoucher.tag === 'handling_fee') {
                priceDisc = carts[cartVoucherIndex].handlingFee
            }
            priceDisc = priceDisc > productActiveVoucher.price ? productActiveVoucher.price : priceDisc
        }

        carts[cartVoucherIndex].discount_price = -1 * priceDisc
        carts[cartVoucherIndex].voucher = productActiveVoucher
        carts[cartVoucherIndex].voucher_order_detail_id = productActiveVoucher.voucher_order_detail_id
        carts[cartVoucherIndex].total = carts[cartVoucherIndex].total - priceDisc

        generateBillAmount(carts);
    }

    return (
        <Stack minH={height} bgColor='gray.100'>
            <Stack p={[1, 2, 5]} spacing={5} mt={[10, 10, 0]}>
                <HStack cursor='pointer' w={'100px'} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                    <IoCaretBackOutline size={15} />
                    <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
                </HStack>

                <Stack>
                    <AddressCard  {...activeAddress} changeAddress={() => changeAddress()} />
                </Stack>

                <Stack   >
                    <Grid templateColumns={{ base: "1fr", md: "3fr 1fr" }} gap={3}>
                        <Stack borderRadius='xl' >
                            <Text fontSize={'lg'} fontWeight='bold'>{t('product')}</Text>

                            {renderCartItem()}
                        </Stack>
                        <Stack>
                            <Text fontSize={'lg'} fontWeight='bold'>{t('bill_detail')}</Text>
                            <Stack shadow='md' borderRadius={'md'} p={5} bgColor='white'>
                                {renderBillItem()}
                            </Stack>

                            {
                                store.get('user_api')?.country_code === 'id' ?
                                    <Stack bg="white" p={5} borderRadius='md' spacing={3} shadow={'md'}>
                                        <Heading size="sm" color="green.700">{t('promo_code')}</Heading>
                                        <Stack spacing={3}>
                                            {promoRes !== null ? (
                                                <Stack borderRadius={'md'} borderWidth={2} borderColor={'green.300'} p={3}>
                                                    <Text></Text>
                                                    <div style={{
                                                        fontSize: 15,
                                                        paddingLeft: 16,
                                                    }} dangerouslySetInnerHTML={{ __html: promoRes.message }} />
                                                    <Stack>
                                                        <Button colorScheme={'red'} size='sm' onClick={() => removePromoCode()}>{t('cancel')}</Button>
                                                    </Stack>
                                                </Stack>

                                            ) : (
                                                <Stack>
                                                    <Text fontSize={'sm'}>{t('click_to_use_promo_code')}</Text>
                                                    <HStack>
                                                        <Box h="100%" w="100%" alignItems="center">

                                                            <ModalPromo />

                                                        </Box>
                                                    </HStack>
                                                </Stack>
                                            )}

                                        </Stack>
                                    </Stack>
                                    : ""
                            }

                            <HStack bg="white" alignItems="center" borderRadius='md' shadow={'md'}>
                                <Box py="3" mx="auto">
                                    <Button
                                        width="100%"
                                        onClick={() => setOrderModal(true)}
                                        bg={colors.theme}
                                    >
                                        <Text color={colors.black}>{t('create_order')}</Text>
                                    </Button>
                                </Box>
                            </HStack>
                        </Stack>
                    </Grid>
                </Stack>




            </Stack>




            <Modal isOpen={addressModal} onClose={() => handleCloseModalAddress()} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('choose_address')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <AddressList data={addreses} updateData={() => fetchData()} setActive={onsetActiveAddress} openModal={closeModalChangeAddress} />
                    </ModalBody>

                </ModalContent>
            </Modal>



            <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('bill_detail')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        {renderSingleItem()}
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal isOpen={voucherModal} onClose={() => setVoucherModal(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        Voucher
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Select fontSize='sm' size={'sm'} bgColor={'white'} placeholder={t('choose_voucher')} onChange={(e) => handleChangeVoucher(e)}>
                            {voucherData.map((x, index) => (
                                <option value={index} key={`${index}-p`} disabled={x.is_expired ? true : false}>
                                    {x.title} ({t('invoice_number')}: {x.invoice_no} ) { x.is_expired ? ' / Expired' : '' }
                                </option>
                            ))}
                        </Select>
                        {productActiveVoucher !== null && (
                            <>
                                <Box>
                                    <Text color={"red"} my={2} fontSize={"sm"}>
                                        * {productActiveVoucher?.description}
                                    </Text>
                                </Box>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="coolGray" variant="ghost" onClick={() => setVoucherModal(false)}>
                            {t('cancel')}
                        </Button>
                        {promoRes === null ? (
                            <Button bgColor={colors.theme} onClick={() => handleSubmitVoucher()}>
                                <Text>{t('submit')}</Text>
                            </Button>
                        ) : (
                            <Button bgColor={colors.theme} disabled onClick={() => handleSubmitVoucher()}>
                                <Text color={colors.white}>{t('submit')}</Text>
                            </Button>
                        )}
                    </ModalFooter>

                </ModalContent>
            </Modal>

            <Modal isOpen={promoModal} onClose={() => setPromoModal(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('promo_code')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder='xxxxxxxxx'
                            id="address"
                            color={colors.black}
                            fontWeight="bold"
                            onChange={(e) => setCodePromo(e.target.value)}
                        />
                        {promoRes !== null && (
                            <>
                                {promoRes?.data !== undefined ? (
                                    <Stack p={2}>
                                        <div dangerouslySetInnerHTML={{ __html: promoRes.message }} />
                                        <Box>{t('amount')} : {userSymbolCurrency} {formatFrice(convertPriceFix(Number(promoRes?.data)))}</Box>
                                    </Stack>
                                ) : (
                                    <>
                                        <Box>
                                            <Text color={colors.danger}>{promoRes?.message}</Text>
                                        </Box>
                                    </>
                                )}
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="coolGray" variant="ghost" onClick={() => setPromoModal(false)}>
                            {t('cancel')}
                        </Button>
                        {promoRes === null ? (
                            <Button bgColor={colors.theme} onClick={() => onSubmitPromo()}>
                                <Text>{t('submit')}</Text>
                            </Button>
                        ) : (
                            <Button bgColor={colors.theme} disabled onClick={() => onSubmitPromo()}>
                                <Text color={colors.white}>{t('submit')}</Text>
                            </Button>
                        )}
                    </ModalFooter>

                </ModalContent>
            </Modal>


            <Modal isOpen={orderModal} onClose={() => setOrderModal(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('payment_confirmation')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack m={3} spacing={2}>
                            <Text dangerouslySetInnerHTML={{
                                __html: t('we_always_transparent',
                                    { transparent: '<strong style="color: rgb(48, 133, 214); font-weight: bold;">' + t('transparent') + '</strong>' })
                            }} />
                            {/* <Text>
                                {t('get_additional_fee_info')}:
                            </Text> */}
                            {/* <Text fontSize='sm' >We are always Transparent In the process you may get additional price information / notifications if :</Text> */}
                            <Stack m={3} spacing={2} fontSize='sm'>
                                <Text>* {t('estimated_info')}</Text>
                                <Text>* {t('tax_diff_info')}</Text>
                                <Text>* {t('additional_shipping_fee_info')}</Text>
                                <Text>* {t('additional_request_fragile_info')}</Text>
                                <Text>* {t('additional_quality')}</Text>
                                <Text>* {t('additinal_free_ongkir')}</Text>
                                <Text>* {t('item_may_opened_info')}</Text>
                                <Text>* {t('complaint_deadline')}</Text>
                            </Stack>

                            <Stack spacing={2} fontSize='sm' alignItems={'flex-start'}>
                                <HStack space={2}>
                                    <Text>{t('please_type')} :</Text>
                                    <Text bgColor={colors.theme} fontWeight="bold" p={1} borderRadius='sm' >I AGREE</Text>
                                </HStack>
                                <Input
                                    placeholder='I AGREE'
                                    id="agree"
                                    color={colors.black}
                                    fontWeight="bold"
                                    size={'sm'}
                                    onChange={(e) => setAgreeText(e.target.value)}
                                />
                            </Stack>

                            <Stack my={5} spacing={2}>
                                <Stack alignItems={'flex-start'}>
                                    <button onClick={() => setMoreInfo(true)}>
                                        <Text fontSize={'xs'} color='blue.400'>{t('click_for_detail')}</Text>
                                    </button>
                                </Stack>
                                <HStack spacing={2}>
                                    <Checkbox value={agreeBol} accessibilityLabel="This is a dummy checkbox" onChange={(e) => onChangeAgree(e.target.checked)} />
                                    <Text fontWeight="bold" fontSize={'sm'}>{t('i_agree_with_info')}</Text>
                                </HStack>
                            </Stack>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="coolGray" variant="ghost" onClick={() => setOrderModal(false)}>
                            {t('cancel')}
                        </Button>
                        <Button bgColor={colors.theme} onClick={() => onSubmit()}>
                            <Text color={colors.black}>{t('submit')}</Text>
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>

            <Modal isOpen={moreInfo} onClose={() => setMoreInfo(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('information_detail')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack m={3} spacing={2}>
                            <Text fontSize='sm' >{t('in_warehouse_indo_policy')}</Text>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button bgColor={colors.theme} onClick={() => setMoreInfo(false)}>
                            <Text color={colors.black}>OK</Text>
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>

            {productActiveCbm && (
                <Modal isOpen={cbmModal} onClose={() => setCbmModal(false)} >
                    <ModalOverlay />
                    <ModalContent >
                        <ModalHeader>
                            {t('input_balance_cbm')}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody >
                            <VStack>
                                <SimpleGrid columns={[1, null, 2]} gap={2} alignItems="center" justifyContent="center" m={2}>
                                    {/* CBM All Product Balance */}
                                    {/* {cbmData.balance_lartas > 0 ? ( */}
                                        <Stack alignItems="center" justifyContent="center">
                                            <Text fontSize="sm" color={colors.danger}>
                                                CBM All Product Balance:
                                            </Text>
                                            <Spacer />
                                            <Heading size="lg" textAlign="center">
                                                {cbmData.balance_lartas}
                                            </Heading>
                                        </Stack>
                                    {/* ) : null} */}

                                    {/* CBM Product Selected Balance */}
                                    {cbmData.balance_non_lartas > 0 ? (
                                        <Stack alignItems="center" justifyContent="center">
                                            <Text fontSize="sm" color={colors.danger}>
                                                CBM Product Selected Balance:
                                            </Text>
                                            <Spacer />
                                            <Heading size="lg" textAlign="center">
                                                {cbmData.balance_non_lartas}
                                            </Heading>
                                        </Stack>
                                    ) : null}

                                    {/* Used CBM Total */}
                                    <Stack
                                        alignItems="center"
                                        justifyContent="center"
                                        gridColumn={{ base: '1 / -1', md: 'span 2' }} // Memastikan elemen ini berada di tengah saat kolom lebih dari 2
                                        textAlign="center" // Memusatkan teks di dalam Stack
                                    >
                                        <Text fontSize="sm">{t('used_cbm_total')}:</Text>
                                        <Spacer />
                                        <Heading size="lg">
                                            {productActiveCbm?.product_relation?.is_lartas > 0
                                                ? (cbmInputLartas ? cbmInputLartas : 0)
                                                : (cbmInputNonLartas ? cbmInputNonLartas : 0)}
                                        </Heading>
                                    </Stack>
                                </SimpleGrid>

                                {/* lartas */}
                                <HStack spacing={2}>
                                    <Stack w={'50px'}>
                                        <Text>
                                            APB :
                                        </Text>
                                    </Stack>
                                    <Input
                                        placeholder={'All Product Balance'}
                                        isDisabled={productActiveCbm?.product_relation?.is_lartas > 0 || cbmData.balance_lartas > 0 ? false : true}
                                        type='number'
                                        fontSize={'sm'}
                                        size='sm'
                                        alignItems={'center'}
                                        justifyContent='center'
                                        color={colors.black}
                                        fontWeight="bold"
                                        value={cbmInputLartas}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value <= productActiveCbm?.cbm) {
                                                setCbmInputLartas(value);
                                            }
                                        }}
                                    />
                                </HStack>

                                {/* non lartas */}
                                <HStack spacing={2}>
                                    <Stack w={'50px'}>
                                        <Text>
                                            SPB :
                                        </Text>
                                    </Stack>
                                    <Input
                                        placeholder={'Selected Product Balance'}
                                        isDisabled={productActiveCbm?.product_relation?.is_lartas > 0 ? true : false}
                                        type='number'
                                        fontSize={'sm'}
                                        size='sm'
                                        alignItems={'center'}
                                        justifyContent='center'
                                        color={colors.black}
                                        fontWeight="bold"
                                        value={cbmInputNonLartas}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value <= productActiveCbm?.cbm) {
                                                setCbmInputNonLartas(value);
                                            }
                                        }}
                                    />
                                </HStack>

                                <Stack pt={2}>
                                    <Text fontSize={'xs'} fontStyle='italic' color={'red.300'}>{t('maximum_input_cbm_balance', { cbm: productActiveCbm?.cbm })}</Text>
                                </Stack>
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="coolGray" variant="ghost" onClick={() => setCbmModal(false)}>
                                {t('cancel')}
                            </Button>
                            <Button bgColor={colors.theme} onClick={() => handleModalSubmit()}>
                                <Text color={colors.black}>{t('submit')}</Text>
                            </Button>
                        </ModalFooter>

                    </ModalContent>
                </Modal>
            )}





        </Stack>
    )
}


export default CheckoutFreeMemberPage