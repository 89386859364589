/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box, Button, HStack, Stack, Table, Tbody, Text, Th, Thead, Tr, useToast, Grid, Td, Image, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure, Input, TableContainer, Icon, Badge,
    Divider
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import CustomStepperShipping from '../../Components/Stepper/CustomStepperShipping'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import { formatFrice } from '../../Utils/Helper'
import ShippingChatToAdmin from '../../Components/Chat/ShippingChatToAdmin'
import _axiosChina from '../../Api/AxiosChina'
import ConvertPrice from '../../Utils/ConvertPrice'
import store from 'store'

import { FaPrint } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { FcLink } from "react-icons/fc";
import { checkQuizOnboarding } from '../../Routes/UserMiddleware'

function InvoiceShippingListDetailPage() {
    const { t } = useTranslation();
    const userDataLoc = store.get('user_api');
    useEffect(() => {
        checkQuizOnboarding(userDataLoc?.detail?.must_answer);
    }, []);

    const { loadingShow, loadingClose, kursRates, userSymbolCurrency, userCurrency } = useContext(AuthContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const params = useParams()
    const toast = useToast({
        position: "top",
        align: "center",
    });
    const [shipping, setShipping] = useState()
    const [image, setImage] = useState('')
    const [title, setTitle] = useState('')
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);
    const [editingData, setEditingData] = useState(null)
    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])
    const [shippingId, setShippingId] = useState(0)
    const url = process.env.REACT_APP_API_COM
    const { convertPriceFix } = ConvertPrice()
    const user = store.get('user_api')
    const [shippingFile, setShippingFile] = useState([])
    const fetchShipping = async () => {
        try {
            loadingShow()
            const { status, data, message } = await _axios.get(`/api/shipping/${params.id}`)
            if (status === true) {
                setShipping(data)
                if (data?.statuses?.length > 0) {
                    const dataArr = data.statuses
                    setCurrentStep(data.statuses[0].name)
                    setMessageStep(data.statuses[0].message)
                    setShippingId(data.id)
                    setSteps(dataArr.reverse())
                }

            } else {
                Swal.fire({
                    title: 'Warning Message',
                    text: message,
                    icon: 'warning',
                    showCancelButton: false,
                })
            }
            loadingClose()
        } catch (error) {
            toast({
                title: 'importir.com',
                description: "error catch " + error,
                status: 'error',
            })
        }
    }

    const handleWa = () => {
        const message = encodeURIComponent(`Hi Member Importir,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
        const source = encodeURIComponent(window.location.href);
        const url = `https://api.whatsapp.com/send?phone=6287800174596&text=${message}%0A%0ASource:%20${source}`;
        window.open(url, '_blank');
    }

    const handleUploadImage = async (e) => {
        const uploadFile = e.target.files[0];
        try {
            const params = new FormData();
            params.append('file', uploadFile);
            loadingShow()
            const response = await _axios.post('api/storage', params);
            setImage(response.data)
            loadingClose()
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async () => {
        try {
            let response;
            const data = {
                name: title,
                image: image
            };

            loadingShow();
            if (editingData) {
                response = await _axios.post(`/api/shipping/${editingData}/edit-file`, data);
            } else {
                response = await _axios.post(`/api/shipping/${params.id}/additional-file`, data);
            }

            loadingClose();

            if (response.status) {
                window.location.reload()
            } else {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message || 'An error occurred',
                status: 'error',
            });
        }
    };

    const onDelete = async (item, index) => {
        try {
            loadingShow()
            const response = await _axios.delete(`/api/shipping/${item.id}/delete-file`)
            loadingClose()
            if (response.status) {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'success',
                });
                fetchShipping()
            } else {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const openDetailsModal = (bill) => {
        setSelectedBill(bill);
        setShowDetailsModal(true);
    };

    const closeDetailsModal = () => {
        setSelectedBill(null);
        setShowDetailsModal(false);
    };

    const handlePayLartas = async (invoice) => {
        const resp = await _axiosChina.get(`payment/shipping/v2/${invoice}/init-payment`)
        if (resp.status) {
            window.location.href = resp.data.redirect_url
        }
    }

    const caclPriceWithInvoiceRate = (invoice_id, total) => {
        let currency = 'RMB';
        if (user?.country_code === 'ph') {
            currency = 'PHP'
        }
        let gt = 0;
        shipping?.rates?.forEach((e, i) => {
            if (e.shipping_bill_id === invoice_id && e.from === currency) {
                gt = total / e.rate;
            }
        })
        return gt;
    }

    const getShippingFile = async () => {
        try {
            const res = await _axios.get(`/api/shipping/${params.id}/fetch-file-shipping`)
            if (res.status === true) {
                setShippingFile(res.data)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    useEffect(() => {
        fetchShipping()
        getShippingFile()
        return () => {
        }
    }, [])
    return (
        <Stack spacing={5} p={[1, 1, 5]} mt={[10, 10, 0]}>
            <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate("/shipping")}>
                <IoCaretBackOutline size={15} />
                <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
            </HStack>


            <Stack spacing={5} p={[1, 1, 5]}>

                <Stack>
                    <Text fontSize='xl' fontWeight='bold'>{t('product')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <TableContainer width={'100%'}>
                            <Table variant='striped' colorScheme='teal'>
                                <Thead>
                                    <Tr>
                                        <Th textAlign={'center'}>{t('product_image')}</Th>
                                        <Th textAlign={'center'}>{t('name')}</Th>
                                        <Th textAlign={'center'}>Hscode</Th>
                                        <Th textAlign={'center'}>{shipping?.country === 'china' ? 'Price/Pcs (RMB)' : 'Price/Pcs (THB)'}</Th>
                                        <Th textAlign={'center'}>{t('quantity')}</Th>
                                        <Th textAlign={'center'}>{shipping?.country === 'china' ? `${t('total')} (RMB)` : `${t('total')} (THB)`}</Th>
                                        <Th textAlign={'center'}>{t('total')} ({userCurrency})</Th>
                                        <Th textAlign={'center'}>{t('other')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {shipping?.products.map((x, i) =>
                                        <Tr key={i}>
                                            <Td>
                                                <Image src={x.image} height={'100'}></Image>
                                            </Td>
                                            <Td>{x.name}</Td>
                                            <Td>{x.hs_code}</Td>
                                            <Td>{shipping.country === 'china' ? '¥' : '฿'} {x.price / x.quantity}</Td>
                                            <Td>{x.quantity}</Td>
                                            <Td>{shipping.country === 'china' ? '¥' : '฿'} {x.price}</Td>
                                            <Td>{userSymbolCurrency} {formatFrice(convertPriceFix(parseInt(shipping.country === 'china' ? x.price * (kursRates?.rmb) : x.price * (kursRates?.thb))))}</Td>
                                            <Td>

                                                {!x.is_batteray ? "" : ' Batteray '}
                                                {!x.is_liquid ? "" : ' Liquid '}
                                                {!x.is_msds ? "" : ' MSDS '}
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='xl' fontWeight='bold'>{t('invoice_list')}</Text>
                    <Stack bgColor='white' spacing={1} overflowY={'auto'} borderRadius='xl' p={3} shadow='md'>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm'>#</Th>
                                    <Th fontSize='sm'>{t('invoice_number')}</Th>
                                    <Th fontSize='sm'>Status</Th>
                                    <Th fontSize='sm'>{t('status')}</Th>
                                    <Th fontSize='sm'>{t('actions')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {shipping?.bills?.length > 0 && shipping?.bills?.map((x, index) => (
                                    x.published_at ? (
                                        <Tr>
                                            <Td fontSize='sm'>{index + 1}</Td>
                                            <Td fontSize='sm'>{x?.name}</Td>
                                            <Td fontSize='sm'>{x?.paid_at ? <Badge colorScheme='purple'>Paid</Badge> : <Badge colorScheme='red'>Unpaid</Badge>}</Td>
                                            {
                                                userDataLoc === undefined ?
                                                    <Td fontSize='sm'>{formatFrice(x?.total)}</Td>
                                                    :
                                                    userDataLoc?.country_code === 'id' ?
                                                        <Td fontSize='sm'>{formatFrice(x?.total)}</Td>
                                                        :
                                                        <Td>PHP {formatFrice(caclPriceWithInvoiceRate(x?.id, x?.total))}</Td>
                                            }
                                            {/* <Td fontSize='sm'>{formatFrice(x.total)}</Td> */}
                                            <Td fontSize='sm'>
                                                <Button colorScheme='green' onClick={() => openDetailsModal(x)}>Show All </Button>
                                                {
                                                    user?.country_code === "id" ?
                                                        !shipping?.is_lartas ? (
                                                            <Button colorScheme='blue' ml={'4'} onClick={() => navigate(`/shipping/payment/${x?.invoice}`)}>Detail</Button>
                                                        ) : (
                                                            <Button colorScheme='blue' ml={'4'} onClick={() => handlePayLartas(x?.invoice)}>Detail</Button>
                                                        )
                                                        :
                                                        <Button colorScheme='blue' ml={'4'} onClick={() => navigate(`/shipping/payment/${x?.invoice}`)}>Detail</Button>
                                                }

                                                <Button colorScheme='orange' ml={'4'} onClick={() => navigate(`/shipping/escrow/${x?.id}`)}>Escrow</Button>
                                            </Td>
                                        </Tr>
                                    ) : null
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>

                {/* <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('chat_to_admin')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <ShippingChatToAdmin shippingId={shippingId}/>
                </Stack>
            </Stack> */}
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{editingData ? "Edit Additional File" : "Add Additional File"}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={3}>
                            <Text>Title</Text>
                            <Input type="text" variant='outline' placeholder='Please input title' defaultValue={title} onChange={(e) => setTitle(e.target.value)} />

                            <Text>Image</Text>
                            <Input type="file" variant='outline' onChange={(e) => handleUploadImage(e)} />

                            <Stack direction='row'>
                                {image !== '' ?
                                    <Image
                                        boxSize='150px'
                                        objectFit='cover'
                                        src={image}
                                        alt={title}
                                    />
                                    :
                                    <Text></Text>
                                }
                            </Stack>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' onClick={() => onSubmit()}>{editingData ? "Save Changes" : "Submit"}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Bills Modal */}

            <Modal isOpen={showDetailsModal} size={'4xl'} onClose={closeDetailsModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bills Detail</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Table variant='striped' colorScheme='gray' p={4}>
                            <Thead>
                                <Tr bgColor={'#ffd600'}>
                                    <Td>
                                        <Text fontSize={'md'} fontWeight={'bold'}>Bill</Text>
                                    </Td>
                                    <Td>
                                        <Text fontSize={'md'} fontWeight={'bold'}>Amount</Text>
                                    </Td>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {selectedBill?.details?.length > 0 && selectedBill?.details.map((x, i) => {
                                    return (
                                        <Tr key={i}>
                                            <Td>
                                                <Text  fontSize='sm' textTransform={'capitalize'} fontWeight={500} >{x?.title}</Text>
                                            </Td>

                                            <Td fontSize='sm'>
                                                <Text fontSize='sm' textTransform={'capitalize'} fontWeight={500} >{userSymbolCurrency} {formatFrice(convertPriceFix(x?.amount))}</Text>
                                            </Td>

                                        </Tr>
                                    )

                                })

                                }
                                <Tr>
                                    <Td fontSize='sm' textTransform={'capitalize'} fontWeight={500} >Total</Td>
                                    <Td fontSize='sm' textTransform={'capitalize'} fontWeight={'bold'} >
                                        {userSymbolCurrency} {formatFrice(convertPriceFix(selectedBill?.total))}
                                    </Td>
                                </Tr>
                            </Tbody>
                            <Divider py={2}  color={'black'}/>
                            <HStack pt={4}>
                                {selectedBill?.total < 0 ? 
                                <Stack fontSize={'sm'}>
                                <Text fontWeight={'500'}>Dear Customer,</Text> 
                                <Text>it appears that your payment has exceeded the billed amount. Please <Text as={'span'} color={'blue.500'} fontWeight={'500'} onClick={() => handleWa()}>Contact our admin</Text> to initiate the refund process if the payment has already been made. </Text> 
                                <Text></Text> 
                                <Text fontWeight={'500'}>Thank you.</Text> 
                                </Stack>
                                : 
                                ''}   
                            </HStack>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={closeDetailsModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
}

export default InvoiceShippingListDetailPage