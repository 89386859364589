/* eslint-disable default-case */
import { HStack, Text, useColorModeValue as mode } from '@chakra-ui/react'

export function formatFrice(value) {
  if (typeof value === 'number') {
    if (value < 1000) {
      let val = value.toFixed(2);
      if (val === '0.00') {
        val = '0';
      } else {
        val = parseFloat(val).toString();
      }
      return val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    return "0";
  }
}

export function formatPrice(value, opts = {}) {
    const { locale = "en-US", currency = "IDR" } = opts;

    // Ensure that the value is a valid number
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
        // Handle the case where the value is not a valid number
        return 0;
    }

    const formatter = new Intl.NumberFormat(locale, {
        currency,
        style: "currency",
        maximumFractionDigits: 0,
    });

    return formatter.format(numericValue);
}

export const PriceTag = (props) => {
	const { price, currency, salePrice, rootProps, priceProps, salePriceProps } = props
	return (
		<HStack spacing="1" {...rootProps}>
			<Price isOnSale={!!salePrice} textProps={priceProps}>
				{formatPrice(price, {
					currency,
				})}
			</Price>
			{salePrice && (
				<SalePrice {...salePriceProps}>
					{formatPrice(salePrice, {
						currency,
					})}
				</SalePrice>
			)}
		</HStack>
	)
}

const Price = (props) => {
	const { isOnSale, children, textProps } = props
	const defaultColor = mode('gray.700', 'gray.400')
	const onSaleColor = mode('gray.400', 'gray.700')
	const color = isOnSale ? onSaleColor : defaultColor
	return (
		<Text
			as="span"
			fontWeight="medium"
			color={color}
			textDecoration={isOnSale ? 'line-through' : 'none'}
			{...textProps}
		>
			{children}
		</Text>
	)
}

const SalePrice = (props) => (
	<Text as="span" fontWeight="semibold" color={mode('gray.800', 'gray.100')} {...props} />
)


export function formatCategoryName(category) {
  if (category) {
    return category.split('-').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  } else {
    return '';
  }
}

export function readMore (val) {
  if(!val) return ''
  val = val.replace(/\s{2,}/g, ' ')
  const strLength = val.length
  if(strLength > 18) {{
    val = val.slice(0,18) + '...'}

  }
  return val.toLowerCase()
    .replace(/\w/, firstLetter => firstLetter.toUpperCase())
}

export function convertDateToDateString(inputDate) {
	if (inputDate == '') return '';
	const months = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	
	const parts = inputDate.split("-");
	const year = parts[0];
	const monthIndex = parseInt(parts[1]) - 1;
	const day = parts[2];

	const formattedDate = `${day} ${months[monthIndex]} ${year}`;
	return formattedDate;
}
export function getHandlingFeePercentage(price, packageName = 'gold', freight = 'sea', isLartas = 0, type = 'com', country = 'id') {
    const handlingFees = {
        50000000: 10,
        100000000: 7,
        200000000: 5,
    };
    freight = freight.toLowerCase();
    packageName = packageName.toLowerCase();
    const maxThreshold = 200000000;
    const specialRate = 3;

	if (country === 'id'){
		return getHandlingFeeForID(isLartas, freight, packageName, type, price, maxThreshold, specialRate, handlingFees);
	}
	if (country === 'ph'){
		return getHandlingFeeForPH(type, price, maxThreshold, specialRate, handlingFees);
	}
}

export function getHandlingFeeByPrice(price, handlingFees, maxThreshold, specialRate) {
	if (maxThreshold < price) {
		return specialRate;
	}
	for (const threshold in handlingFees) {
		if (price < threshold) {
			return handlingFees[threshold];
		}
	}
	return 10;
}

function getHandlingFeeForPH(type, price, maxThreshold, specialRate, handlingFees) {
	if (type === 'shp') {
		return 5;
	}
  return 3;
	// return getHandlingFeeByPrice(price, handlingFees, maxThreshold, specialRate);
}

function getHandlingFeeForID(isLartas, freight, packageName, type, price, maxThreshold, specialRate, handlingFees) {
	if (isLartas === 1 && type === 'com' && freight === 'sea' && ['gold', 'platinum', 'expert', 'diamond'].includes(packageName)) {
		return specialRate;
	}
	return getHandlingFeeByPrice(price, handlingFees, maxThreshold, specialRate);
}

export function getShippingFee(packageName = 'gold', freight = 'sea', isLartas = false, country = 'id') {
    // Ph member
    if (country === 'ph') {
        switch (freight.toLowerCase()) {
            case 'air':
                return 210000;
            case 'sea':
                return 3200000;
        }
    }
    // Indonesia
    switch (freight.toLowerCase()) {
        case 'air':
            return 180000;
        case 'sea':
            if (isLartas == 0) {
                switch (packageName.toLowerCase()) {
                    case 'gold':
                        return 2500000;
                    case 'platinum':
                    case 'diamond':
                        return 1500000;
                    case 'expert':
                        return 0;
                }
            }
            break;
    }
    // Default shipping fee
    return 10000000;
}

export function stringToSlug(str){
  if ([undefined, null, ''].includes(str)){
    return "";
  }
  let slug = str.toLowerCase();
  slug = slug.replace(/[^a-z0-9\s-]/g, '');
  slug = slug.replace(/[\s-]+/g, '-');
  slug = slug.replace(/^-+|-+$/g, '');
  return slug;
}
