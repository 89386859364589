/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Heading, Divider, Flex, Text, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer, useToast, HStack, Tag } from '@chakra-ui/react'
import { AiOutlineArrowRight } from "react-icons/ai";
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import moment from 'moment';
import { errorSlack } from '../../Api/SlackApi';
import AuthContext from '../../Routes/hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import store
    from 'store';
import { IoMdArrowRoundBack } from 'react-icons/io';
function CbmPage() {
    const packageUser = store.get("package_user");
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [cbmBalanceData, setCbmbalanceData] = useState([])
    const [cbmBalanceLogsData, setCbmbalanceLogsData] = useState([])

    const path = window.location.href ?? '#'
    const { loadingShow, loadingClose } = useContext(AuthContext)

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const getCbmBalanceData = async () => {
        try {
            loadingShow()
            const res = await _axios.get('api/cbm-balance/get-cbm-balance')
            loadingClose()
            if (res.status === true) {
                setCbmbalanceData(res.data)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error'
            })
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const getCbmBalanceLogsData = async () => {
        try {
            const res = await _axios.get('api/cbm-balance/get-logs')
            if (res.status === true) {
                setCbmbalanceLogsData(res.data)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error'
            })
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    useEffect(() => {
        getCbmBalanceData()
        getCbmBalanceLogsData()
    }, [])

    return (
        <Stack p={[1, 1, 5]} bgColor='white' shadow={'md'} borderRadius='md'>
            <Heading size='xl' textAlign='center' px={11} mt={12} color='green.300'>
                {t('remaining_cbm_balance')}
            </Heading>
            <Divider />
            <Flex justify={'center'} alignItems={'center'} gap={10} px={5} py={6}>
                <Stack alignItems={'center'} justifyContent='center'>
                    <Heading size={'lg'}>{cbmBalanceData.balance_lartas} CBM</Heading>
                    <Text>All Product</Text>
                </Stack>
                <Stack alignItems={'center'} justifyContent='center'>
                    <Heading size={'lg'}>{cbmBalanceData.balance_non_lartas} CBM</Heading>
                    <Text>Selected Product</Text>
                </Stack>
            </Flex>
            <Stack align={'center'} mt={3}>
                <Flex gap={2}> {/* Flex container untuk tombol bersebelahan */}
                    {!["Free-Member", "FREE MEMBER"].includes(packageUser?.name) ? (
                        <Button
                            bgColor={'#ffd600'}
                            shadow={'lg'}
                            variant='solid'
                            size='sm'
                            onClick={() => navigate('/cbm/package')}
                        >
                            {t('buy_package')} CBM
                        </Button>
                    ) : null}
                    <Button
                        onClick={() => navigate(`/cbm/invoice`)}
                        bgColor={'#ffd600'}
                        color='black'
                        size={'sm'}
                        shadow='md'
                    >
                        Invoice Detail <AiOutlineArrowRight />
                    </Button>
                </Flex>
            </Stack>

            <TableContainer px={4} py={5}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Transaction Number</Th>
                            <Th>CBM</Th>
                            <Th>Type</Th>
                            <Th>Date</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'sm'}>
                        {cbmBalanceLogsData.length > 0 ?
                            cbmBalanceLogsData.map((item, index) => {
                                const trancationFix = item.transaction_type === 'com' || item.transaction_type === 'shp' ? "Shopping" : item.transaction_type === 'refund_com' ? "Refund" : item.transaction_type
                                return (
                                    <Tr>
                                        <Td>{index + 1}</Td>
                                        <Td>{item.transaction_number}</Td>
                                        <Td>
                                            <HStack>
                                                <Text>{item.cbm_amount}</Text>
                                                {item.is_lartas > 0 ?
                                                    <Tag bgColor={'red.100'}>
                                                        <Text>All Product</Text>
                                                    </Tag>
                                                    :
                                                    <Tag bgColor={'blue.100'}>
                                                        <Text>Selected Product</Text>
                                                    </Tag>
                                                }
                                            </HStack>

                                        </Td>
                                        <Td>
                                            <Stack>
                                                <Text textTransform={'capitalize'} color={trancationFix === "Shopping" ? 'red.300' : trancationFix === "add" ? 'gray.900' : 'green.300'}>{trancationFix}</Text>
                                            </Stack>
                                        </Td>
                                        <Td>{moment(item?.approved_at).format('LLL')}</Td>
                                    </Tr>
                                )
                            })
                            :
                            <Tr>
                                <Td colSpan={5} textAlign="center">
                                    <Text color={"gray.600"}>{t('data_not_found')}</Text>
                                </Td>
                            </Tr>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default CbmPage