import {
	Box,
	Button,
	HStack,
	Image,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Link,
	Spacer,
	Stack,
	Text,
	VStack,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import logobelanja from "../../assets/importirlogo.png";
import {
	MdEmail,
	MdVpnKey,
	MdVisibility,
	MdVisibilityOff,
} from "react-icons/md";
import colors from "../../Utils/colors";
import AuthContext from "../../Routes/hooks/AuthContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import store from "store";
import { useJitsu } from "@jitsu/jitsu-react";
import { triggerEventFacebookPixel } from "../../Utils/facebook";
function LoginEmail() {
	const {analytics} = useJitsu();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();
	const { login, currentUser, loadingShow, loadingClose } =
		useContext(AuthContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const emailAdmin = searchParams.get("email_admin");
	const emailUser = searchParams.get("email_user");
	const expiredSession = store.get("expired_session");
	const { state } = useLocation();
	if (!expiredSession) {
		if (currentUser?.uid) {
			let isRedirect = false;
			if (state !== undefined) {
				if (state?.from !== undefined) {
					isRedirect = true;
				}
			}
			if (isRedirect) {
				navigate(state?.from?.pathname);
			} else {
				navigate("/");
			}
		}
	}

	const height = window.innerHeight;

	useEffect(() => {
		if (!expiredSession) {
      if (currentUser?.uid) {
        let isRedirect = false;
        if (state !== undefined) {
          if (state?.from !== undefined) {
            isRedirect = true;
          }
        }
        if (isRedirect) {
          navigate(state?.from?.pathname);
        } else {
          navigate("/");
        }
      }
    }
		if (emailUser !== null) {
			setEmail(emailAdmin);
		}

		return () => {};
	}, []);

	const toast = useToast({
		position: "top",
		align: "center",
	});

	const handleLogin = async () => {
		if (email !== "" && password !== "") {
			await login(email, password);
			toast({
				title: 'importir.com',
				description: 'Success Login',
				status: 'success',
			  });
			const userData = store.get('fb_account');
			analytics.identify(userData?.user?.uid, {
				user_id: userData?.user?.uid ?? "",
				email: email,
				location: window.location.pathname,
				company_id: "dNNHm2zy4iTSfp1ejV9K",
				project_id: "wjS0AhuP4FAF1j0A6tb9"
			})
			triggerEventFacebookPixel('Login', {
				user_id: userData?.user?.uid ?? "",
				email: email,
				location: window.location.pathname,
				company_id: "dNNHm2zy4iTSfp1ejV9K",
				project_id: "wjS0AhuP4FAF1j0A6tb9"
			});
			
		} else {
			toast({
				title: "importir.com",
				description: "Check your email and password !",
				color: "warning",
			});
		}
	};

	const loggedInAsAdmin = async () => {
		if (email !== "" && password !== "") {
			loadingShow();

			const user = {
				email: email,
				email_member: emailUser,
				password: password,
			};
			try {
				const res = await axios.post(
					process.env.REACT_APP_API_COM +
						"api/general/login-as-admin",
					user
				);

				if (res.status === 200) {
					if (res.data.status === true) {
						store.set("as_admin", true);
						store.set("as_member_email", emailUser);
						login(emailUser, password);
					} else {
						toast({
							title: "importir.com",
							description: res.data.message,
							status: "error",
						});
					}
				}
				loadingClose();
			} catch (error) {
				console.log(error, "ini error");
				loadingClose();
				return;
			}
			loadingClose();
		} else {
			toast({
				title: "importir.com",
				description: "Check your email and password !",
				color: "warning",
			});
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			h={height}
			w="full"
			bgColor={colors.theme}
			style={{ minHeight: "100vh" }}
		>
			<Stack
				justifyContent="center"
				alignItems="center"
				position={"absolute"}
				spacing={5}
				pb={10}
				bgColor="blackAlpha.400"
				p={10}
				borderRadius="xl"
			>
				<Box>
					<Image
						w="200px"
						borderRadius={20}
						src={logobelanja}
						alt="Alternate Text"
					/>
				</Box>
				<Spacer />
				{emailUser !== null ? (
					<>
						<Text>You will logged in to {emailUser}</Text>
						<Stack alignItems="center">
							<InputGroup
								w={{
									base: "100%",
									md: "285",
								}}
							>
								<InputLeftAddon
									shadow={"md"}
									children={
										<MdEmail
											name="email"
											size={24}
											color="black"
										/>
									}
								/>
								<Input
									w={{
										base: "100%",
										md: "100%",
									}}
									defaultValue={email}
									placeholder="email admin"
									fontSize={"md"}
									shadow={3}
									bgColor={"white"}
									color={colors.black}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</InputGroup>
						</Stack>
						<Stack alignItems="center">
							<InputGroup
								w={{
									base: "92%",
									md: "92%",
								}}
							>
								<InputLeftAddon
									shadow={"md"}
									children={
										<MdVpnKey size={24} color="black" />
									}
								/>
								<Input
									w={{
										base: "100%",
										md: "100%",
									}}
									placeholder="password admin"
									fontSize={"md"}
									shadow={"md"}
									type={showPassword ? "text" : "password"}
									bgColor={"white"}
									color={colors.black}
									onChange={(e) =>
										setPassword(e.target.value)
									}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleLogin();
										}
									}}
								/>
								<InputRightElement>
									{showPassword ? (
										<MdVisibilityOff
											size={20}
											color="black"
											onClick={togglePasswordVisibility}
											style={{ cursor: "pointer" }}
										/>
									) : (
										<MdVisibility
											size={20}
											color="black"
											onClick={togglePasswordVisibility}
											style={{ cursor: "pointer" }}
										/>
									)}
								</InputRightElement>
							</InputGroup>
						</Stack>
						<Button
							shadow={"md"}
							size={"sm"}
							bgColor={colors.black}
							onClick={() => loggedInAsAdmin()}
						>
							<Text color={colors.theme} fontWeight="bold">
								Login As Admin
							</Text>
						</Button>
					</>
				) : (
					<>
						<Stack alignItems="center">
							<InputGroup
								w={{
									base: "100%",
									md: "285",
								}}
							>
								<InputLeftAddon
									shadow={"md"}
									children={
										<MdEmail
											name="email"
											size={24}
											color="black"
										/>
									}
								/>
								<Input
									w={{
										base: "100%",
										md: "100%",
									}}
									placeholder="email"
									fontSize={"md"}
									shadow={3}
									bgColor={"white"}
									color={colors.black}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</InputGroup>
						</Stack>
						<Stack alignItems="center">
							<InputGroup
								w={{
									base: "92%",
									md: "92%",
								}}
							>
								<InputLeftAddon
									shadow={"md"}
									children={
										<MdVpnKey size={24} color="black" />
									}
								/>
								<Input
									w={{
										base: "100%",
										md: "100%",
									}}
									placeholder="password"
									fontSize={"md"}
									shadow={"md"}
									type={showPassword ? "text" : "password"}
									bgColor={"white"}
									color={colors.black}
									onChange={(e) =>
										setPassword(e.target.value)
									}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleLogin();
										}
									}}
								/>
								<InputRightElement>
									{showPassword ? (
										<MdVisibilityOff
											size={20}
											color="black"
											onClick={togglePasswordVisibility}
											style={{ cursor: "pointer" }}
										/>
									) : (
										<MdVisibility
											size={20}
											color="black"
											onClick={togglePasswordVisibility}
											style={{ cursor: "pointer" }}
										/>
									)}
								</InputRightElement>
							</InputGroup>
						</Stack>
						<Button
							shadow={"md"}
							size={"sm"}
							bgColor={colors.black}
							onClick={() => handleLogin()}
						>
							<Text color={colors.theme} fontWeight="bold">
								CONTINUE
							</Text>
						</Button>
					</>
				)}
				<Spacer />
				<Stack>
					<Link
						textAlign={"center"}
						isExternal
						color={"black"}
						fontWeight="bold"
						cursor="pointer"
						onClick={() => navigate("/forgot-password")}
					>
						Forgot password ?
					</Link>
					<Stack space={1}>
						<Text color={"black"}>
							Are you don't have any account ?
						</Text>
						<Link
							isExternal
							color={"black"}
							fontWeight="bold"
							cursor="pointer"
							onClick={() => navigate("/register")}
						>
							Register
						</Link>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}

export default LoginEmail;
