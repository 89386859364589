/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Grid, Heading, HStack,  Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Stack, Text, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'
import AuthContext from '../../Routes/hooks/AuthContext'
import ConvertPrice from '../../Utils/ConvertPrice'
import { formatFrice } from '../../Utils/Helper'
import { useTranslation } from 'react-i18next'
function RefundListPage() {
    const {t} = useTranslation();
    const toast = useToast({
		position: "top",
		align: "center",
	});

    const { userSymbolCurrency } = useContext(AuthContext)

    const path = window.location.href ?? '#'

    const [refundList, setRefundList] = useState([])
    const [refundModal, setRefundModal] = useState(false)
    const [refundActive, setRefundActive] = useState('')

    const height = window.innerHeight

    const { convertPriceFix } = ConvertPrice()


    const getDataRefund = async () => {
        try {
            const res = await _axios.get('api/refund-transaction')
            if (res.status === true) {
                setRefundList(res.data)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const calculateAmount = (data) => {
        const totalAmount = data.reduce((total, item) => {
            return total + (parseFloat(item.amount) || 0);
        }, 0);
        return totalAmount; // Mengembalikan total amount
    };

    useEffect(() => {
        getDataRefund()

        return () => {
        }
    }, [])

    const handleRefundDetail = (x) => {
        setRefundActive(x)
        setRefundModal(true)
    }

    const handleCloseModalDetail = () => {
        setRefundActive('')
        setRefundModal(false)
    }

    const handleApprove = async () => {
        try {
            const res = await _axios.get(`api/refund-transaction/${refundActive.id}/approve`)
            if (res.status === true) {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'success'
                })
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }

        } catch (error) {
            console.log(error,)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }


    return (
        <Stack p={[1, 1, 5]} bgColor='gray.100'>
            <Stack py={3}>
            <Heading size={'md'}>{t('refund_transaction_list')}</Heading>
            </Stack>
            <Stack>
                {
                    refundList?.length > 0 ? (
                        <Stack>
                            {refundList?.map((x, index) => {
                                return (
                                    <Stack bgColor={'white'} spacing={1} key={index} borderRadius='xl' p={3} shadow={3}  >

                                        <Grid templateColumns={{ base: "1fr", md: "3fr 1fr 1fr" }} gap={5} justifyContent={'space-between'} m={3}>
                                            <Stack spacing={1} justifyContent='center' >
                                                <Text fontSize='sm' color={'gray.600'}>{moment(x?.created_at).format('LLL')}</Text>
                                                <Text fontSize={'md'} fontWeight='bold'>Refund ID : {x?.invoice_number}</Text>
                                                <Text fontSize={'md'} fontWeight='bold'>Order ID : {x?.transaction_type}{x?.transaction_id}</Text>
                                                {/* <Text fontWeight={'bold'} fontSize='lg'>{t('total')}: {userSymbolCurrency} {formatFrice(convertPriceFix(Number(x?.refund_transaction_bills[0]?.amount)))} </Text> */}
                                                <Text fontWeight={'bold'} fontSize='lg'>{t('total')}: {userSymbolCurrency} {formatFrice(convertPriceFix(Number(calculateAmount(x?.refund_transaction_bills))))} </Text>
                                            </Stack>

                                            <Spacer />


                                            <Stack justifyContent={'center'}>
                                                {x.current_status === "TRANSFERRED SUCCESS" ? (
                                                    <HStack spacing={2} alignItems='center' >
                                                        <Text color={'gray.600'} fontSize='sm'>Status:</Text>
                                                        <Text color={'gray.600'} fontSize='sm'>{t(`refund_statuses.${x.current_status}`)}</Text>
                                                        <BsCircleFill size={8} color="green" />
                                                    </HStack>
                                                ) : (
                                                    <HStack spacing={2} alignItems='center' >
                                                        <Text color={'gray.600'} fontSize='sm'>Status:</Text>
                                                        <Text color={'gray.600'} fontSize='sm'>{t(`refund_statuses.${x.current_status}`)}</Text>
                                                        <BsCircleFill size={8} color="red" />
                                                    </HStack>
                                                )}

                                                <Button shadow={'md'} borderRadius={'lg'} bgColor={'green.400'} onClick={() => handleRefundDetail(x)}>
                                                    <HStack>

                                                        <Text color={'white'} fontWeight='bold' fontSize={'md'} shadow={3}>Detail</Text>
                                                    </HStack>
                                                </Button>
                                            </Stack>
                                        </Grid>

                                    </Stack>
                                )
                            })}
                        </Stack>
                    ) : (
                        <Stack h={height / 1.2} alignItems={'center'} justifyContent='center' >
                            <Text color={'gray.500'} fontWeight='bold'>{t('invoice_unavailable')}</Text>
                        </Stack>
                    )
                }
            </Stack>


            <Modal isOpen={refundModal} onClose={() => handleCloseModalDetail()} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('refund_agreement')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack spacing={5} pb={5}>
                            <Text fontSize={'sm'} >{t('refund_agreement_title')}</Text>
                            <Stack spacing={3}>
                                <HStack>
                                    <Text fontSize={'sm'} color='gray.600'>Refund ID</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'}>{refundActive?.invoice_number}</Text>
                                </HStack>
                                <Divider />

                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>Order ID</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'}>{refundActive?.transaction_type}{refundActive?.transaction_id}</Text>
                                </HStack>
                                <Divider />


                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>{t('name')}</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'} textTransform={'capitalize'}>{refundActive?.user_inquiry?.name}</Text>
                                </HStack>
                                <Divider />


                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>{t('account_number')}</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'}>{refundActive?.user_inquiry?.account_no}</Text>
                                </HStack>
                                <Divider />

                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>{t('bank_code')}</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'} textTransform={'uppercase'}>{refundActive?.user_inquiry?.bank_code}</Text>
                                </HStack>
                                <Divider />

                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>{t('total')}</Text>
                                    <Spacer />
                                    {refundActive?.refund_transaction_bills?.length > 0 && (
                                        <Text fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(refundActive?.refund_transaction_bills[0]?.amount))}</Text>

                                    )}
                                </HStack>
                                <Divider />

                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>{t('last_status')}</Text>
                                    <Spacer />
                                    {refundActive?.current_status === "APPROVE SHIPPING" ? (
                                        <Button bgColor={'green.300'} size='sm' onClick={() => handleApprove()}>
                                            <Text fontSize={'sm'}>{t('approve')}</Text>
                                        </Button>
                                    ) : (
                                        <Text fontSize={'sm'}>{refundActive?.current_status}</Text>

                                    )}
                                </HStack>
                                <Divider />


                                <HStack>
                                    <Text fontSize={'sm'}  color='gray.600'>{t('admin_note')}</Text>
                                    <Spacer />
                                    <Text fontSize={'sm'}>{refundActive?.admin_note}</Text>
                                </HStack>
                                <Divider />
                            </Stack>
                            <Text fontSize={'sm'}>
                                {t('refund_info')}
                            </Text>
                        </Stack>
                    </ModalBody>


                </ModalContent>
            </Modal>

        </Stack >
    )
}

export default RefundListPage