/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Center, Checkbox, Divider, Flex, FormControl, FormLabel, Input, Select, SimpleGrid, Stack, Tag, TagLabel, Text, Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter, 
    useDisclosure,
    InputGroup,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    WrapItem,
    Image,
    useToast,
    Heading,
    UnorderedList,
    ListItem,
    Badge,
    Spinner} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from '../../Routes/hooks/AuthContext'
import _axios from '../../Api/AxiosBarrier'
import { LinkIcon } from "@chakra-ui/icons";
import { formatFrice, getHandlingFeePercentage, getShippingFee } from "../../Utils/Helper";
import store from 'store'
import { useNavigate, useParams } from "react-router-dom";
import ConvertPrice from "../../Utils/ConvertPrice";
import { checkQuizOnboarding } from '../../Routes/UserMiddleware'
import Swal from 'sweetalert2'
import Joyride from 'react-joyride';
import { errorSlack } from "../../Api/SlackApi";
import colors from "../../Utils/colors";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CreatePage = () => {
    const {t} = useTranslation();
    const path = window.location.href ?? '#'
    const userDataLoc = store.get('user_api');
    const firebaseUser = store.get('fb_account');
    const userPackage = store.get('user_package');
    const expirationDate = userPackage?.expired_at

    useEffect(() => { 
        if (!moment().isAfter(expirationDate)){
            checkQuizOnboarding(userDataLoc?.detail?.must_answer);
        }
    }, []);
    
    const { loadingShow, loadingClose, userSymbolCurrency, userDb } = useContext(AuthContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {convertPriceFix} = ConvertPrice()
	const toast = useToast({
		position: "top",
		align: "center",
	});
    const navigate = useNavigate()
    const params = useParams();
    const [modalType, setModalType] = useState()
    const [currentProduct, setCurrentProduct] = useState(1);
    let user = store.get('user_api')
    const [saveP, setSaveP] = useState({
        is_demo: "real",
        freight: "",
        is_lartas: user?.country_code === "id" ? 0 : 1,
        volume_est: 0,
        weight_est: 0,
        wooden_frame: false,
        wooden_frame_note: "",
        product: [
            {
                inc: 1,
                name: '',
                hs_code: null,
                hs_format: '',
                price: 0,
                quantity: '',
                is_batteray: false,
                is_msds: false,
                is_liquid: false,
                image: '',
                ppn: 0,
                pph: 0,
                bm: 0,
            }
        ],
        customer_additional_fee: 0,
        supplier_email: "",
        supplier_phone: "",
        supplier_wechat: "",
        commercial_file: "",
        packing_list_file: "",
        supplier_bank_file: "",
        shipping_method: {},
        note: "SILAHKAN TULIS NAMA DAN ALAMAT EKPEDISI ANDA JIKA INGIN DIKIRIMKAN MELALUI EKSPEDISI : ",
        use_cbm: 0,
        use_cbm_lartas: 0,
        use_cbm_non_lartas: 0,
        country: 'china'
    })
    const [agreeLabel, setAgreeLabel] = useState("")
    const [checklist, setCheckList] = useState(false)
    const [sFList, setSFList] = useState([])
    const [freightDesc, setFreightDesc] = useState("")
    const [keywordHscode, setKeywordHscode] = useState("")
    const [optHscodes, setOptHscodes] = useState([])
    const [pageHscode, setPageHscode] = useState(1)
    const [curHsProd, setCurHsProd] = useState()
    const [addressDf, setAddressDf] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [logisticLocals, setLogisticLocals] = useState([])
    const [newAddress, setNewAddress] = useState({
        label: "",
        receiver: "",
        city: "",
        phone: "",
        address: "",
        postal_code: ""
    })
    const [promoCodeOpt, setPromoCodeOpt] = useState({})
    const [balanceCBM, setBalanceCBM] = useState({
        lartas: 0,
        non: 0
    })
    const [usageBalanceCBM, setUsageBalanceCBM] = useState({
        lartas: 0,
        non: 0
    })
    const [rates, setRates] = useState({
        rmb: 0,
        thb: 0
    })
    const [freightSel, setFreightSel] = useState({
        air_price: 0,
        sea_price: 0,
        type: "AIR"
    })
    const [labelPrice, setLabelPrice] = useState({
        shipping_fee: 0,
        handling_fee: 0,
        ppn_handling_fee: 0,
        delivery_local_fee: 0,
        bm: 0,
        ppn: 0,
        pph: 0,
        other_fee: 0,
        discount_price: 0
    })
    const [activeAddress, setActiveAddress] = useState({})
    const [airPrice, setAirPrice] = useState(0)
    const [errors, setErrors] = useState({});
    const [runStep, setRunStep] = useState(false)
    const fieldRefs = {
        product: [{
            name: useRef(),
            hs_format: useRef(),
            price: useRef(),
            quantity: useRef(),
            image: useRef(),
        }],
        is_lartas: useRef(),
        freight: useRef(),
        volume_est: useRef(),
        weight_est: useRef(),
        supplier_email: useRef(),
        supplier_bank_file: useRef(),
        supplier_phone: useRef(),
        supplier_wechat: useRef(),
        commercial_file: useRef(),
        packing_list_file: useRef(),
        shipping_method: useRef()
      };      

    const [promoRes, setPromoRes] = useState(null)
    const [voucherData, setVoucherData] = useState([])
    const [voucherModal, setVoucherModal] = useState(false);
    const [productActiveVoucher, setProductActiveVoucher] = useState(null)
    const [moreInfo, setMoreInfo] = useState(false);

    const handleChangeVoucher = (e) => {
        setProductActiveVoucher(voucherData[e.target.value])
    }
    const handleShowVoucher = () => {
        // console.log(saveP)
        // if (saveP.promo_code === '' || saveP.promo_code === undefined) {
            const cbmActive = usageBalanceCBM.lartas > 0 || usageBalanceCBM.non > 0;
            

            if (cbmActive) {
                toast({
                    title: 'importir.com',
                    description: 'You have cbm balance active, cannot access Voucher',
                    status: 'warning'
                })
                loadingClose();
                return;
            }

            setProductActiveVoucher(null)
            setVoucherModal(true)

        // } else {
        //     toast({
        //         title: 'importir.com',
        //         description: 'You have promo code active, cannot access Voucher',
        //         status: 'warning'
        //     })
        // }

    }

    const handleCloseVoucherModal = () => {
        setVoucherModal(false)
        setProductActiveVoucher(null)
    }

    const handleCancelVoucher = () => {
        setProductActiveVoucher(null)
        setLabelPrice(data => ({...data, discount_price: 0}))
    }

    const handleSubmitVoucher = async (arg = null, shippingParams = null, dataRates = null) => {
        const labelPriceLocalHan = labelPrice
        setVoucherModal(false)
        let priceDisc = 0
        let voucher = null;
        if (!arg){
            voucher = productActiveVoucher;
        }else{
            voucher = arg;
        }
        priceDisc = parseInt(voucher?.price);
        if (voucher.tag !== null) {
            if (voucher.tag === 'product') {
                let totalRmb = 0;
                shippingParams?.product?.map(x => 
                    totalRmb = totalRmb + parseInt(x?.price)
                )
                totalRmb = shippingParams.country === 'china' ? totalRmb * dataRates.rmb : totalRmb * dataRates.thb
                priceDisc = totalRmb
            }
            if (voucher.tag === 'bm') {
                priceDisc = labelPrice.bm;
            }
            if (voucher.tag === 'ppn') {
                priceDisc = labelPrice.ppn;
            }
            if (voucher.tag === 'pph') {
                priceDisc = labelPrice.pph;
            }
            if (voucher.tag === 'shipping_fee') {
                priceDisc = labelPrice.shipping_fee;
            }
            if (voucher.tag === 'handling_fee') {
                priceDisc = labelPrice.handling_fee;
            }
            priceDisc = priceDisc > voucher?.price ? voucher?.price : priceDisc
        }
        priceDisc = -1 * priceDisc;
        saveP.voucher_order_detail_id = voucher.voucher_order_detail_id
        labelPriceLocalHan.discount_price = priceDisc;
        // labelPriceLocal = labelPrice;
        setLabelPrice(labelPriceLocalHan);
    }

    const getVoucherData = async () => {
        try {
            const res = await _axios.get('api/voucher/order?is_get=yes&paid_at=yes')
            let respData = []
            res.forEach((x) => {
                x.data.voucher_order_detail_id = x.voucher_order_detail_id
                x.data.is_expired = x.is_expired
                respData.push(x.data)
            })
            setVoucherData(respData)
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }
    const showSubmitButton = () => {
        if (agreeLabel === 'I AGREE' && checklist === true) {
            return true
        }

        return false
    }

    const handleChecklist = () => {
        setCheckList(!checklist)
    }

    const getShippingMethod = async (weight = 0) => {
        if (logisticLocals?.length === 0) {
            console.log("addresslist", addressDf)
            const address = addressDf.find(x => x.is_active === 1)
            console.log("address", address)
            if (address !== undefined) {
                loadingShow()
                const resLogis = await _axios.get(`api/delivery?weight=${weight}&province=${address.province}&city=${address.city}&district=${address.district}`)
                loadingClose()
                setLogisticLocals(resLogis.data)
            }
        }
    }

    const getShippingFreight = async (val = null) => {
        try {
            let country;
            if (val === null){
                country = saveP.country;
            }else{
                country = val;
            }
            const res = await _axios.get(`api/shipping/freight?from=${country}`);
            console.log(res, 'ini resss')
            if (res.status) {
                setSFList(res.data)
            } else {
                alert(res.message)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAddress = async () => {
        try {
            const res = await _axios.get(`api/address`);
            setAddressDf(res.data)
            setLogisticLocals([])
            const chooseA = res.data.find(x => x.is_active === 1)
            if (chooseA !== undefined) {
                setActiveAddress(chooseA)
            }
            return {
                active_address: chooseA
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getBalance = async () => {
        try {
            const res = await _axios.get(`api/cbm-balance/get-cbm-balance`);
            console.log("res", res)
            setBalanceCBM(data => ({...data, lartas: res.data.balance_lartas}))
            setBalanceCBM(data => ({...data, non: res.data.balance_non_lartas}))
        } catch (error) {
            console.log(error);
        }
    };

    const getShippingDetail = async (dataRates, addressData) => {
        try {
            const res = await _axios.get(`api/shipping/SHP${params.id}`);
            if (res.status) {
                await getShippingMethod()
                const dA = res.data
                if (dA.last_status !== 'customer ready') {
                    Swal.fire({
                        title: 'Warning Message',
                        text: "Status of shipping is not customer ready again, you can't update this shipping data",
                        icon: 'warning',
                        showCancelButton: false,
                    })
                    return 
                }
                const productLocal = []
                dA.products.forEach(x => {
                    productLocal.push({
                        name: x.name,
                        hs_code: x?.hscode?.hs_code,
                        hs_format: x?.hscode?.hs_format,
                        price: x?.price,
                        quantity: x.quantity,
                        is_batteray: !x.is_batteray ? false : true,
                        is_msds: !x.is_msds ? false :  true,
                        is_liquid: !x.is_liquid ? false : true,
                        image: x.image,
                        bm: x.hscode.mfn !== '' ? x.hscode.mfn : 0,
                        ppn: x.hscode.ppn !== '' ? x.hscode.ppn : 0,
                        pph: x.hscode.pph_import !== '' ? x.hscode.pph_import : 0,
                    })
                })
                // setSaveP(data => ({...data, product: productLocal}))
                let addFee = 0
                for (let i = 0; i < dA.bills.length; i++) {
                    const bill = dA.bills[i]
                    for (let k = 0; k < bill.details.length; k++) {
                        if (bill.details[k].tag === "customer_additional_fee") {
                            addFee = bill.details[k].amount / dataRates.rmb
                            break;
                        }
                    }
                    if (addFee > 0) {
                        break;
                    }
                }
                let deliveryLocalFee = 0
                for (let i = 0; i < dA.bills?.length; i++) {
                    const bill = dA.bills[i]
                    for (let k = 0; k < bill.details.length; k++) {
                        if (bill.details[k].tag === "delivery_fee") {
                            deliveryLocalFee = bill.details[k].amount
                            break;
                        }
                    }
                    if (deliveryLocalFee > 0) {
                        break;
                    }
                }
                setLabelPrice(data => ({...data, delivery_local_fee: deliveryLocalFee}))
                let cbmLartas = 0;
                let cbmNonLartas = 0;
                dA.cbm_balance_logs.forEach(x => {
                    if (x.is_lartas === 0) {
                        cbmNonLartas = cbmNonLartas + parseFloat(-1 * (x.cbm_amount))
                    } else {
                        cbmLartas = cbmLartas + parseFloat(-1 * (x.cbm_amount))
                    }
                })
                const dataEdit  = {
                    is_demo: dA.is_demo ? "demo" : "real",
                    freight: dA.freight,
                    is_lartas: dA.is_lartas ? 1 : 0,
                    country: dA.country,
                    volume_est: dA.volume_est,
                    weight_est: dA.weight_est,
                    wooden_frame: dA.is_wooden_frame,
                    wooden_frame_note: dA.wooden_frame_note,
                    product: productLocal,
                    customer_additional_fee: parseInt(addFee),
                    supplier_email: dA.supplier_email,
                    supplier_phone: dA.supplier_phone,
                    supplier_wechat: dA.supplier_wechat,
                    commercial_file: dA.commercial_file,
                    packing_list_file: dA.packing_list_file,
                    supplier_bank_file: dA.supplier_bank_file,
                    shipping_method: {
                        fee_total: deliveryLocalFee, service_code: ""
                    },
                    note: dA.note,
                    use_cbm: cbmLartas+cbmNonLartas,
                    use_cbm_lartas: cbmLartas,
                    use_cbm_non_lartas: cbmNonLartas,
                    address: addressData.active_address
                }
                setSaveP(dataEdit)
                setFreightSel({})
                setFreightSel(data => ({...data, air_price: dA.freight?.price_air}))
                setFreightSel(data => ({...data, sea_price: dA.freight?.price}))
                setFreightSel(data => ({...data, type: dA.freight?.freight_type}))
                setAirPrice(dA.freight.price_air)
                calcOtherFee(parseInt(addFee), dataRates);
                await getShippingFreight(dA.country)
                let usedVoucher = null;
                if (dA.shipping_voucher !== null){
                    setProductActiveVoucher(dA.shipping_voucher.voucher_order_detail.voucher);
                    usedVoucher = dA.shipping_voucher.voucher_order_detail.voucher;
                    handleSubmitVoucher(usedVoucher, dataEdit, dataRates);
                }
                return {
                    save_p: dataEdit,
                    freight_sel: dA.freight,
                }
            } else {
                Swal.fire({
                    title: 'Warning Message',
                    text: res.message,
                    icon: 'warning',
                    showCancelButton: false,
                })
            }
            console.log("res getshippingDetail", res)
        } catch (error) {
            console.log(error);
        }
    }

    const getFirstData = async () => {
        loadingShow();
        const addressData = await getAddress()
        await getShippingFreight()
        await getBalance()
        const dataRates = await getDataRates()    
        if (params.id !== 'create') {
            const dataRes = await getShippingDetail(dataRates, addressData)
            calcShippingFreightEdit(dataRes?.freight_sel, dataRes?.save_p?.volume_est, dataRes?.save_p?.weight_est)
            changePriceRmb(undefined, undefined, dataRes, dataRates)
        }
        loadingClose()
    }

    const getDataRates = async () => {
        try {
            const res = await _axios.get('api/rates')
            console.log('ratesres', res)
            if (res.status) {
                setRates(res.data)
                setRates(data => ({...data, rmb: parseFloat(res.data.rmb).toFixed(2)}))
                console.log('rates', res.data)
                return {
                    "rmb": parseFloat(res.data.rmb).toFixed(2)
                }
            }
        } catch (error) {
            console.log(error, 'ini error')
        }
    }

    const setSteps = () => {
        const steps = store.get('stepCreateShp')
        if (steps === undefined) {
            setRunStep(true)
        } else {
            setRunStep(false)
        }
    }

    const handleTourClose = () => {
        store.set('stepCreateShp', true)
        setRunStep(false)
    };

    useEffect(() => {
        setAirPrice(2)
        console.log("air price", airPrice)
        getFirstData()
        setSteps()
        getVoucherData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showModal = (type) => {
        const newErrors = {};

        if (type === 'submitProcess') {
            for (const key in saveP) {
                if (key === 'is_lartas' && saveP[key] === "") {
                  newErrors[key] = 'Is lartas field is required';
                } else if (!saveP[key] && key === 'freight') {
                  newErrors[key] = 'Freight field is required';
                } else if (!saveP[key] && key === 'volume_est') {
                  newErrors[key] = 'Volume field is required';
                } else if (!saveP[key] && key === 'weight_est') {
                  newErrors[key] = 'Weight field is required';
                } else if (key === 'product') {
                  const productArray = saveP[key];
                  for (let i = 0; i < productArray.length; i++) {
                    const product = productArray[i];
                    for (const productKey in product) {
                      if (!product[productKey] && productKey === 'name') {
                        newErrors[key] = newErrors[key] || [];
                        newErrors[key][i] = newErrors[key][i] || {};
                        newErrors[key][i][productKey] = 'Name field is required';
                      } else if (!product[productKey] && productKey === 'hs_format') {
                        newErrors[key] = newErrors[key] || [];
                        newErrors[key][i] = newErrors[key][i] || {};
                        newErrors[key][i][productKey] = 'Hs format field is required';
                      } else if (!product[productKey] && productKey === 'price') {
                        newErrors[key] = newErrors[key] || [];
                        newErrors[key][i] = newErrors[key][i] || {};
                        newErrors[key][i][productKey] = 'Price field is required';
                      } else if (!product[productKey] && productKey === 'quantity') {
                        newErrors[key] = newErrors[key] || [];
                        newErrors[key][i] = newErrors[key][i] || {};
                        newErrors[key][i][productKey] = 'Quantity field is required';
                      } else if (!product[productKey] && productKey === 'image') {
                        newErrors[key] = newErrors[key] || [];
                        newErrors[key][i] = newErrors[key][i] || {};
                        newErrors[key][i][productKey] = 'Image field is required';
                      }
                    }
                  }
                } else if (!saveP[key] && key === 'supplier_email') {
                    newErrors[key] = 'Supplier email field is required';
                } else if (!saveP[key] && key === 'supplier_phone') {
                    newErrors[key] = 'Supplier phone field is required';
                } else if (!saveP[key] && key === 'supplier_wechat') {
                    newErrors[key] = 'Supplier wechat field is required';
                } else if (!saveP[key] && key === 'commercial_file') {
                    newErrors[key] = 'Commercial file field is required';
                } else if (!saveP[key] && key === 'packing_list_file') {
                    newErrors[key] = 'Packing list file field is required';
                } else if (!saveP[key] && key === 'supplier_bank_file') {
                    newErrors[key] = 'Supplier bank file field is required';
                } else if (key === 'shipping_method') {
                    if (!saveP[key].service_code) { // Check if a shipping method is selected
                      newErrors[key] = 'Please select a shipping method';
                    }
                }
            }
        }

        setErrors(newErrors);

        // If there are no errors, open the modal
        if (Object.keys(newErrors)?.length === 0) {
            setModalType(type);
            onOpen();
        } else {
            if (type === 'submitProcess') {
                let scrollToField = null;
            
                const fieldCheckeds = ["is_lartas", "freight", "volume_est", "weight_est"];
                for (let i = 0; i < fieldCheckeds.length; i++) {
                    if (newErrors[fieldCheckeds[i]] !== undefined) {
                        scrollToField = fieldRefs[fieldCheckeds[i]].current
                        break;
                    }
                }
                if (!scrollToField) {
                    // Check for product errors first
                    if (newErrors.product) {
                        for (let i = 0; i < newErrors.product.length; i++) {
                            const productErrors = newErrors.product[i];
                            for (const key in productErrors) {
                                if (fieldRefs.product[i][key] && productErrors[key]) {
                                    scrollToField = fieldRefs.product[i][key].current;
                                    break; // Scroll to the first product error and stop checking
                                }
                            }
                            if (scrollToField) {
                                break;
                            }
                        }
                    }

                    if (!scrollToField) {
                        const fieldCheckedAfterProducts = ["supplier_email", "supplier_phone", "supplier_wechat", "commercial_file", 
                        "packing_list_file", "supplier_bank_file"];
                        for (let i = 0; i < fieldCheckedAfterProducts.length; i++) {
                            if (newErrors[fieldCheckedAfterProducts[i]] !== undefined) {
                                scrollToField = fieldRefs[fieldCheckedAfterProducts[i]].current
                                break;
                            }
                        }
                    }
                }
            
                // If no product errors, check main fields
                if (!scrollToField) {
                    for (const key in newErrors) {
                        if (newErrors[key] && fieldRefs[key]?.current) {
                            scrollToField = fieldRefs[key].current;
                            break; // Stop after finding the first error field
                        }
                    }
                }
            
                if (scrollToField) {
                    scrollToField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }
    }

    const handleMode = (mode) => {
        setSaveP(data => ({...data, is_demo: mode}))
        onClose()
    }

    const handleChangeFreight = (val) => {
        if (val !== "") {
            showModal("freight_desc")
            const freightSelected = sFList.find(x => x.id === parseInt(val))
            console.log("freight desc", freightSelected)
            setFreightDesc(freightSelected.description)
            setFreightSel(data => ({...data, air_price: freightSelected.price_air}))
            setFreightSel(data => ({...data, sea_price: freightSelected?.price}))
            setFreightSel(data => ({...data, type: freightSelected?.freight_type}))
            calcShippingFreight()
            setSaveP(data => ({...data, freight: {id: val}}))
        }
    }

    const handleWoodenFrame = () => {
        setSaveP(data => ({...data, wooden_frame: !saveP.wooden_frame}))
    }

    const handleAddProduct = () => {
        let currentProducts = saveP.product
        setCurrentProduct(currentProduct+1)
        currentProducts.push({
            inc: currentProduct+1,
            name: '',
            hs_code: null,
            hs_format: '',
            price: 0,
            quantity: '',
            is_batteray: false,
            is_msds: false,
            is_liquid: false,
            image: '',
            ppn: 0,
            pph: 0,
            bm: 0,
        })
        setSaveP(data => ({...data, product: currentProducts}))
    }

    const handleDeleteProduct = (x) => {
        let currentProducts = saveP.product
        currentProducts = currentProducts.filter(cp => cp.inc !== x.inc)
        setSaveP(data => ({...data, product: currentProducts}))
    }

    const handleChangeInput = (val, i, type) => {
        console.log(val, i)
        if (i !== undefined) {
            const products = saveP.product
            products[i][type] = val
            setSaveP(data => ({...data, product: products}))
        }
    }

    const handleChangeCheckbox = (i, type) => {
        const products = saveP.product
        products[i][type] = products[i][type] ? false : true
        setSaveP(data => ({...data, product: products}))
    }

    const handleSearchHscode = async (type = "") => {
        loadingShow();
        if (type === "") {
            setOptHscodes([])
            setPageHscode(1)
        }
        try {
            loadingShow()
            const res = await _axios.get(`api/hscode/search?keyword=${keywordHscode}&page=${pageHscode}`);
            console.log("res", res)
            if (pageHscode === 1) {
                setOptHscodes(res.data)
            } else {
                setOptHscodes(previousData => [...previousData, ...res.data])
            }
            loadingClose();
        } catch (error) {
            console.log(error);
            loadingClose();
        }
    };

    const handleLoadmoreHscode = () => {
        setPageHscode(pageHscode+1)
        console.log("pagehscode", pageHscode)
        handleSearchHscode("next")
    }

    const handleShowModalHscode = (i) => {
        setCurHsProd(i)
        showModal('hscode')
    }

    const handleSelectHscode = (hscode) => {
        const products = saveP.product
        products[curHsProd]['hs_code'] = hscode.hs_code
        products[curHsProd]['hs_format'] = hscode.hs_format
        products[curHsProd]['bm'] = hscode.bm !== "" ? hscode.bm : 0
        products[curHsProd]['ppn'] = hscode.ppn !== "" ? hscode.ppn : 0
        products[curHsProd]['pph'] = hscode.pph !== "" ? hscode.pph : 0
        setSaveP(data => ({...data, product: products}))
        setCurHsProd()
        onClose()
    }

    const handleUploadImage = async (i, e) => {
        const uploadFile = e.target.files[0];
        try {
            const params = new FormData();
            params.append('file', uploadFile);
            loadingShow()
            const response = await _axios.post('api/storage', params);
            console.log("das", response.data)
            const products = saveP.product
            products[i]['image'] = response.data
            setSaveP(data => ({...data, product: products}))
            loadingClose()
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeImage = (i) => {
        const products = saveP.product
        products[i]['image'] = ""
        setSaveP(data => ({...data, product: products}))
    }

    const handleChangeFile = async (type, e) => {
        const uploadFile = e.target.files[0];
        if (uploadFile?.size > 2000000) {
            Swal.fire({
                title: 'Max Upload',
                text: "the file is more than 2 MB size",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes'
            })
            return
        }
        const params = new FormData();
        params.append('file', uploadFile);
        loadingShow()
        const response = await _axios.post('api/storage', params);
        loadingClose()
        const savePprod = saveP
        savePprod[type] = response.data
        setSaveP(savePprod)
    }

    const handleSearchDistrict = (city) => {
        if (city?.length > 2) {
            return new Promise((resolve, reject) => {
                _axios.get(`api/address/search?keyword=${city}`).then((res) => {
                    setDistrictList(res.data)

                })
                    .catch(err => reject(err))
            })
        }
    }

    const handleNewAddressProcess = async () => {
        if (newAddress.city === '') {
            toast({
                title: 'importir.com',
                description: t('please_choose_address'),
                status: 'error',
            })
            return
        }
        const addressSplit = newAddress.city.split(",")

        const params = {
            province: addressSplit[2],
            city: addressSplit[1],
            district: addressSplit[0],
            label: newAddress.label,
            phone: newAddress.phone,
            address: newAddress.address,
            receiver: newAddress.receiver,
            postal_code: newAddress.postal_code,
        };
        console.log("params", params)
        try {
            loadingShow()
            const res = await _axios.post(`api/address`, params)
            loadingClose()
            if (res.status) {
                await getAddress()
                onClose()
            }
        } catch(error) {
            loadingClose()
            toast({
                title: 'importir.com',
                description: "error catch " + error,
                status: 'error',
            })
        }
    }

    const handleShowModal = () => {
        setNewAddress({
            label: "",
            receiver: "",
            city: "",
            phone: "",
            address: "",
            postal_code: ""
        })
        showModal("addaddress")
    }

    const handleChangeAddress = async (item) => {
        try {
            loadingShow()
            const res = await _axios.post(`/api/address/${item.id}/set-active`)
            loadingClose()
            if (res.status) {
                await getAddress()
            } else {
                toast({
					title: 'importir.com',
					description: res.message,
					status: 'error',
				})
            }
        } catch (error) {
            loadingClose()
            toast({
                title: 'importir.com',
                description: "error catch " + error,
                status: 'error',
            })
        }
    }

    const handleDeleteAddress = async (item) => {
        try {
            loadingShow()
            const res = await _axios.delete(`/api/address/${item.id}`)
            loadingClose()
            if (res.status) {
                toast({
                    title: 'importir.com',
                    description: "success delete",
                    status: 'success'
				})
                await getAddress()
            } else {
                toast({
					title: 'importir.com',
					description: res.message,
					status: 'error',
				})
            }
        } catch (error) {
            loadingClose()
            toast({
                title: 'importir.com',
                description: "error catch " + error,
                status: 'error',
            })
        }
    }

    const handleChangeSM = (val) => {
        const dataLog = logisticLocals[val]
        setSaveP(data => ({...data, shipping_method: {
            service_code: dataLog.service_code,
            fee_total:  dataLog.fee_total
        }}))
        setLabelPrice(data => ({...data, delivery_local_fee: dataLog.fee_total}))
    }

    const handleSearchPromo = async () => {
        loadingShow();
        try {
            loadingShow()
            const res = await _axios.post(`api/promo`, {
                promo_code: keywordHscode,
                role: 'shipping'
            });
            console.log("resPromo", res)
            setPromoCodeOpt(res)
            loadingClose();
        } catch (error) {
            console.log(error);
            loadingClose();
        }
    };

    const choosePromo = () => {
        const findPromoType = promoCodeOpt.data.permissions.find(x => x.key === 'promo_type');
        if (findPromoType !== null && findPromoType.value === "shipping_fee_fix_sea_price"){
            const amount = parseInt(saveP.is_lartas) === 1 ? 2500000 : 1000000;
            const cbmVal = saveP.volume_est < 0.2 ? 0.2 : saveP.volume_est;
            let promoTotal = cbmVal * amount;
            promoTotal = -1 * (labelPrice.shipping_fee - promoTotal);
            console.log(promoTotal, 'total promo')
            setLabelPrice(data => ({...data, discount_price: promoTotal}))
        }
        setSaveP(data => ({...data, promo_code: promoCodeOpt.data.code}))
        setKeywordHscode('')
        setPromoRes(promoCodeOpt.data.code)
        onClose()
    }

    const cancelPromo = () => {
        setPromoCodeOpt({})
        setSaveP(data => ({...data, promo_code: ""}))
    }

    const handleSubmit = async () => {
		const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);
		const currentDate = new Date();
        if (userDataLoc?.country_code === 'ph'){
            if (currentDate > expirationDate) {
                onClose()
                Swal.fire({
                    title: 'Expired Account',
                    text: "Your package has expired.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes'
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        window.open('https://old.importir.com/view-profile', '_blank')
                    }
                })
                return 
            }
        }
        try {
            loadingShow()
            let url = 'api/shipping'
            if (params.id !== 'create') {
                url = 'api/shipping/SHP'+ params.id
            }
            const formDataSHP = saveP;
            formDataSHP.firebase_uid = firebaseUser.user.uid;
            const response = await _axios.post(url, formDataSHP);
            loadingClose()
            if (response.status) {
                toast({
                    title: 'importir.com',
                    description: params.id === 'create' ? 'Success create shipping' : 'Success update shipping',
                    status: 'success'
                })
                navigate('/shipping/detail/' + response.data.shipping_number)
            } else {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'error'
                })
                
            }
        } catch (error) {
            console.log(error)
        }
    }

    const usUsageBal = () => {
        let totalUsage = parseFloat(usageBalanceCBM.lartas) + parseFloat(usageBalanceCBM.non)
        const volumeEst = parseFloat(saveP.volume_est) < 0.2 ? 0.2 : parseFloat(saveP.volume_est)
        if (totalUsage > volumeEst) {
            alert('Please Input your CBM Balance is same or lower than your estimate bill (' + volumeEst + ')')
            return
        }
        // kalau orderan lartas, maka balance yang dipake hanya apb
        // kalau orderan non lartas, maka balancenya bisa pake apb dan spb
        if (saveP.is_lartas) {
            // lartas
            if (usageBalanceCBM.lartas > balanceCBM.lartas) {
                alert('Your APB balance is not enough')
                return
            }
        } else {
            if ((parseFloat(usageBalanceCBM.lartas) + parseFloat(usageBalanceCBM.non)) > (parseFloat(balanceCBM.lartas) + parseFloat(balanceCBM.non))) {
                alert('Your APB and SPB balance is not enough')
                return
            }
        }

        saveP.use_cbm_lartas = parseFloat(usageBalanceCBM.lartas)
        saveP.use_cbm_non_lartas = parseFloat(usageBalanceCBM.non)
        saveP.use_cbm = parseFloat(usageBalanceCBM.non) + parseFloat(usageBalanceCBM.lartas)

        setUsageBalanceCBM({
            lartas: 0,
            non: 0
        })
        onClose()
    }

    const cancelUsageBalance = () => {
        setSaveP(data => ({...data, use_cbm: 0}))
        setSaveP(data => ({...data, use_cbm_lartas: 0}))
        setSaveP(data => ({...data, use_cbm_non_lartas: 0}))
    }

    const calcShippingFreightEdit = (freightSel, volEst, weightEst) => {
        if (freightSel?.freight_type === "AIR") {
            const cbmCubication = parseFloat(volEst) * 168
            const realWeight = cbmCubication > weightEst ? cbmCubication : parseFloat(weightEst)
            setLabelPrice(data => ({...data, shipping_fee: realWeight * freightSel.price_air}))
        } else {
            const packageUser = store.get('package_user');
            const seaFee = getShippingFee(packageUser?.package?.name, freightSel?.freight_type, saveP.is_lartas, userDataLoc?.country_code);
            setLabelPrice(data => ({...data, shipping_fee: parseFloat(volEst) * seaFee}))
        }
    }

    const calcShippingFreight = (volEst = saveP.volume_est, weightEst = saveP.weight_est) => {
        if (freightSel.type === "AIR") {
            const cbmCubication = parseFloat(volEst) * 168
            const realWeight = cbmCubication > weightEst ? cbmCubication : parseFloat(weightEst)
            setLabelPrice(data => ({...data, shipping_fee: realWeight * freightSel.air_price}))
        } else {
            const packageUser = store.get('package_user');
            const seaFee = getShippingFee(packageUser?.package?.name, freightSel?.freight_type, saveP.is_lartas, userDataLoc?.country_code);
            setLabelPrice(data => ({...data, shipping_fee: parseFloat(volEst) * seaFee}))
        }
    }

    const changeVolAndWeight = (type, val) => {
        setSaveP(data => ({...data, [type]: val}))
        if (type === 'volume_est') {
            calcShippingFreight(val, saveP.weight_est)
        } else {
            calcShippingFreight(saveP.volume_est, val)
        }
    }

    const totalProductPrice = () => {
        let totalRmb = 0
        saveP?.product?.map(x => 
            totalRmb = totalRmb + parseInt(x?.price)
        )
        totalRmb = saveP.country === 'china' ? totalRmb * rates.rmb : totalRmb * rates.thb
        return totalRmb
    }

    const handlingFeeCalculate = async (product, isLartas) => {
        let packageUser = store.get('package_user')
        return getHandlingFeePercentage(product, packageUser?.package?.name, freightSel?.type, isLartas, 'shp', userDataLoc?.country_code);;
    }

    const changePriceRmb = async (val, i, dataRes, dataRates) => {
        if (val !== undefined) {
            handleChangeInput(val, i, 'price')
        }
        let productPriceTotal = totalProductPrice()
        if (dataRes !== undefined) {
            let totalRmb = 0
            const productLo = dataRes?.save_p?.product
            productLo.forEach(x => { 
                totalRmb = totalRmb + parseFloat(x?.price)
            })
            totalRmb = totalRmb * dataRates.rmb
            productPriceTotal = totalRmb

        }
        let hFPercent = await handlingFeeCalculate(productPriceTotal, dataRes?.save_p?.is_lartas)
        const handlingFee = productPriceTotal * hFPercent / 100
        setLabelPrice(data => ({...data, handling_fee: handlingFee}))
        setLabelPrice(data => ({...data, ppn_handling_fee: 11 * handlingFee / 100}))
        if (dataRes === undefined) {
            calcTax()
        } else {
            calcTax(dataRes?.save_p?.product, dataRates)
        }
    }

    const calcTax = (products, dataRates) => {
        let bmFee = 0;
        let ppnFee = 0;
        let pphFee = 0;
        let priceIdr = 0;
        if (products === undefined) {
            saveP.product.forEach((x, i) => {
                priceIdr =  saveP.country === 'china' ? x?.price * rates.rmb :  x?.price * rates.thb
                const bmLocal = x.bm * priceIdr / 100
                const ppnLocal = x.ppn * (priceIdr + bmLocal) / 100
                const pphLocal = x.pph * (priceIdr + bmLocal) / 100
                bmFee = bmFee + bmLocal
                ppnFee = ppnFee + ppnLocal
                pphFee = pphFee + pphLocal
            })
        } else {
            products.forEach((x, i) => {
                priceIdr = saveP.country === 'china' ? x?.price * dataRates.rmb :  x?.price * dataRates.thb
                const bmLocal = x.bm * priceIdr / 100
                const ppnLocal = x.ppn * (priceIdr + bmLocal) / 100
                const pphLocal = x.pph * (priceIdr + bmLocal) / 100
                bmFee = bmFee + bmLocal
                ppnFee = ppnFee + ppnLocal
                pphFee = pphFee + pphLocal
            })
        }
        setLabelPrice(data => ({...data, bm: bmFee}))
        setLabelPrice(data => ({...data, ppn: ppnFee}))
        setLabelPrice(data => ({...data, pph: pphFee}))
    }

    const calcOtherFee = (val, dataRates) => {
        setSaveP(data => ({...data, customer_additional_fee: val}))
        if (dataRates === undefined) {
            setLabelPrice(data => ({...data, other_fee: saveP.country === 'china' ? val * rates.rmb : val * rates.thb}))
        } else {
            setLabelPrice(data => ({...data, other_fee: saveP.country === 'china' ? val * dataRates.rmb : dataRates.thb}))
        }
    }

    const totalBill = () => {
        const totalProduct = totalProductPrice()
        return totalProduct + labelPrice.shipping_fee + labelPrice.handling_fee + labelPrice.ppn_handling_fee + labelPrice.bm + labelPrice.ppn +
        labelPrice.pph + labelPrice.other_fee + labelPrice.delivery_local_fee
    }

    const CustomTooltip = ({
        index,
        step,
        skipProps,
        primaryProps,
        tooltipProps,
        isLastStep,
        backProps
    }) => (
        <div {...tooltipProps} style={{ textAlign: 'center', background: "white", padding: "20px" }}>
            <div>{step.title}</div>
            <div>{step.content}</div>
            <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                <button {...skipProps} onClick={() => handleTourClose()}>Close</button>
                <div>
                    {index > 0 && <button {...backProps} style={{ marginLeft: '10px', background: 'green', color: 'white', borderRadius: '5px', padding: '5px' }}>Back</button>}
                </div>

                {!isLastStep && (
                    <div style={{ marginLeft: '10px', background: 'red', color: 'white', borderRadius: '5px', padding: '5px' }}>
                        <button {...primaryProps} >{isLastStep ? '' : 'Next'}</button>
                    </div>

                )}
            </div>
        </div>
    );

    const steps = [
        {
            target: '.country',
            title: `${t('country')}`,
            disableBeacon: true,
            content: `${t('tour_shipping_form_country')}`,
        },
        {
            target: '.freight',
            title: `${t('freight')}`,
            content: `${t('tour_shipping_form_freight')}`,
            placement: 'right',
        },
        {
            target: '.product-cost',
            title: `${t('product_cost_info')}`,
            content: `${t('tour_shipping_form_product_cost')}`,
        },
        {
            target: '.additional-fee',
            title: `${t('additional_fee')}`,
            content: `${t('tour_shipping_form_additional_fee')}`,
        },
        {
            target: '.invoice',
            title: `${t('invoice_packing_list')}`,
            content: `${t('tour_shipping_form_invoice')}`,
        },
        {
            target: '.address',
            title: `${t('customer_address')}`,
            content: `${t('tour_shipping_form_address')}`,
        },
        {
            target: '.others',
            title: `${t('other')}`,
            content: `${t('tour_shipping_form_others')}`,
        },
        {
            target: '.promo',
            title: `${t('promo')}`,
            content: `${t('tour_shipping_form_promo')}`,
        },
        {
            target: '.cbm-balance',
            title: `${t('cbm_balance')}`,
            content: `${t('tour_shipping_form_cbm_balance')}`,
        },
        {
            target: '.summary',
            title: `${t('summary')}`,
            content: `${t('tour_shipping_form_summary')}`,
        },
    ]

    const showShippingFee = () => {
        return (
            <Flex align={'center'} justify={'space-between'} mt={2}>
                <Text>{t('shipping_fee_to', {country: "Indonesia"})}</Text> 
                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.shipping_fee))}</Text>    
            </Flex>
        )
    }

    const demoLable = () => {
        return saveP?.is_demo === "demo" ? 
        (
            <Badge
                variant="solid"
                colorScheme="red"
                position="absolute"
                top="0rem" // Adjust the top position to align the badge with the corner
                left="-0.8rem"
                transform="rotate(-45deg)" // Rotate the badge by 45 degrees
                transformOrigin="top right" // Set the rotation origin
            >
                DEMO
            </Badge>
        )
        :
         (<></>)
    }

    const handleImportFrom = async (e) => {
        loadingShow();
        setSaveP(prevData  => ({...prevData , country: e.target.value}));
        await getShippingFreight(e.target.value);
        loadingClose();
    }

    return (
        <Stack>
            <div>
                <Joyride
                    steps={steps}
                    run={runStep}
                    continuous
                    tooltipComponent={CustomTooltip}
                    disableScrolling={true}
                    hideBackButton
                    disableOverlayClose
                    disableScrollParentFix
                    scrollToSteps
                />
            </div>
            <SimpleGrid columns={[1, 1, 2]} spacing={5} pb={5}>
                <Box>
                <Stack className="country">
                        <Flex align={'center'} justify={'space-between'} mt={5}>
                            <Text fontSize="xl" fontWeight="bold" >
                                {t('import_from')}
                            </Text> 
                        </Flex>
                        <Box bg={"white"}  shadow={"2xl"}
                            borderRadius="md"
                            p={4}
                            textAlign="center"
                            position="relative"
                            >
                            <Select value={saveP.country} onChange={(e) => handleImportFrom(e)}>
                                <option value='china'>China</option>
                                {/* <option value='thailand'>Thailand</option> */}
                            </Select>
                        </Box>
                    </Stack>
                    <Stack className="freight">
                        <Flex align={'center'} justify={'space-between'} mt={5}>
                            <Text fontSize="xl" fontWeight="bold" >
                                {t('freight')}
                            </Text>
                            <Button colorScheme={saveP.is_demo !== "demo" ? "blue" : "green"} onClick={() => showModal("isdemo")}>
                                {`${t('change').toUpperCase()} DEMO/REAL`}
                            </Button>
                        </Flex>
                        <Box bg={"white"}  shadow={"2xl"}
                            borderRadius="md"
                            p={4}
                            textAlign="center"
                            position="relative" // Needed for positioning the badge
                            >
                            {demoLable()}
                            {
                                user?.country_code === "id" ? 
                                    <FormControl isInvalid={!!errors.is_lartas} ref={fieldRefs.is_lartas}>
                                        <FormLabel>{t('type')} *)</FormLabel>
                                        <Select value={saveP.is_lartas === 1 ? 'lartas' : (saveP.is_lartas === 0 ? "non" : "")}  placeholder='-- Choose Lartas/Non Lartas --' onChange={(e) => setSaveP(data => ({...data, is_lartas: e.target.value === 'lartas'? 1 : 0}))}>
                                            <option value="lartas">Lartas</option>
                                            <option value="non">Non Lartas</option>
                                        </Select>
                                        {errors.is_lartas && <Text color="red">{errors.is_lartas}</Text>}
                                    </FormControl>
                                : ""
                            }
                            <FormControl mt={5} isInvalid={!!errors.freight} ref={fieldRefs.freight}>
                                <FormLabel>{t('freight')} *)</FormLabel>
                                <Select value={saveP?.freight?.id} placeholder='-- Select Freight --' onChange={(e) => handleChangeFreight(e.target.value)}>
                                    {
                                        sFList.map((x, i) => 
                                            <option key={i} value={x.id}>{x.name}</option>
                                        )
                                    }
                                </Select>
                                {errors.freight && <Text color="red">{errors.freight}</Text>}
                            </FormControl>
                            <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5}>
                                <FormControl isInvalid={!!errors.volume_est} ref={fieldRefs.volume_est}>
                                    <FormLabel>{t('estimated_cbm')} (CBM, ex: 0,2) *)</FormLabel>
                                    <Input type="number" step={0.0001} value={saveP.volume_est} onChange={(e) => changeVolAndWeight("volume_est", e.target.value)} />
                                    {errors.volume_est && <Text color="red">{errors.volume_est}</Text>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.weight_est} ref={fieldRefs.weight_est}>
                                    <FormLabel>{t('estimated_weight')} (Kg) *)</FormLabel>
                                    <Input type="number" step={0.01} value={saveP.weight_est} onChange={(e) => changeVolAndWeight("weight_est", e.target.value)} />
                                    {errors.weight_est && <Text color="red">{errors.weight_est}</Text>}
                                </FormControl>
                            </SimpleGrid>
                            <FormControl mt={5}>
                                <FormLabel>{t('wooden_packing')}</FormLabel>
                                <Checkbox onChange={handleWoodenFrame} isChecked={saveP.wooden_frame}></Checkbox>{t('wooden_packing_agreement')} <br></br>
                                {
                                    saveP.wooden_frame ?
                                        <FormControl>
                                            <FormLabel>{t('note_to_admin')} *)</FormLabel>
                                            <Input type="text" defaultValue={saveP.wooden_frame_note} onChange={(e) => setSaveP(data => ({...data, wooden_frame_note: e.target.value}))} />
                                        </FormControl>
                                    :""
                                }
                                <Tag
                                    mt={2}
                                    size={'md'}
                                    key={'md'}
                                    borderRadius='full'
                                    variant='solid'
                                    colorScheme='red'
                                    >
                                    <TagLabel>{t('wooden_packing_policy')}</TagLabel>
                                </Tag>
                            </FormControl>
                        </Box>
                    </Stack>
                    <Stack color={'white'} my={10} >
                        <Divider borderColor={'blackAlpha.400'} />
                    </Stack>
                    <Stack className="product-cost">
                        <Text fontSize="xl" fontWeight="bold">
                            {t('product')} & {t('cost_info')} 
                        </Text>
                        {
                            saveP.product.map((x, i) => 
                                <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3} key={i}
                                position="relative">
                                    {demoLable()}
                                    <Flex align={'center'} justify={'space-between'} mt={5}>
                                        <Text textAlign={"center"}>{t('product')} {x.inc}</Text>
                                        {
                                            saveP.product?.length > 1 ?<Button colorScheme="red" onClick={() => handleDeleteProduct(x)}>Delete</Button> : ""
                                        }
                                    </Flex>
                                    <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5}>
                                        <FormControl isInvalid={!!(errors?.product && errors?.product[i] && errors?.product[i]?.name)} ref={fieldRefs?.product[i]?.name}>
                                            <FormLabel>{t('product_name')} (English) *)</FormLabel>
                                            <Input type="text" value={x.name} onChange={(e) => handleChangeInput(e.target.value, i, 'name')} />
                                            {errors.product && errors.product[i] && errors.product[i].name && (
                                                <Text color="red">{errors.product[i].name}</Text>
                                            )}
                                        </FormControl>
                                        <FormControl isInvalid={!!(errors.product && errors.product[i] && errors.product[i]?.hs_format)} ref={fieldRefs.product[i]?.hs_format}>
                                            <FormLabel>Hs Code *)</FormLabel>
                                            <Input defaultValue={x.hs_format} onClick={() => handleShowModalHscode(i)} type="text" />
                                            {errors.hs_format && <Text color="red">{errors.hs_format}</Text>}
                                            {errors.product && errors.product[i] && errors.product[i].hs_format && (
                                                <Text color="red">{errors.product[i].hs_format}</Text>
                                            )}
                                        </FormControl>
                                    </SimpleGrid>
                                    <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5}>
                                        <FormControl isInvalid={!!(errors.product && errors.product[i] && errors.product[i]?.price)} ref={fieldRefs.product[i]?.price}>
                                            <FormLabel>{saveP.country === 'china' ?  `${t('product_price_total_w_rate', {currency: "RMB"})} *)`  :  `${t('product_price_total_w_rate', {currency: "THB"})} *)`}</FormLabel>
                                            <Input type="number" value={x?.price} onChange={(e) => changePriceRmb(e.target.value, i)} />
                                            {errors.product && errors.product[i] && errors.product[i]?.price && (
                                                <Text color="red">{errors.product[i]?.price}</Text>
                                            )}
                                        </FormControl>
                                        <FormControl isInvalid={!!(errors.product && errors.product[i] && errors.product[i]?.quantity)} ref={fieldRefs.product[i]?.quantity}>
                                            <FormLabel>{t('quantity')} *)</FormLabel>
                                            <Input type="number" value={x.quantity} onChange={(e) => handleChangeInput(e.target.value, i, 'quantity')}  />
                                            {errors.product && errors.product[i] && errors.product[i].quantity && (
                                                <Text color="red">{errors.product[i].quantity}</Text>
                                            )}
                                        </FormControl>
                                    </SimpleGrid>
                                    <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5}>
                                        <FormControl>
                                            <FormLabel>{t('does_item_contain')}</FormLabel>
                                            <Stack>
                                                <Checkbox size='md' onChange={()=>handleChangeCheckbox(i, "is_batteray")} colorScheme='green' isChecked={x.is_batteray}>
                                                    {t('battery')}
                                                </Checkbox>
                                                <Checkbox size='md' isChecked={x.is_liquid}  onChange={()=>handleChangeCheckbox(i, "is_liquid")} colorScheme='green'>
                                                    {t('liquid')}
                                                </Checkbox>
                                                <Checkbox size='md' isChecked={x.is_msds}  onChange={()=>handleChangeCheckbox(i, "is_msds")} colorScheme='green'>
                                                    {t('msds')}
                                                </Checkbox>
                                            </Stack>
                                        </FormControl>
                                        <FormControl isInvalid={!!(errors.product && errors.product[i] && errors.product[i]?.image)} ref={fieldRefs.product[i]?.image}>
                                            <FormLabel>{t('product_image')} *)</FormLabel>
                                            {
                                                (x?.image !== '' && x?.image !== undefined) ?
                                                    <>
                                                        <Image src={x?.image} boxSize='150px' objectFit='cover' mt={5} />
                                                        <Button bgColor={"red.300"} onClick={() => handleChangeImage(i)}>Change Image</Button>
                                                    </>
                                                : <Input type="file" onChange={(e) => handleUploadImage(i, e)} />
                                            }
                                            {errors.product && errors.product[i] && errors.product[i].image && (
                                                <Text color="red">{errors.product[i].image}</Text>
                                            )}
                                        </FormControl>
                                    </SimpleGrid>
                                </Box>
                            )
                        }
                        <Stack>
                            <Button colorScheme="blue" onClick={() => handleAddProduct()}>{`${t('add')} ${t('product')}`}</Button>
                        </Stack>
                    </Stack>
                    <Stack color={'white'} my={10} >
                        <Divider borderColor={'blackAlpha.400'} />
                    </Stack>
                    <Stack className="additional-fee">
                        <Text fontSize="xl" fontWeight="bold">
                           {t('additional_fee')}
                        </Text>
                        <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}
                                position="relative">
                            {demoLable()}
                            <FormControl>
                                <FormLabel>{saveP.country === "china" ? 'Additional Fee  (RMB)' : 'Additional Fee  (THB)' }</FormLabel>
                                <Input type="number" value={saveP.customer_additional_fee} onChange={(e) => calcOtherFee(e.target.value)} />
                            </FormControl>
                        </Box>
                    </Stack>
                    <Stack color={'white'} my={10} >
                        <Divider borderColor={'blackAlpha.400'} />
                    </Stack>
                    <Stack className="invoice">
                        <Text fontSize="xl" fontWeight="bold">
                            {t('invoice')} & {t('packing_list')}
                        </Text>
                        <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}
                                position="relative">
                            {demoLable()}
                            <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5}>
                                <FormControl isInvalid={!!errors.supplier_email} ref={fieldRefs.supplier_email}>
                                    <FormLabel>{t('supplier_email')} *)</FormLabel>
                                    <Input type="text" value={saveP.supplier_email} onChange={(e) => setSaveP(data => ({...data, supplier_email: e.target.value}))}  />
                                    {errors.supplier_email && <Text color="red">{errors.supplier_email}</Text>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.supplier_phone} ref={fieldRefs.supplier_phone}>
                                    <FormLabel>{t('supplier_phone')} *)</FormLabel>
                                    <Input type="text" value={saveP.supplier_phone} onChange={(e) => setSaveP(data => ({...data, supplier_phone: e.target.value}))}  />
                                    {errors.supplier_phone && <Text color="red">{errors.supplier_phone}</Text>}
                                </FormControl>
                            </SimpleGrid>
                            <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5} ref={fieldRefs.supplier_wechat}>
                                <FormControl isInvalid={!!errors.supplier_wechat}>
                                    <FormLabel>{t('supplier_wechat')} *)</FormLabel>
                                    <Input type="text" value={saveP.supplier_wechat} onChange={(e) => setSaveP(data => ({...data, supplier_wechat: e.target.value}))}  />
                                    {errors.supplier_wechat && <Text color="red">{errors.supplier_wechat}</Text>}
                                </FormControl>
                                <FormControl isInvalid={!!errors.commercial_file} ref={fieldRefs.commercial_file}>
                                    <FormLabel>{t('commercial_invoice_file')} *)</FormLabel>
                                    {
                                        saveP.commercial_file !== '' ?
                                            <>
                                                {saveP.commercial_file.split('.').reverse()[0] === 'pdf' ?
                                                    <a href={saveP.commercial_file }><LinkIcon /></a>
                                                :
                                                    <Image src={saveP.commercial_file} boxSize='150px' objectFit='cover' mt={5} />}
                                                <Button bgColor={"red.300"} onClick={() => setSaveP(data => ({...data, commercial_file: ''}))}>Change</Button>
                                            </>
                                        : <Input type="file" onChange={(e) => handleChangeFile("commercial_file", e)} />
                                    }
                                    {errors.commercial_file && <Text color="red">{errors.commercial_file}</Text>}
                                    <Text color={"red.400"} fontStyle={'italic'}>{t('max_upload')}</Text>
                                </FormControl>
                            </SimpleGrid>
                            <SimpleGrid columns={{base: 1, lg: 2}} spacing={2} mt={5}>
                                <FormControl isInvalid={!!errors.packing_list_file} ref={fieldRefs.packing_list_file}>
                                    <FormLabel>{t('packing_list_file')} *)</FormLabel>
                                    {
                                        saveP.packing_list_file !== '' ?
                                            <>
                                                {saveP.packing_list_file.split('.').reverse()[0] === 'pdf' ?
                                                    <a href={saveP.packing_list_file }><LinkIcon /></a>
                                                :
                                                    <Image src={saveP.packing_list_file} boxSize='150px' objectFit='cover' mt={5} />}
                                                <Button bgColor={"red.300"} onClick={() => setSaveP(data => ({...data, packing_list_file: ''}))}>Change</Button>
                                            </>
                                        : <Input type="file" onChange={(e) => handleChangeFile("packing_list_file", e)} />
                                    }
                                    {errors.packing_list_file && <Text color="red">{errors.packing_list_file}</Text>}
                                    <Text color={"red.400"} fontStyle={'italic'}>{t('max_upload')}</Text>
                                </FormControl>
                                <FormControl isInvalid={!!errors.supplier_bank_file} ref={fieldRefs.supplier_bank_file}>
                                    <FormLabel>{t('supplier_bank_info')} *)</FormLabel>
                                    {
                                        saveP.supplier_bank_file !== '' ?
                                            <>
                                                {saveP.supplier_bank_file.split('.').reverse()[0] === 'pdf' ?
                                                    <a href={saveP.supplier_bank_file }><LinkIcon /></a>
                                                :
                                                    <Image src={saveP.supplier_bank_file} boxSize='150px' objectFit='cover' mt={5} />}
                                                <Button bgColor={"red.300"} onClick={() => setSaveP(data => ({...data, supplier_bank_file: ''}))}>Change</Button>
                                            </>
                                        : <Input type="file" onChange={(e) => handleChangeFile("supplier_bank_file", e)} />
                                    }
                                    {errors.supplier_bank_file && <Text color="red">{errors.supplier_bank_file}</Text>}
                                    <Text color={"red.400"} fontStyle={'italic'}>{t('upload_supplier_info')}, {t('max_upload')}</Text>
                                </FormControl>
                            </SimpleGrid>
                        </Box>
                    </Stack>
                    <Stack color={'white'} my={10} >
                        <Divider borderColor={'blackAlpha.400'} />
                    </Stack>
                    <Stack className="address">
                        <Text fontSize="xl" fontWeight="bold">
                            {t('customer_address')} 
                        </Text>
                        <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}
                                position="relative">
                            {demoLable()}
                            {/* {showActiveAddress()} */}
                            <FormControl>
                                <FormLabel>{activeAddress.receiver} ({activeAddress.phone}) </FormLabel>
                                <FormLabel>{activeAddress.address}, {activeAddress.district}, {activeAddress.city}, {activeAddress.province} </FormLabel>
                            </FormControl>
                        </Box>
                        <Stack>
                            <SimpleGrid columns={2} spacing={2}>
                                <Button bgColor={"blue.400"} onClick={() => handleShowModal()}>{t('add')} {t('address')}</Button>
                                <Button bgColor={"green.400"} onClick={() => showModal("listaddress")}>{t('change')} {t('address')}</Button>
                            </SimpleGrid>
                        </Stack>
                    </Stack>
                    <Stack color={'white'} my={10} >
                        <Divider borderColor={'blackAlpha.400'} />
                    </Stack>
                    <Stack className="others">
                        <Text fontSize="xl" fontWeight="bold">
                            {t('other')}
                        </Text>
                        <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}>
                            {demoLable()}
                            {
                                <FormControl isInvalid={!!errors.shipping_method} ref={fieldRefs.shipping_method}>
                                    <FormLabel>{t('shipping_method')} *)</FormLabel>
                                    <Select placeholder={t('select_placeholder')} onClick={() => getShippingMethod()} onChange={(e) => handleChangeSM(e.target.value)}>
                                        {
                                            logisticLocals?.length > 0 ?
                                                logisticLocals.map((x, i) => 
                                                    <option key={i} value={i}>{x.service_code} - Rp. {formatFrice(x.fee_total)}</option>    
                                                )
                                            : ""
                                        }
                                    </Select>
                                    {errors.shipping_method && <Text color="red">{errors.shipping_method}</Text>}
                                </FormControl>
                            }
                            <FormControl mt={5}>
                                <FormLabel>{t('note_to_admin')}</FormLabel>
                                <Textarea value={saveP.note}
                                 onChange={(e) => setSaveP(data => ({...data, note: e.target.value}))} />
                            </FormControl>
                        </Box>
                    </Stack>
                    {
                        saveP.use_cbm > 0 ?
                            ""
                        :
                            <>
                                <Stack color={'white'} my={10} >
                                    <Divider borderColor={'blackAlpha.400'} />
                                </Stack>
                                <Stack className="promo">
                                    <Text fontSize="xl" fontWeight="bold">
                                        {t('promo')}
                                    </Text>
                                    <Tag
                                        size={'md'}
                                        key={'md'}
                                        borderRadius='full'
                                        variant='solid'
                                        colorScheme='red'
                                        >
                                        <TagLabel>{t('promo_code_info')}</TagLabel>
                                    </Tag>
                                    <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}>
                                        {demoLable()}
                                        {
                                            promoCodeOpt?.status !== undefined && promoCodeOpt?.status ?
                                                <Flex align={'center'} justify={'space-between'}>
                                                    <div dangerouslySetInnerHTML={{ __html: promoCodeOpt?.data?.description }} />
                                                    <Button colorScheme="green" onClick={() => cancelPromo()}>{t('cancel_promo')}</Button>
                                                </Flex>
                                            :
                                                <Center>
                                                    <Button colorScheme="green" onClick={() => showModal("promo")}>{t('apply_promo')}</Button>
                                                </Center>
                                        }
                                    </Box>
                                </Stack>
                            </>
                    }
                    {
                        saveP.promo_code ? 
                            ""
                        : 
                            <>
                                <Stack color={'white'} my={10} >
                                    <Divider borderColor={'blackAlpha.400'} />
                                </Stack>
                                <Stack className="cbm-balance">
                                    <Text fontSize="xl" fontWeight="bold">
                                        {t('use_cbm_balance')}
                                    </Text>
                                    <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}
                                        position="relative">
                                        {demoLable()}
                                        {
                                            saveP.use_cbm > 0 ?
                                                <>
                                                    <Flex align={'center'} justify={'space-between'}>
                                                        <>
                                                            <Text>{t('use_cbm_balance_from', {balance: saveP.use_cbm_lartas, type: "APB"})}</Text>
                                                            {
                                                                    !saveP.is_lartas ? 
                                                                    <Text>{t('use_cbm_balance_from', {balance: saveP.use_cbm_non_lartas, type: "SPB"})}</Text>
                                                                : 
                                                                    ""
                                                            }
                                                        </>
                                                        <Button onClick={() => cancelUsageBalance()} bgColor={'red.400'}>{t('cancel_cbm_balance')}</Button>
                                                    </Flex>
                                                    
                                                </>
                                            :
                                                <Center>
                                                    <Button colorScheme="green" onClick={() => showModal("usagebalance")}>{t('apply_cbm_balance')}</Button>
                                                </Center>
                                        }
                                        
                                    </Box>
                                </Stack>
                            </>
                    }
                    {
                        (userDataLoc?.country_code === 'ph') || (!saveP.promo_code && saveP.use_cbm > 0) ? 
                            ""
                        : 
                            <>
                                <Stack color={'white'} my={10} >
                                    <Divider borderColor={'blackAlpha.400'} />
                                </Stack>
                                <Stack className="cbm-balance">
                                    <Text fontSize="xl" fontWeight="bold">
                                        {t('use_voucher')}
                                    </Text>
                                    <Box bg={"white"} borderRadius={"xl"} shadow={"2xl"} p={3}
                                        position="relative">
                                        {demoLable()}
                                        <Flex justify={'center'}>
                                            {productActiveVoucher === null ? 
                                                <Button colorScheme="green" onClick={() => handleShowVoucher()}>
                                                    {t('use_voucher')}
                                                </Button>
                                            : 
                                            
                                                <Button colorScheme="red" onClick={() => handleCancelVoucher()}>
                                                    {t('cancel_voucher')} ({productActiveVoucher.title})
                                                </Button>
                                            }
                                        </Flex>
                                    </Box>
                                </Stack>
                            </>
                    }
                    <Center>
                        <Button bgColor={"blue.500"} mt={5} onClick={() => showModal("submitProcess")} color={'white'}>{t('save')}</Button>
                    </Center>
                </Box>
                <Box className="summary">
                    <Stack mt={16} mr={5} maxH={'500px'} position={'sticky'} top={'32'} width={"100%"}>
                        <Box bg={"white"} overflow={"auto"} borderRadius={'xl'} shadow={"2xl"} p={3}>
                            {demoLable()}
                            <Text fontWeight={"bold"}>{t('summary')}</Text>
                            <Flex align={'center'} justify={'space-between'} mt={5}>
                                <Text>
                                    {t('rate')}
                                </Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(saveP.country === 'china' ? rates.rmb : rates.thb))}</Text>
                            </Flex>
                            <Stack color={'white'} my={5} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Text fontWeight={"bold"}>{t('product_list')}</Text>
                            {
                                saveP.product.map((x, i) => 
                                    <Flex align={'center'} key={i} justify={'space-between'} mt={2}>
                                        <Text>
                                            {x.name} x {x.quantity}
                                        </Text>
                                        <Text>{saveP.country === 'china' ? `¥${x?.price}` : `฿${x?.price}` }</Text>
                                        <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(saveP.country === 'china' ? x?.price * rates.rmb : x?.price * rates.thb))}</Text>
                                    </Flex>
                                )
                            }
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={5}>
                                <Text>
                                    {t('total')}
                                </Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(totalProductPrice()))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Text fontWeight={"bold"} mt={5}>{t('first_bill_estimation')} </Text>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('total_product_price')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(totalProductPrice()))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            {showShippingFee()}
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('delivery_fee_local')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.delivery_local_fee))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('handling_fee')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.handling_fee))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('ppn_handling_fee')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(labelPrice.ppn_handling_fee)}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('bm')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.bm))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('ppn')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.ppn))}</Text>
                            </Flex>
                            {
                                user?.country_code !== 'ph' ? 
                                <Stack>
                                    <Stack color={'white'} my={2} >
                                        <Divider borderColor={'blackAlpha.400'} />
                                    </Stack>
                                    <Flex align={'center'} justify={'space-between'} mt={2}>
                                        <Text>{t('pph')}</Text>
                                        <Text>Rp. {formatFrice(labelPrice.pph)}</Text>
                                    </Flex>
                                </Stack>
                                :
                                <Text></Text>
                            }
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('additional_fee')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.other_fee))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text>{t('discount')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(labelPrice.discount_price))}</Text>
                            </Flex>
                            <Stack color={'white'} my={2} >
                                <Divider borderColor={'blackAlpha.400'} />
                            </Stack>
                            <Flex align={'center'} justify={'space-between'} mt={2}>
                                <Text fontWeight={'bold'} size={'xl'}>{t('total')}</Text>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(totalBill() + labelPrice.discount_price))}</Text>
                            </Flex>
                        </Box>
                    </Stack>
                </Box>
            </SimpleGrid>
            <Modal isOpen={isOpen} size={"2xl"} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>
                    {
                        modalType === 'isdemo' 
                        ? 
                            t('choose_mode')
                        :
                            (
                                modalType === "freight_desc"
                                ?
                                    t('freight_desc')
                                :
                                    (
                                        modalType === "hscode"
                                        ?
                                            t('search') + " Hs code"
                                        :
                                            (
                                                modalType === "addaddress"
                                                ?
                                                    t('add_new_address')
                                                :
                                                    (
                                                        modalType === "listaddress"
                                                        ?
                                                            t('add_new_address')
                                                        :
                                                            (
                                                                modalType === "promo"
                                                                ?
                                                                    t('promo_code')
                                                                : 
                                                                    (
                                                                        modalType === "usagebalance"
                                                                        ?
                                                                            t('cbm_balance_form')
                                                                        :
                                                                            (
                                                                                modalType === "submitProcess"
                                                                                ?
                                                                                    t('confirmation_message')
                                                                                :
                                                                                    ""
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )
                                    )
                            )
                    }
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        modalType === 'isdemo' 
                        ? 
                            <>
                                <SimpleGrid columns={2} spacing={2}>
                                    <Button bgColor={"green.500"} onClick={() => handleMode("demo")}>DEMO</Button>
                                    <Button bgColor={"blue.400"} onClick={() => handleMode("real")}>REAL</Button>
                                </SimpleGrid>
                                <Stack textAlign={'center'} mt={5} color={'red.500'}>
                                    <Text>
                                        {t('demo_mode_info')}
                                    </Text>
                                </Stack>
                            </>
                        :
                            (
                                modalType === "freight_desc"
                                ?
                                    <Box p={5} bgColor={'blue.100'} borderRadius={"xl"}>
                                        <Stack p={2}>
                                            <div dangerouslySetInnerHTML={{ __html: freightDesc }} />
                                        </Stack>
                                    </Box>
                                :
                                    (
                                        modalType === "hscode"
                                        ?
                                            <>     
                                                <InputGroup>
                                                    <Input type='text' onChange={(e) => setKeywordHscode(e.target.value)} />
                                                    <Button onClick={() => handleSearchHscode()}>{t('search')}</Button>
                                                </InputGroup>
                                                <TableContainer mt={5}>
                                                    <Table variant='striped' colorScheme='teal'>
                                                        <Tbody>
                                                            {
                                                                optHscodes.map((x, i) =>
                                                                    <Tr key={i}>
                                                                    <Td>
                                                                        {
                                                                            x.hs_format?.length === 8 ? 
                                                                            <Button onClick={() => handleSelectHscode(x)}>{t('use')}</Button>
                                                                            : ""
                                                                        }
                                                                    </Td>
                                                                        <Td>{x.hs_format}</Td>
                                                                        <Td overflow={false}>
                                                                            <WrapItem>{x.desc_text}</WrapItem></Td>
                                                                    </Tr>
                                                                )
                                                            }
                                                            {
                                                                optHscodes?.length > 0 ?
                                                                    <Tr>
                                                                        <Td colSpan={3}>
                                                                            <Button bgColor={'blue.500'} color={'white'} onClick={() => handleLoadmoreHscode()}>Loadmore</Button>
                                                                        </Td>
                                                                    </Tr>
                                                                : ""
                                                            }
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        :
                                            (
                                                modalType === "addaddress"
                                                ?
                                                    <>     
                                                        <FormControl>
                                                            <FormLabel>{t('district')} / {t('city')} ({t('minimum_input_character', {length: 3})})</FormLabel>
                                                            <Input type='text' onChange={(e) => handleSearchDistrict(e.target.value)} />
                                                        </FormControl>
                                                        
                                                        <FormControl mt={5}>
                                                            <FormLabel>{t('select_address_by_district_or_city')}</FormLabel>
                                                            <Select mt={3} onChange={(e) => setNewAddress(data => ({...data, city: e.target.value}))}>
                                                                {
                                                                    districtList?.length === 0 ? 
                                                                    <option>
                                                                        <Spinner
                                                                            thickness='4px'
                                                                            speed='0.65s'
                                                                            emptyColor='gray.200'
                                                                            color='blue.500'
                                                                            size='xl'
                                                                        />
                                                                    </option>
                                                                    : 
                                                                    <>
                                                                        <option>{t('choose_district_or_city_placeholder')}</option>
                                                                        {
                                                                            districtList.map((x,i) => 
                                                                                <option key={i} value={x}>{x}</option>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        
                                                        <SimpleGrid columns={{base: 1, md: 2}} spacing={2} mt={5}>
                                                            <FormControl>
                                                                <FormLabel>{t('address_label')}</FormLabel>
                                                                <Input type='text' onChange={(e) => setNewAddress(data => ({...data, label: e.target.value}))} />
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>{t('receiver_name')}</FormLabel>
                                                                <Input type='text' onChange={(e) => setNewAddress(data => ({...data, receiver: e.target.value}))} />
                                                            </FormControl>
                                                        </SimpleGrid>
                                                        <SimpleGrid columns={{base: 1, md: 2}} spacing={2} mt={5}>
                                                            <FormControl>
                                                                <FormLabel>{t('receiver_phone')}</FormLabel>
                                                                <Input type='text' onChange={(e) => setNewAddress(data => ({...data, phone: e.target.value}))} />
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>{t('postal_code')}</FormLabel>
                                                                <Input type='text' onChange={(e) => setNewAddress(data => ({...data, postal_code: e.target.value}))} />
                                                            </FormControl>
                                                        </SimpleGrid>
                                                        <FormControl mt={5}>
                                                            <FormLabel>{t('address')}</FormLabel>
                                                            <Textarea onChange={(e) => setNewAddress(data => ({...data, address: e.target.value}))}></Textarea>
                                                        </FormControl>
                                                        <Button mt={5} bgColor={'blue'} color={'white'} onClick={() => handleNewAddressProcess()}>{t('submit')}</Button>
                                                    </>
                                                :
                                                    (
                                                        modalType === "listaddress"
                                                        ?
                                                            <TableContainer mt={5}>
                                                                <Table variant='striped' colorScheme='teal'>
                                                                    <Tbody>
                                                                        {
                                                                            addressDf.map((x, i) =>
                                                                                <Tr key={i}>
                                                                                <Td>
                                                                                    {
                                                                                        x?.is_active !== 1 ? 
                                                                                            <>
                                                                                                <Button onClick={() => handleChangeAddress(x)} bgColor={"blue.400"}>{t('choose_address')}</Button>
                                                                                                <Button onClick={() => handleDeleteAddress(x)} bgColor={"red.400"}>{t('delete')}</Button>
                                                                                            </>
                                                                                        : ""
                                                                                    }
                                                                                </Td>
                                                                                    <Td>
                                                                                        <FormControl>
                                                                                            <FormLabel>{x.label}</FormLabel>
                                                                                            <FormLabel>{x.address}, {x.district}, {x.city}, {x.province}</FormLabel>
                                                                                        </FormControl>
                                                                                    </Td>
                                                                                </Tr>
                                                                            )
                                                                        }
                                                                        {
                                                                            optHscodes.length > 0 ?
                                                                                <Tr>
                                                                                    <Td colSpan={3}>
                                                                                        <Button bgColor={'blue.500'} color={'white'} onClick={() => handleLoadmoreHscode()}>{t('load_more')}</Button>
                                                                                    </Td>
                                                                                </Tr>
                                                                            : ""
                                                                        }
                                                                    </Tbody>
                                                                </Table>
                                                            </TableContainer>
                                                        :
                                                            (
                                                                modalType === "promo"
                                                                ?
                                                                    <>
                                                                        <InputGroup>
                                                                            <Input type='text' onChange={(e) => setKeywordHscode(e.target.value)} />
                                                                            <Button onClick={() => handleSearchPromo()}>{t('search')}</Button>
                                                                        </InputGroup>
                                                                        {
                                                                            promoCodeOpt?.status ? 
                                                                                <>
                                                                                    <Text size={'xl'} mt={5}>{promoCodeOpt?.data.code}</Text>
                                                                                    <Flex align={'center'} justify={'space-between'}>
                                                                                        <div dangerouslySetInnerHTML={{ __html: promoCodeOpt?.data.description }} />
                                                                                        <Button colorScheme="blue" onClick={() => choosePromo()}>{t('use')}</Button>
                                                                                    </Flex>
                                                                                </>
                                                                            :
                                                                                promoCodeOpt?.message
                                                                        }
                                                                    </>
                                                                :
                                                                    (
                                                                        modalType === "usagebalance"
                                                                        ?
                                                                            <>
                                                                                <SimpleGrid columns={2} spacing={2}>
                                                                                    <Text>All Product Balance (APB): {balanceCBM.lartas}CBM</Text>
                                                                                    {
                                                                                        !saveP.is_lartas ?
                                                                                            <Text>Selected Product Balance (SPB): {balanceCBM.non}CBM</Text>
                                                                                        : <Text></Text>
                                                                                    }
                                                                                    <FormControl>
                                                                                        <FormLabel>APB</FormLabel>
                                                                                        <Input type='number' defaultValue={usageBalanceCBM.lartas} step={0.001} max={balanceCBM.lartas} onChange={(e) => setUsageBalanceCBM(data => ({...data, lartas: e.target.value}))} />
                                                                                    </FormControl>
                                                                                    {
                                                                                        !saveP.is_lartas ? 
                                                                                            <FormControl>
                                                                                                <FormLabel>SPB</FormLabel>
                                                                                                <Input type='number' defaultValue={usageBalanceCBM.non} step={0.001} max={balanceCBM.non} onChange={(e) => setUsageBalanceCBM(data => ({...data, non: e.target.value}))} />
                                                                                            </FormControl>
                                                                                        : ""
                                                                                    }
                                                                                </SimpleGrid>
                                                                                <Button onClick={() => usUsageBal()} mt={3} bgColor={"blue.200"}>{t('apply_cbm_balance')}</Button>
                                                                            </>
                                                                        :
                                                                            (
                                                                                modalType === 'submitProcess' 
                                                                                ?
                                                                                    <>
                                                                                        <Box>
                                                                                            <Box textAlign="center" mb="4">
                                                                                                <Heading fontSize="xl" fontWeight="bold">
                                                                                                    {t('payment_confirmation')}
                                                                                                </Heading>
                                                                                                <br />
                                                                                                <Text dangerouslySetInnerHTML={{ __html: t('we_always_transparent', 
                                                                                                { transparent: '<strong style="color: rgb(48, 133, 214); font-weight: bold;">' + t('transparent') + '</strong>' }) }} />

                                                                                            </Box>
                                                                                            <Box textAlign="center" mb="4">
                                                                                                {/* <Text>
                                                                                                    {t('get_additional_fee_info')}
                                                                                                </Text> */}
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <UnorderedList ml="4" listStyleType="disc">
                                                                                                <ListItem>
                                                                                                    {t('estimated_info')}
                                                                                                </ListItem>
                                                                                                <ListItem>
                                                                                                    {t('tax_diff_info')}
                                                                                                </ListItem>
                                                                                                <ListItem>
                                                                                                    {t('additional_shipping_fee_info')}
                                                                                                </ListItem>
                                                                                                <ListItem>
                                                                                                    {t('additional_request_fragile_info')}
                                                                                                </ListItem>

                                                                                                <ListItem>
                                                                                                    <Text dangerouslySetInnerHTML={{ __html: t('random_product_info', 
                                                                                                { random: '<strong style="font-weight: bold;">' + t('random').toUpperCase() + '</strong>' }) }} />
                                                                                                
                                                                                                </ListItem>

                                                                                                <ListItem>
                                                                                                    {t('additinal_free_ongkir')}
                                                                                                </ListItem>
                                                                                                <ListItem>
                                                                                                    {t('item_may_opened_info')}
                                                                                                </ListItem>
                                                                                                <ListItem>
                                                                                                    {t('complaint_deadline')}
                                                                                                </ListItem>
                                                                                             
                                                                                                </UnorderedList>
                                                                                            </Box>
                                                                                            <Box mt="4">
                                                                                                <form>
                                                                                                <Box fontWeight="bold">
                                                                                                    {t('please_type')} :{' '}
                                                                                                    <strong
                                                                                                    style={{
                                                                                                        backgroundColor: 'rgb(254, 215, 0)',
                                                                                                        paddingRight: '7px',
                                                                                                        paddingLeft: '7px',
                                                                                                        borderRadius: '3px',
                                                                                                    }}
                                                                                                    >
                                                                                                    I AGREE
                                                                                                    </strong>
                                                                                                </Box>
                                                                                                <Input
                                                                                                    id="agree"
                                                                                                    type="text"
                                                                                                    name="confirm"
                                                                                                    placeholder="I AGREE"
                                                                                                    autoComplete="off"
                                                                                                    mb="3"
                                                                                                    className="block p-2 w-full text-gray-900 bg-gray-50 border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 rounded-full"
                                                                                                    onChange={(e) => setAgreeLabel(e.target.value)}
                                                                                                />
                                                                                                <Text
                                                                                                    id="invalid2"
                                                                                                    hidden
                                                                                                    color="rgb(178, 34, 34)"
                                                                                                    fontSize="sm"
                                                                                                    pl="3%"
                                                                                                >
                                                                                                    * {t('typing_dont_match')}
                                                                                                </Text>
                                                                                                <Box>
                                                                                                    <Text onClick={() => setMoreInfo(true)} style={{cursor: "pointer"}} fontSize={'xs'} color='blue.400'>
                                                                                                        {t('click_for_detail')}
                                                                                                    </Text>
                                                                                                </Box>
                                                                                                <Checkbox id="i-agree-checkbox" onChange={handleChecklist}>
                                                                                                    {t('i_agree_with_info')}
                                                                                                </Checkbox>
                                                                                                </form>
                                                                                            </Box>
                                                                                            <Box mt="4">
                                                                                                <Box textAlign="center">
                                                                                                    <Button
                                                                                                        mt="3"
                                                                                                        mb="3"
                                                                                                        w="1/5"
                                                                                                        borderWidth="1px"
                                                                                                        borderColor="transparent"
                                                                                                        boxShadow="sm"
                                                                                                        fontSize="xs"
                                                                                                        fontWeight="medium"
                                                                                                        rounded="md"
                                                                                                        colorScheme="red"
                                                                                                        _hover={{ bg: 'red.700' }}
                                                                                                        onClick={onClose}
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </Button>
                                                                                                    {
                                                                                                        showSubmitButton() ? 
                                                                                                        <Button
                                                                                                            title="Please check, I agree with the detail information"
                                                                                                            mt="3"
                                                                                                            ml={3}
                                                                                                            mb="3"
                                                                                                            w="1/5"
                                                                                                            borderWidth="1px"
                                                                                                            borderColor="transparent"
                                                                                                            boxShadow="sm"
                                                                                                            fontSize="xs"
                                                                                                            fontWeight="medium"
                                                                                                            rounded="md"
                                                                                                            colorScheme="blue"
                                                                                                            _hover={{ bg: 'blue.400' }}
                                                                                                            onClick={() => handleSubmit()}
                                                                                                        >
                                                                                                            {t('save')}
                                                                                                        </Button> : 

                                                                                                        <Button
                                                                                                            disabled
                                                                                                            title="Please check, I agree with the detail information"
                                                                                                            mt="3"
                                                                                                            ml={3}
                                                                                                            mb="3"
                                                                                                            w="1/5"
                                                                                                            borderWidth="1px"
                                                                                                            borderColor="transparent"
                                                                                                            boxShadow="sm"
                                                                                                            fontSize="xs"
                                                                                                            fontWeight="medium"
                                                                                                            rounded="md"
                                                                                                            colorScheme="blue"
                                                                                                            cursor="not-allowed"
                                                                                                            _hover={{ bg: 'blue.400' }}
                                                                                                        >{t('save')}</Button>
                                                                                                    }
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </>
                                                                                : ""
                                                                            )
                                                                    )
                                                            )
                                                    )
                                            )
                                    )
                            )
                    }
                </ModalBody>
                </ModalContent>
            </Modal>
            <Modal isOpen={voucherModal} onClose={() => handleCloseVoucherModal()} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        Voucher
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Select fontSize='sm' size={'sm'} bgColor={'white'} placeholder={t('choose_voucher')} onChange={(e) => handleChangeVoucher(e)}>
                            {voucherData.map((x, index) => (
                                <option value={index} key={`${index}-p`} disabled={x.is_expired ? true : false}>
                                    {x.title} ({t('invoice_number')}: {x.invoice_no}) { x.is_expired ? ' / Expired' : '' }
                                </option>
                            ))}
                        </Select>
                        {productActiveVoucher !== null && (
                            <>
                                <Box>
                                    <Text color={"red"} my={2} fontSize={"sm"}>
                                        * {productActiveVoucher?.description}
                                    </Text>
                                </Box>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {promoRes === null ? (
                            <Button bgColor={colors.theme} onClick={() => handleSubmitVoucher()}>
                                <Text>{t('submit')}</Text>
                            </Button>
                        ) : (
                            <Button bgColor={colors.theme} disabled onClick={() => handleSubmitVoucher()}>
                                <Text color={colors.white}>{t('submit')}</Text>
                            </Button>
                        )}
                    </ModalFooter>

                </ModalContent>
            </Modal>
            <Modal isOpen={moreInfo} onClose={() => setMoreInfo(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        {t('information_detail')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack m={3} spacing={2}>
                            <Text fontSize='sm' >
                                {t('in_warehouse_indo_policy')}
                            </Text>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button bgColor={colors.theme} onClick={() => setMoreInfo(false)}>
                            <Text color={colors.black}>OK</Text>
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </Stack>
    )
}

export default CreatePage;