/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    HStack, Spacer, Stack, Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Tag,
    TagLabel,
    InputGroup,
    InputLeftAddon,
    Input,
    Select,
    Button,
    Box,
    Container,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    SimpleGrid
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice'
import Joyride from 'react-joyride';
import { checkQuizOnboarding } from '../../Routes/UserMiddleware'
import store from 'store'
import { FaEye, FaFax, FaPen, FaPlus, FaSearch } from 'react-icons/fa'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

function InvoiceShippingListPage() {
    const { t } = useTranslation();
    const userDataLoc = store.get('user_api');

    const userPackage = store.get('user_package');
    const expirationDate = userPackage?.expired_at

    useEffect(() => {
        if (!moment().isAfter(expirationDate)) {
            checkQuizOnboarding(userDataLoc?.detail?.must_answer);
        }
    }, [userPackage]);

    const [shippingList, setShippingList] = useState([])
    const [searchParam, setSearchParam] = useState({
        shippingNumber: "",
        type: "",
        import_from: ""
    })
    const [runStep, setRunStep] = useState(false)
    let currentPage = 1;

    const { loadingShow, loadingClose, userSymbolCurrency } = useContext(AuthContext)
    const navigate = useNavigate()
    const { convertPriceFix } = ConvertPrice()

    const setSteps = () => {
        const steps = store.get('stepsShipping')
        if (steps === undefined) {
            setRunStep(true)
        } else {
            setRunStep(false)
        }
    }

    const handleTourClose = () => {
        store.set('stepsShipping', true)
        setRunStep(false)
    };

    const { isOpen, onOpen, onClose } = useDisclosure()
    const handleModalClose = () => {
        store.set('modal_warehouse_ph', true)
        onClose();
    }
    useEffect(() => {
        if (userDataLoc?.country_code === 'ph') {
            if (store.get('modal_warehouse_ph') === undefined) {
                onOpen();
            }
        }
        getDataOrderShipping();
        setSteps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataOrderShipping = async () => {
        loadingShow();
        try {
            const res = await _axios.get(`api/shipping?page=${currentPage}&shipping_number=${searchParam.shippingNumber}&type=${searchParam.type}&import_from=${searchParam.import_from}`);
            if (currentPage === 1) {
                setShippingList(res.data)
            } else {
                setShippingList(previousData => [...res.data, ...previousData])
            }
            loadingClose();
        } catch (error) {
            console.log(error);
            loadingClose();
        }
    };

    const handleSearch = async () => {
        currentPage = 1
        await getDataOrderShipping(1)
    }

    // const handleLoadmore = async () => {
    //     currentPage = currentPage + 1
    //     await getDataOrderShipping()
    // }

    const CustomTooltip = ({
        index,
        step,
        skipProps,
        primaryProps,
        tooltipProps,
        isLastStep,
        backProps
    }) => (
        <div {...tooltipProps} style={{ textAlign: 'center', background: "white", padding: "20px" }}>
            <div>{step.title}</div>
            <div>{step.content}</div>
            <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                <button {...skipProps} onClick={() => handleTourClose()}>Close</button>
                <div>
                    {index > 0 && <button {...backProps} style={{ marginLeft: '10px', background: 'green', color: 'white', borderRadius: '5px', padding: '5px' }}>Back</button>}
                </div>

                {!isLastStep && (
                    <div style={{ marginLeft: '10px', background: 'red', color: 'white', borderRadius: '5px', padding: '5px' }}>
                        <button {...primaryProps} >{isLastStep ? '' : 'Next'}</button>
                    </div>

                )}
            </div>
        </div>
    );

    const steps = [
        {
            target: '.create',
            title: `${t('create_shipping')}`,
            content: `${t('tour_shipping_index_create_shipping')}`,
            disableBeacon: true,
            placement: 'right',
        },
        {
            target: '.simulator',
            title: `${t('simulator')}`,
            content: `${t('tour_shipping_index_simulator')}`,
        },
        {
            target: '.old-order',
            title: `${t('old_shipping')}`,
            content: `${t('tour_shipping_index_old_order')}`,
        },
        {
            target: '.detail',
            title: `${t('shipping_detail')}`,
            content: `${t('tour_shipping_index_detail')}`,
        },
    ]

    return (
        <Stack border={"1px gray"} borderRadius={"xl"} bgColor={"white"} shadow={"2xl"} mt={5} p={[1, 1, 5]}>
            <div>
                <Joyride
                    steps={steps}
                    run={runStep}
                    continuous
                    tooltipComponent={CustomTooltip}
                    disableScrolling={true}
                    hideBackButton
                    disableOverlayClose
                    disableScrollParentFix
                />
            </div>
            <Modal blockScrollOnMount={false} size={"xl"} isOpen={isOpen} onClose={handleModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Notification of Updated China Operational Warehouse Address in Guangzhou</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb='1rem'>
                            <p>Greetings, member of Importir group,&nbsp;</p>

                            <p>Hope this message finds you well.&nbsp;</p>

                            <p>We want to inform all shipments to the Philippines.&nbsp;Please&nbsp;inform your supplier to process the delivery of goods to our Guangzhou warehouse address at the following address:&nbsp;</p>

                            <p>广州市白云区白云湖街唐阁上村中街28号103B 云诚达货运代理有限公司(原龙树实业有限公司&nbsp;510000&nbsp;</p>

                            <p>Guangzhou Baiyun District, Baiyunhu Avenue, Tangge Shangcun Middle Road No.28 Unit 103B&nbsp;Postal Code 510000&nbsp;</p>

                            <p>联系人&nbsp;/&nbsp;Contact Person: -&nbsp;威力&nbsp;/ Weili (Phone no. : 18142827674)</p>

                            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- Mr. Deandy &nbsp;(WeChat&nbsp;id : IMPORTIRORG_admin)</p>

                            <p>收货时间&nbsp;/&nbsp;Working Hour: 周一到五 (Mon &ndash; Fri)&nbsp;9.00 - 17.00&nbsp; /&nbsp;&nbsp;&nbsp;周六 (Sat)&nbsp;9.00 &ndash; 14.00 China Time</p>

                            <p>&nbsp;</p>

                            <p>* 周天，及中国法定假日正常休假</p>

                            <p>* 每一个箱子要用编织袋打包</p>

                            <p>* 每一个箱子上必须贴客户的唛头&nbsp;（唛头后面写上货物总数量）</p>

                            <p>&nbsp;&nbsp;和海运代码（这些跟客户要）</p>

                            <p>* 如果唛头不正确本公司无法收货&nbsp;</p>

                            <p>* 货物送到仓库必须带上装箱单和订单(超过一个箱子只需要在一个箱子上贴)</p>

                            <p>&nbsp;</p>

                            <p>We are hopeful that you&nbsp;can tell the supplier to process the delivery of goods to that address by not forgetting to include the Marking Code, and Shipping Code, and the goods can be packed properly to keep the goods safe during the delivery process to your places.</p>

                            <p>Thank you for your kind attention.</p>

                            <p>Stay safe and have a nice day!</p>

                            <p>&nbsp;</p>

                            <p><strong>Warm Regards,</strong></p>

                            <p>Shipping Division&nbsp;</p>
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={handleModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <HStack>
                <HStack cursor='pointer' zIndex='50' w='100px' mx={5} mb={2} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                    <IoCaretBackOutline size={15} />
                    <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
                </HStack>
                <Spacer />
            </HStack>
            <Container maxW={'container.2xl'}>
                <Stack bgColor={'#d9edf7'} borderColor={'#bcdff1'} className='old-order' spacing={1} borderRadius='md' p={2} shadow={3} display={'flex'} justifyContent={'center'} width={'full'}>
                    <Box textAlign={'center'} fontSize={{ xs: 'xs', sm: 'sm', xl: 'xl' }} fontStyle={'italic'}>
                        <Text dangerouslySetInnerHTML={{ __html: t('old_page_redirect', { link: '<a href="/old-shp" style="color: blue; font-weight: bold;">' + t('click') + '</a>' }) }} />
                    </Box>
                </Stack>
            </Container>
            <Box textAlign={"right"} mb={3}>
              
                <Button className='simulator' color={"white"} bgColor={"green.400"} ml={2} onClick={() => navigate('/shipping/simulator')}>
                    <HStack spacing={2}>
                    <Icon as={FaFax} w={4} h={4} color='black.500' />   
                    <Text>{t('simulator')}</Text>
                    </HStack>
                </Button>
            </Box>
            <SimpleGrid columns={[1, 2, 3, 4]} gap={2}>
                <InputGroup>
                    <InputLeftAddon children={t('shipping_number')} />
                    <Input type='text' onChange={(e) => setSearchParam(data => ({ ...data, shippingNumber: e.target.value }))} />
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children={t('type')} />
                    <Select onChange={(e) => setSearchParam(data => ({ ...data, type: e.target.value }))}>
                        <option value=''>{t('all')}</option>
                        <option value='demo'>{t('demo')}</option>
                        <option value='real'>{t('real')}</option>
                    </Select>
                </InputGroup>
                <InputGroup>
                    <InputLeftAddon children={t('import_from')} />
                    <Select onChange={(e) => setSearchParam(data => ({ ...data, import_from: e.target.value }))}>
                        <option value=''>{t('all')}</option>
                        <option value='china'>China</option>
                        <option value='thailand'>Thailand</option>
                    </Select>
                </InputGroup>
                <Button bgColor={colors.theme} onClick={() => handleSearch()}><Icon as={FaSearch} w={4} h={4} color='black.500' /></Button>
            </SimpleGrid>
            <HStack mt={5}>
                <TableContainer width={'100%'}>
                    <Table variant='striped' colorScheme='teal'>
                        <Thead>
                            <Tr>
                                <Th >{t('shipping_number')}</Th>
                                <Th >{t('type')}</Th>
                                <Th>{t('total')}</Th>
                                <Th >{t('last_status')}</Th>
                                <Th >{t('import_from')}</Th>
                                <Th >{t('created_at')}</Th>
                                <Th >{t('actions')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shippingList?.map((x, i) =>
                                <Tr key={i}>
                                    <Td>
                                        <a href='javascript:;' onClick={() => navigate('/invoice-shipping/detail/' + x.shipping_number)}>
                                            <Text color={'blue.500'}>{x.shipping_number}</Text>
                                        </a>
                                    </Td>
                                    <Td>
                                        {
                                            x.is_demo ?
                                                <Tag
                                                    size={'md'}
                                                    key={'md'}
                                                    borderRadius='full'
                                                    variant='solid'
                                                    colorScheme='green'
                                                >
                                                    <TagLabel>DEMO</TagLabel>
                                                </Tag>
                                                :
                                                <Tag
                                                    size={'md'}
                                                    key={'md'}
                                                    borderRadius='full'
                                                    variant='solid'
                                                    colorScheme='red'
                                                >
                                                    <TagLabel>REAL</TagLabel>
                                                </Tag>
                                        }
                                    </Td>
                                    <Td >{userSymbolCurrency} {formatFrice(convertPriceFix(x?.bill?.total))}</Td>
                                    <Td>{x.last_status}</Td>
                                    <Td>{x.import_from === 'china' ? '🇨🇳' : '🇹🇭'}</Td>
                                    <Td>{x.created_at}</Td>
                                    <Td>
                                        <Button bgColor={'blue.200'} textColor={'white'} mr={2} onClick={() => navigate('/invoice-shipping/detail/' + x.shipping_number)} className='detail'>
                                            <Icon as={FaEye} w={4} h={4} color='black.500' />
                                        </Button>
                                        {
                                            x.last_status === 'customer ready' ?
                                                <>
                                                    <Button bgColor={'orange.200'} onClick={() => navigate('/shipping/form/' + x.id)}>
                                                        <Icon as={FaPen} w={4} h={4} color='black.500' />
                                                    </Button>
                                                </>
                                                : ""
                                        }
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </HStack>
            {/* <Button bgColor={"blue.400"} onClick={() => handleLoadmore()}>Loadmore</Button> */}
        </Stack >
    )
}

export default InvoiceShippingListPage