import {
    Stack, Heading, Button, Image, Grid, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, Text, useToast, Box, ListItem, List, Card, CardBody, CardFooter, ButtonGroup, HStack, SimpleGrid,
    Flex,
    Divider,
    Badge
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../Routes/hooks/AuthContext'
import _axios from '../../../Api/AxiosBarrier'
import { useNavigate, useParams } from 'react-router-dom'
import { formatFrice, formatPrice } from '../../../Utils/Helper'
import { IoCaretBackOutline } from 'react-icons/io5'
import colors from '../../../Utils/colors'
import ConvertPrice from '../../../Utils/ConvertPrice'
import CountdownBill from '../../../Components/CountDown/CountdownBill'
import store from 'store'
import moment from 'moment'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
function DetailBillShipping() {
    const { t } = useTranslation();
    const userDataLoc = store.get('user_api');
    const { convertPriceFix } = ConvertPrice()
    const { loadingShow, loadingClose, userSymbolCurrency, userCurrency } = useContext(AuthContext)
    const params = useParams()
    const [billDetail, setBillDetail] = useState({})
    const [xenditLinkPayment, setxenditLinkPayment] = useState(null)
    const [midtransVA, setMidtransVA] = useState(null)
    const [midtransGopay, setMidtransGopay] = useState(null)
    const [showManual, setShowManual] = useState(false)
    const [checkUseVoucher, setCheckUseVoucher] = useState(false);
    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const path = window.location.href ?? '#';
    const navigate = useNavigate()
    const [rate, setRate] = useState(1);
    const startDate = moment().format('YYYY-MM-DD HH:mm:ss');
    const bankLogos = {
        PERMATA: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425815-bank-indonesia-permata-permatabank-syariah-icon-2024_07_18_15_47_44.png"
        },
        MANDIRI: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425804-bank-indonesia-mandiri-icon-2024_07_18_16_14_23.png"
        },
        BRI: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425806-bank-bri-indonesia-indonesian-rakyat-icon-2024_07_18_16_14_55.png"
        },
        BNI: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425812-bank-bni-indonesia-indonesian-negara-icon-2024_07_18_16_15_23.png"
        },
        BCA: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425807-asia-bank-bca-central-indonesian-icon-2024_07_18_16_22_57.png"
        }
    };
    const [xenditPaymentOption, setXenditPaymentOption] = useState([]);
    const fetchBillDetail = async () => {
        try {
            loadingShow()
            const response = await _axios.get(`/api/shipping/bill/${params.id}`)
            loadingClose()
            if (response.status) {
                setBillDetail(response.data)
                if (response?.data?.bill?.last_payment_payload) {
                    if (response?.data?.bill?.last_payment_payload?.data !== undefined) {
                        if (response?.data?.bill?.last_payment_payload?.data?.external_id === undefined) {
                            setxenditLinkPayment(response?.data?.bill?.last_payment_payload?.data)
                            setMidtransGopay(null)
                            setMidtransVA(response?.data?.bill?.last_payment_payload?.data)
                        }
                    }
                }
                const user = store.get('user_api');
                response.data?.bill?.rates?.map((e) => {
                    if (user?.country_code === 'ph') {
                        if (e.from === 'PHP') setRate(e.rate);
                    }
                });

                const checkUseDiscountTag = _.filter(response?.data?.details, { tag: 'discount' });
                if (checkUseDiscountTag.length !== 0) {
                    checkVoucherUsage(response?.data?.bill?.id);
                }
            } else {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const checkVoucherUsage = async (id) => {
        try {
            const { status, message } = await _axios.get(`/api/voucher/order/shp/${id}/check-voucher-availability`)
            if (!status) {
                setCheckUseVoucher(true);
                return toast({
                    title: 'importir.com',
                    description: message,
                    status: 'error',
                });
            }
            return setCheckUseVoucher(false)
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const handlePaymentStripe = async (method) => {
        if (method === 'MANUAL') {
            setShowManual(true)
            return
        } else {
            setShowManual(false)
        }
        try {
            loadingShow()

            const host = window.location.href ?? "#";
            const data = {
                user_id: store.get("user_api").id,
                type: "SHP",
                invoice_no: billDetail?.bill?.invoice,
                redirect_url: host
            }
            const res = await _axios.post('/api/payments-stripe', data)
            if (res.status === true) {
                window.open(res.data.url, 'blank')
                loadingClose()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
            }
        } catch (error) {
            console.log(error)
            loadingClose()
        }
        loadingClose()

    }

    const handlePayment = async (method) => {
        loadingShow()
        const data = {
            company: "ESD",
            invoiceId: billDetail?.bill?.id,
            payment_method: method,
            redirect_url: path
        }
        try {
            const res = await _axios.post('/api/shipping/xendit-shipping-payment', data)
            if (res.status === true) {
                if (method == 'CREDIT_CARD') {
                    loadingClose();
                    return window.open(res.data.invoice_url, 'blank')
                }
                setXenditPaymentOption(res.data.available_banks)
                loadingClose()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
            }
        } catch (error) {
            console.log(error)
            loadingClose()
        }
        loadingClose()
    }

    const handlePaymentBca = async () => {
        loadingShow()
        const data = {
            company: "ESD",
            invoiceId: billDetail?.bill?.id,
            payment_type: "bank_transfer",
            amount: billDetail?.bill?.total
        }

        try {
            const res = await _axios.post('/api/shipping/midtrans-shipping-payment', data)
            loadingClose()
            if (res.status === true) {
                setMidtransVA(res.data)
                // window.location.reload()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
            }
        } catch (error) {
            console.log(error)
            loadingClose()
        }
        loadingClose()
    }

    const cancelPayment = () => {
        setMidtransVA(null)
    }

    const handleWa = () => {
        const message = encodeURIComponent(`Hi Member Importir,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
        const source = encodeURIComponent(window.location.href);
        const url = `https://api.whatsapp.com/send?phone=6287800174596&text=${message}%0A%0ASource:%20${source}`;
        window.open(url, '_blank');
    }


    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();

        document.execCommand('copy');

        document.body.removeChild(textArea);

        toast({
            title: 'importir.com',
            description: 'Copied to clipboard!',
            status: 'success',
        });
    };

    const handleSelectPaymentVA = async (arg) => {
        arg.type = 'xendit';
        setMidtransVA(arg);
    }

    useEffect(() => {
        fetchBillDetail();

        return () => { };
    }, []);
    return (
        <Stack p={[1, 1, 5]} m={3} spacing={5} bgColor={'white'}>
            <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                <IoCaretBackOutline size={15} />
                <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
            </HStack>
            {
                !checkUseVoucher && !billDetail?.bill?.paid_at ?
                    <Stack>
                        {
                            store.get("user_api")?.country_code === "id" ?
                                midtransVA ?
                                    <Stack>
                                        <Card bg={'white'}>
                                            {
                                                startDate > billDetail?.bill?.expired_at ?
                                                    <Text fontWeight={'bold'} textAlign={'center'}>
                                                        {t('expired_order_invoice')}
                                                    </Text>
                                                    :
                                                    <>
                                                        <CountdownBill startDate={startDate} endDate={billDetail?.bill?.expired_at} />
                                                        {
                                                            midtransVA?.type !== 'xendit' ? (
                                                                <CardBody>
                                                                    <Heading size={'lg'}>{t('payment_information_for')} {billDetail?.bill?.invoice}</Heading>
                                                                    <Text p={'4'}>PT. Edrus Strategi Digital</Text>
                                                                    <List spacing={3} p={'4'}>
                                                                        <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                                                            <Box>
                                                                                <ListItem>BANK : </ListItem>
                                                                            </Box>
                                                                            <Box >
                                                                                <ListItem>{midtransVA?.va_numbers[0]?.bank.toUpperCase()}</ListItem>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                                                            <Box>
                                                                                <ListItem>{t('va_number')} : </ListItem>
                                                                            </Box>
                                                                            <Box >
                                                                                <ListItem>
                                                                                    {midtransVA?.va_numbers[0]?.va_number}
                                                                                    <Button ml={2} colorScheme='yellow' onClick={() => copyToClipboard(midtransVA?.va_numbers[0]?.va_number)}>{t('copy')}</Button>
                                                                                </ListItem>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                                                            <Box>
                                                                                <ListItem>{t('total')} : </ListItem>
                                                                            </Box>
                                                                            <Box >
                                                                                <ListItem>{formatPrice(midtransVA?.gross_amount)}</ListItem>
                                                                            </Box>
                                                                        </Grid>
                                                                    </List>
                                                                </CardBody>
                                                            ) :
                                                                <>
                                                                    <CardBody>
                                                                        <Heading size={'lg'}>{t('payment_information_for')} {billDetail?.bill?.invoice}</Heading>
                                                                        <Text p={'4'}>PT. Edrus Strategi Digital</Text>
                                                                        <List spacing={3} p={'4'}>
                                                                            <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                                                                <Box>
                                                                                    <ListItem>BANK : </ListItem>
                                                                                </Box>
                                                                                <Box >
                                                                                    <ListItem>{midtransVA?.bank_code}</ListItem>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                                                                <Box>
                                                                                    <ListItem>{t('va_number')} : </ListItem>
                                                                                </Box>
                                                                                <Box >
                                                                                    <ListItem>
                                                                                        {midtransVA?.bank_account_number}
                                                                                        <Button ml={2} colorScheme='yellow' onClick={() => copyToClipboard(midtransVA?.bank_account_number)}>{t('copy')}</Button>
                                                                                    </ListItem>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                                                                <Box>
                                                                                    <ListItem>{t('total')} : </ListItem>
                                                                                </Box>
                                                                                <Box >
                                                                                    <ListItem>{formatPrice(midtransVA?.transfer_amount)}</ListItem>
                                                                                </Box>
                                                                            </Grid>
                                                                        </List>
                                                                    </CardBody>
                                                                </>
                                                        }
                                                        <CardFooter position={'relative'} justifyContent={'end'}>
                                                            <ButtonGroup spacing='2'>
                                                                <Button colorScheme='red' onClick={() => cancelPayment()}>{t('cancel_payment')}</Button>
                                                            </ButtonGroup>
                                                        </CardFooter>
                                                    </>
                                            }
                                        </Card>
                                    </Stack>
                                    :
                                    <Stack>
                                        {
                                            startDate > billDetail?.bill?.expired_at ?
                                                <Text fontWeight={'bold'} textAlign={'center'}>
                                                    {t('expired_order_invoice')}
                                                </Text>
                                                :
                                                <>
                                                    <CountdownBill startDate={startDate} endDate={billDetail?.bill?.expired_at} />
                                                    <Stack borderBottom={'1px'} borderColor={'gray.300'} p={3}>
                                                        <Heading size={'md'} fontWeight={'semibold'} textAlign={'center'}>
                                                            {t('payment_method')}
                                                        </Heading>
                                                    </Stack>

                                                    <Stack alignItems={'center'}>
                                                        {
                                                            xenditPaymentOption.length == 0 ?
                                                                <Grid templateColumns={'repeat(3, 1fr)'} gap={2}>
                                                                    <Button bgColor={'#ffd600'} w={'36'} h={'24'} onClick={() => handlePayment("INVOICE_TRANSFER_VA")}>
                                                                        <Text style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}>
                                                                            {t('pay_with_other')}
                                                                        </Text>
                                                                    </Button>

                                                                    <Button bgColor={'#ffd600'} w={'36'} h={'24'} onClick={() => handlePayment("CREDIT_CARD")}>
                                                                        <Text style={{ whiteSpace: 'pre-wrap', overflow: 'hidden' }}>
                                                                            {t('pay_with_cc')}
                                                                        </Text>
                                                                    </Button>

                                                                    <Button bgColor={'#ffd600'} w={'36'} h={'24'} onClick={() => handlePaymentBca()}>
                                                                        <Image
                                                                            boxSize='100px'
                                                                            objectFit='cover'
                                                                            src='https://cdn.cdnlogo.com/logos/b/6/bank-central-asia.svg'
                                                                        />
                                                                    </Button>
                                                                </Grid>
                                                                :
                                                                <>
                                                                    <Flex justify={"end"}>
                                                                        <div className="">
                                                                            <Button
                                                                                onClick={() => setXenditPaymentOption([])}
                                                                                colorScheme='red'>
                                                                                {t('cancel_payment')}
                                                                            </Button>
                                                                        </div>
                                                                    </Flex>
                                                                    <SimpleGrid columns={[1, 1, 2, 4]} spacing={3} p={[1, 1, 3]} alignItems='center' justifyContent={'center'}>
                                                                        {
                                                                            xenditPaymentOption?.length > 0 ? xenditPaymentOption.map((el, index) => (
                                                                                <Box
                                                                                    key={index}
                                                                                    borderWidth="1px"
                                                                                    borderRadius="lg"
                                                                                    overflow="hidden"
                                                                                    p={4}
                                                                                    textAlign="center"
                                                                                    width={"50%"}
                                                                                    marginX={"auto"}
                                                                                    _hover={{ borderColor: "red", cursor: "pointer" }}
                                                                                    onClick={() => handleSelectPaymentVA(el)}
                                                                                >
                                                                                    <Image marginX={"auto"} width={"100%"} src={bankLogos[el?.bank_code].image ?? null} alt="PERMATA" />
                                                                                </Box>
                                                                            )) : null
                                                                        }
                                                                    </SimpleGrid>
                                                                </>
                                                        }

                                                    </Stack>

                                                </>
                                        }
                                    </Stack>
                                :
                                <>

                                    <Stack alignItems={'center'}>
                                        <Grid templateColumns={'repeat(3, 1fr)'} gap={2}>
                                            <Button bgColor={'#ffd600'} w={'36'} h={'24'} onClick={() => handlePaymentStripe("STRIPE")}>
                                                Stripe
                                            </Button>

                                            <Button bgColor={'#ffd600'} w={'32'} h={'24'} ml={'2'} onClick={() => handlePaymentStripe("MANUAL")}>
                                                Manual
                                            </Button>
                                        </Grid>
                                    </Stack>
                                    {
                                        showManual ?
                                            <Stack bgColor={'white'} spacing={5} borderRadius='xl' m={2} p={3} shadow={'md'}>
                                                <div style={{ fontSize: '14px', color: 'black', fontWeight: "bold" }}>
                                                    <p>Please transfer manually before <b>{billDetail?.bill?.expired_at}</b></p>
                                                    <p>With transfer amount {userSymbolCurrency} {formatFrice(convertPriceFix(billDetail?.bill?.total))}</p>
                                                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div>Bank Name : <span>BDO Bank Transfer</span></div>
                                                        <div>Account Number : <span>0024 5029 5085</span></div>
                                                        <div>Branch : <span>SM City Cebu B</span></div>
                                                        <div>Account Name: <span>Rogelio P. Sungahid,Jr.</span></div>
                                                    </p>
                                                </div>
                                            </Stack>
                                            : ""
                                    }
                                </>
                        }
                    </Stack>

                    :

                    <Stack mt={'5'}>
                        <Box>
                            <Badge colorScheme='green'>
                                <Heading size={'md'}>
                                    PAID
                                </Heading>
                            </Badge>
                        </Box>
                    </Stack>
            }

            <Heading fontWeight={'bold'} size={'lg'}>
                {t('invoice_number')} : {billDetail?.bill?.invoice}
            </Heading>

            <HStack pt={4}>
                        {billDetail?.bill?.total < 0 ?
                            <Stack fontSize={'sm'}>
                                <Text fontWeight={'500'}>Dear Customer,</Text>
                                <Text>it appears that your payment has exceeded the billed amount. Please <Text as={'span'} color={'blue.500'} fontWeight={'500'} onClick={() => handleWa()}>Contact our admin</Text> to initiate the refund process if the payment has already been made. </Text>
                                <Text></Text>
                                <Text fontWeight={'500'}>Thank you.</Text>
                            </Stack>
                            :
                            ''}
                    </HStack>

            <Stack mt={'5'}>
                {/* <TableContainer> */}
                <Heading size={'md'} fontWeight={'bold'}>
                    {t('bill_detail')}
                </Heading>
                <Table variant='striped' colorScheme='gray' p={5}>
                    <Thead>
                        <Tr bgColor={'#ffd600'}>
                            <Td>
                                <Text fontSize={'md'} fontWeight={'bold'}>{t('title')}</Text>
                            </Td>
                            <Td>
                                <Text textTransform={'capitalize'} fontSize={'md'} fontWeight={'bold'}>{t('amount')}</Text>
                            </Td>
                            <Td>
                                <Text fontSize={'md'} fontWeight={'bold'}>Tag</Text>
                            </Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            billDetail?.details?.length > 0 && billDetail?.details?.map((x, i) => {
                                return (
                                    <Tr>
                                        <Td>
                                            <Text fontSize='sm' textTransform={'capitalize'} fontWeight={500}>{x?.title}</Text>
                                        </Td>
                                        <Td>
                                            <Text fontSize='sm' textTransform={'capitalize'} fontWeight={500}>{userDataLoc?.country_code === 'ph' ? 'PHP' : 'IDR'} {formatFrice(x?.amount / rate)}</Text>
                                        </Td>
                                        <Td>
                                            <Text fontSize='sm' textTransform={'capitalize'} fontWeight={500}>{x?.tag}</Text>
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                        <Tr>
                            <Td fontSize='md' textTransform={'capitalize'} fontWeight={500} >{t('total')}</Td>
                            <Td fontSize='md' textTransform={'capitalize'} fontWeight={500} >{userDataLoc?.country_code === 'ph' ? 'PHP' : 'IDR'} {formatFrice(billDetail?.bill?.total / rate)}</Td>
                            <Td fontSize='sm' textTransform={'capitalize'} fontWeight={500} ></Td>

                        </Tr>
                    </Tbody>

                    <Divider py={2} color={'black'} />
                 
                </Table>
                {/* </TableContainer> */}
            </Stack>
        </Stack>
    )
}

export default DetailBillShipping